var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav-menu', _vm._g(_vm._b({
    ref: "menu",
    attrs: {
      "name": "Business",
      "title": "Tools and discounts for businesses",
      "subtitle": "Employee appreciation and outreach to customers / donors",
      "action": "Business features",
      "icon": _vm.mdiDomainPlus,
      "to": {
        name: 'business'
      }
    }
  }, 'nav-menu', _vm.$attrs, false), _vm.$listeners), [_vm.showSignUp ? _c('org-sign-up-dialog', {
    attrs: {
      "value": true
    },
    on: {
      "cancel": function ($event) {
        _vm.showSignUp = false;
      },
      "complete": function ($event) {
        return _vm.$router.push({
          name: 'account-org'
        });
      }
    }
  }) : _vm._e(), _c('v-row', {
    staticClass: "ma-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "caption"
  }, [_vm._v("Tools")]), _vm._l([['Batch order', 'batch-order', _vm.mdiCalendarMultiselect], ['Create private design', 'img-to-design', _vm.mdiIncognitoCircle], ['Business portal', 'account-org', _vm.mdiDomain]], function (_ref) {
    var title = _ref[0],
      name = _ref[1],
      icon = _ref[2];
    return _c('div', {
      key: name,
      staticClass: "mx-n2"
    }, [_c('v-btn', {
      attrs: {
        "text": "",
        "color": "primary",
        "to": {
          name: name
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s(icon)
      }
    }), _vm._v(_vm._s(title) + " ")], 1)], 1);
  }), _c('div', {
    staticClass: "caption mt-5"
  }, [_vm._v("More information")]), _vm._l([['Pricing', {
    name: 'pricing'
  }, _vm.mdiCurrencyUsd], ['FAQ', {
    name: 'faq',
    hash: '#what-is-an-org-account'
  }, _vm.mdiFrequentlyAskedQuestions], ['API docs', {
    name: 'api-docs'
  }, _vm.mdiApi]], function (_ref2) {
    var title = _ref2[0],
      to = _ref2[1],
      icon = _ref2[2];
    return _c('div', {
      key: title,
      staticClass: "mx-n2"
    }, [_c('v-btn', {
      attrs: {
        "text": "",
        "color": "primary",
        "to": to
      }
    }, [_c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s(icon)
      }
    }), _vm._v(_vm._s(title) + " ")], 1)], 1);
  }), _c('div', {
    staticClass: "caption mt-5"
  }, [_vm._v("Our business tools are "), _c('span', {
    staticClass: "green--text"
  }, [_vm._v("free")])]), _c('v-btn', {
    staticClass: "mx-n2",
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.showSignUp = true;
      }
    }
  }, [_vm._v("Get started…")])], 2), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "caption mb-1"
  }, [_vm._v("Add custom branding")]), _c('v-card', {
    attrs: {
      "outlined": "",
      "max-width": "320"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 16 / 9
    }
  }, [_c('v-lazy', [_c('video', {
    staticClass: "d-block",
    attrs: {
      "width": "100%",
      "playsinline": "",
      "autoplay": "autoplay",
      "muted": "muted",
      "loop": "loop",
      "src": "https://cdn.ellacard.com/start/business/branding_640x360.mp4"
    },
    domProps: {
      "muted": true
    }
  })])], 1)], 1), _c('div', {
    staticClass: "caption mt-5 mb-1"
  }, [_vm._v("Share credits with managed users")]), _c('v-card', {
    attrs: {
      "outlined": "",
      "max-width": "320"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 16 / 9,
      "src": "https://cdn.ellacard.com/start/business/org_users.png"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
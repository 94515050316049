var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav-menu', _vm._g(_vm._b({
    attrs: {
      "name": "Group videos",
      "title": "Make a video with a group",
      "subtitle": "Instant results, no video editing skills required",
      "action": "View all video themes",
      "icon": _vm.mdiVideoVintage,
      "to": {
        name: 'catalog-video'
      }
    }
  }, 'nav-menu', _vm.$attrs, false), _vm.$listeners), [_c('v-row', {
    staticClass: "ma-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "caption mb-2"
  }, [_vm._v("Professional quality themes help you get creative")]), _c('v-row', {
    staticClass: "ma-n1"
  }, _vm._l([['c02e39f8-92ab-42de-8cb9-71bf0bce37b5', 'Birthday', 'Birthday stand-up', 'poster_6cafbc3d-9a94-40da-9998-eb1b318d51f1.jpg'], ['5f1223b7-deb4-4fd2-ab87-334ee8850dc7', 'Wedding', 'Bridal shower', 'poster_faff2a97-96ed-4060-9844-ccf43bdbb229.jpg'], ['49a43bad-9ecf-423f-8929-0b8ff6532745', 'Graduation', 'Graduation cheers', 'poster_f87c459b-c259-4361-b3e5-67ed55edd3b7.jpg'], ['9ffd2882-d92f-4eb5-9f2f-5f302d73c86c', 'Work', 'Retirement party', 'poster_9b517036-d37b-4db0-98dc-43eace3503d5.jpg']], function (_ref) {
    var templateId = _ref[0],
      q = _ref[1],
      name = _ref[2],
      poster = _ref[3];
    return _c('v-col', {
      key: templateId,
      staticClass: "pa-1",
      attrs: {
        "cols": "6"
      }
    }, [_c('v-card', {
      attrs: {
        "hover": "",
        "to": {
          name: 'catalog-video',
          params: {
            templateId: templateId
          },
          query: {
            q: q
          }
        }
      }
    }, [_c('v-img', {
      attrs: {
        "aspect-ratio": 16 / 9,
        "alt": name,
        "src": ("https://cdn.ellacard.com/group-video-template/" + templateId + "/" + poster)
      }
    })], 1)], 1);
  }), 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "caption mb-1"
  }, [_vm._v("Instant results, no matter how big your group is, and it works on any device")]), _c('v-btn', {
    staticClass: "ma-n2",
    attrs: {
      "text": "",
      "color": "primary",
      "to": {
        name: 'catalog-video',
        hash: '#intro'
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiPlay)
    }
  }), _vm._v("See how it works ")], 1), _c('outline-phone', {
    staticClass: "mt-3"
  }, [_c('v-lazy', [_c('video', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "width": "100%",
      "playsinline": "",
      "autoplay": "",
      "muted": "",
      "loop": "",
      "src": "https://cdn.ellacard.com/start/group_video_header_720.mp4",
      "srcset": "\n              https://cdn.ellacard.com/start/group_video_header_720.mp4 720w,\n              https://cdn.ellacard.com/start/group_video_header_640.mp4 640w,\n              https://cdn.ellacard.com/start/group_video_header_480.mp4 480w,\n              https://cdn.ellacard.com/start/group_video_header_320.mp4 320w\n            "
    },
    domProps: {
      "muted": true
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'catalog-video',
          hash: '#intro'
        });
      }
    }
  })])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
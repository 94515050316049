var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav-menu', _vm._g(_vm._b({
    attrs: {
      "name": "Occasions",
      "title": "Greeting cards / invitations for any occasion",
      "subtitle": "Add your own text, pictures, videos, and more",
      "action": "View all categories",
      "icon": _vm.mdiCards,
      "to": {
        name: 'catalog-categories'
      }
    }
  }, 'nav-menu', _vm.$attrs, false), _vm.$listeners), [_c('v-row', {
    staticClass: "ma-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_vm._l(_vm.categories, function (c, i) {
    var _vm$categories;
    return [c.seasonality != ((_vm$categories = _vm.categories[i - 1]) === null || _vm$categories === void 0 ? void 0 : _vm$categories.seasonality) ? _c('div', {
      staticClass: "caption",
      class: {
        'mt-4': i
      }
    }, [c.seasonality == 0 ? [_vm._v("Private collections")] : c.seasonality == 1 ? [_vm._v("Featured seasonal occasions")] : _vm._e()], 2) : _vm._e(), _c('div', {
      staticClass: "mx-n2"
    }, [_c('v-btn', {
      staticStyle: {
        "white-space": "nowrap !important"
      },
      attrs: {
        "text": "",
        "exact": "",
        "color": c.icon_color || '#1976d2',
        "to": {
          name: 'catalog-category',
          params: {
            category: c.name
          }
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s(c.icon)
      }
    }), _vm._v(" " + _vm._s(c.title) + " ")], 1)], 1)];
  })], 2), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "caption"
  }, [_vm._v("Search for anything")]), _c('v-text-field', {
    staticClass: "mt-2 mb-1 white",
    attrs: {
      "label": "Search",
      "outlined": "",
      "dense": "",
      "rounded": "",
      "hide-details": "",
      "multiple": "",
      "single-line": "",
      "clearable": "",
      "append-icon": _vm.mdiMagnify
    },
    on: {
      "!click": function ($event) {
        $event.stopPropagation();
      },
      "click:append": _vm.search,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.search.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searchText,
      callback: function ($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  }), _vm._l(['flowers', 'funny dogs', 'animated', 'happy animals', 'summer party'], function (q) {
    return _c('div', {
      staticClass: "mx-n2"
    }, [_c('v-btn', {
      attrs: {
        "text": "",
        "color": "primary",
        "to": {
          name: 'catalog-category',
          params: {
            category: 'all'
          },
          query: {
            q: q
          }
        }
      }
    }, [_vm._v(" " + _vm._s(q) + " ")])], 1);
  }), _c('div', {
    staticClass: "mt-6 caption"
  }, [_vm._v("Design your own cards / invitations")]), _vm._l(_vm.DESIGN_YOUR_OWN, function (_ref) {
    var name = _ref[0],
      icon = _ref[1],
      route = _ref[2];
    return _c('div', {
      staticClass: "mx-n2"
    }, [_c('v-btn', {
      attrs: {
        "text": "",
        "color": "primary",
        "to": Object.assign({
          name: 'catalog-category'
        }, route)
      }
    }, [_c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "small": "",
        "color": "primary"
      },
      domProps: {
        "innerHTML": _vm._s(icon)
      }
    }), _vm._v(_vm._s(name) + " ")], 1)], 1);
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
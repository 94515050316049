var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('error-dialog', {
    attrs: {
      "status": _vm.status
    }
  }), _c('div', {
    ref: "btn",
    staticClass: "d-inline-block mb-n1",
    staticStyle: {
      "height": "40px",
      "overflow": "hidden"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "absolute-fill d-flex justify-center align-center"
  }, [_c('div', {
    ref: "btn",
    style: {
      transform: ("scale(" + (_vm.blob.btn.scale || 1) + ")")
    }
  }, [_c('v-btn', _vm._b({
    directives: [{
      name: "blur",
      rawName: "v-blur"
    }],
    staticStyle: {
      "pointer-events": "auto"
    },
    attrs: {
      "Xv-bind:style": "{ 'pointer-events': isInteractive ? 'auto' }",
      "x-large": "",
      "rounded": "",
      "height": "56",
      "min-width": "56",
      "elevation": "6",
      "dark": "",
      "target": "_blank",
      "color": _vm.blob.btn.color || 'primary'
    },
    on: {
      "click": function ($event) {
        !(_vm.isOwner && ['purchase', 'edit'].includes(_vm.mode)) && _vm.$emit('click');
      }
    }
  }, 'v-btn', _vm.isInteractive && _vm.blob.btn.url ? {
    href: _vm.blob.btn.url
  } : {}, false), [_vm.blob.btn.text ? _c('span', {
    class: {
      'mr-1': _vm.blob.btn.url
    },
    domProps: {
      "innerHTML": _vm._s(_vm.blob.btn.text)
    }
  }) : _vm._e(), !_vm.blob.btn.form ? _c('v-icon', {
    domProps: {
      "innerHTML": _vm._s(_vm.mdiOpenInNew)
    }
  }) : _vm._e()], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
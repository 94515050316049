var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('error-dialog', {
    attrs: {
      "status": _vm.signInStatus
    }
  }), _c('v-btn', {
    staticClass: "btn-like-google-sign-in",
    attrs: {
      "elevation": "0",
      "outlined": "",
      "loading": _vm.signInStatus.pending
    },
    on: {
      "click": _vm.signIn
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "#1877F2"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiFacebook)
    }
  }), _c('span', [_vm._v("Continue with Facebook")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    directives: [{
      name: "no-scroll",
      rawName: "v-no-scroll"
    }],
    attrs: {
      "eager": "",
      "content-class": "fill-16x9",
      "value": _vm.$route.hash == _vm.hash
    },
    on: {
      "input": function ($event) {
        _vm.$router.push(_vm.$routeAssign({
          hash: undefined
        }));
      }
    }
  }, [_c('v-card', {
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "dark": ""
    }
  }, [_c('v-btn', {
    staticStyle: {
      "z-index": "1",
      "position": "absolute",
      "top": "12px",
      "right": "12px"
    },
    attrs: {
      "fab": "",
      "small": "",
      "dark": "",
      "color": "rgba(0,0,0,.5)"
    },
    on: {
      "click": function ($event) {
        _vm.$router.push(_vm.$routeAssign({
          hash: undefined
        }));
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "innerHTML": _vm._s(_vm.mdiClose)
    }
  })], 1), _c('v-responsive', {
    attrs: {
      "aspect-ratio": 16 / 9
    }
  }, [_c('video-player', {
    ref: "p",
    staticClass: "absolute-fill",
    attrs: {
      "allow-fullscreen": "",
      "src": _vm.src,
      "srcset": _vm.srcset,
      "poster": _vm.poster
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', {
    staticClass: "mb-12"
  }, [_c('ella-card'), _vm._v(" is the easiest way to create and send a beautiful group greeting card for any occasion. Start with our extensive searchable catalog or design your own greeting card. "), _c('router-link', {
    attrs: {
      "to": {
        name: 'design'
      }
    }
  }, [_vm._v("Become an Ellacard designer")]), _vm._v(" and get paid for every sale of your design. Add anything you want to your card: stylized text, pictures, videos, GIFs"), !_vm.global.xgc ? [_vm._v(", gift cards")] : _vm._e(), _vm._v(", and more. All Ellacard products are "), _c('b', [_vm._v("collaborative")]), _vm._v(" – it's never been easier for a group to come together to create a memorable greeting card, and it all works right in your browser on any device.")], 2), _c('p', {
    staticClass: "mb-12"
  }, [_vm._v("Want to create something truly memorable? Try Ellacard's "), _c('router-link', {
    attrs: {
      "to": {
        name: 'start-group-videos'
      }
    }
  }, [_vm._v("group videos")]), _vm._v(" – anyone can start with one of our themes to create a beautiful video with friends, family, or coworkers, "), _c('b', [_vm._v("no editing required")]), _vm._v(". Ellacard guides your group through exactly what to do to make a video, and automatically "), _c('b', [_vm._v("instantly")]), _vm._v(" makes it for you. "), _c('router-link', {
    attrs: {
      "to": {
        name: 'catalog-video'
      }
    }
  }, [_vm._v("Start creating a video")]), _vm._v(" for free!")], 1), _c('p', {
    staticClass: "mb-12"
  }, [_c('ella-card'), _vm._v(" is "), _c('router-link', {
    attrs: {
      "to": {
        name: 'business'
      }
    }
  }, [_vm._v("perfect for businesses")]), _vm._v(". Use Ellacard for internal employee engagement, like kudos, birthdays, or welcome videos, or use it for outreach to customers / donors. Ellacard makes it easy to send bulk campaigns featuring your greeting cards and videos – perfect for sending thank you cards or engaging customers. We've built a whole suite of tools to manage organization members and track usage. Wrap our cards, videos, and our collaborative design tools with your own custom branding. Ellacard also has a "), _c('router-link', {
    attrs: {
      "to": {
        name: 'api-docs'
      }
    }
  }, [_vm._v("robust API")]), _vm._v(" and can be easily embedded in your own site.")], 1), _c('v-row', _vm._l([['Built-in collaboration', "Every part of Ellacard was designed with collaboration in mind, from group cards with hundreds of pages to hour+ long custom group videos to gift cards split across dozens of contributors."], ['The best visuals', "From funny animated card designs to emotional video graphics, Ellacard makes it easy to create beautiful content. Send cards with confetti, romantic rose petals, or even a spring rain storm."], ['Search that works', "Find exactly the right greeting card with our super fast and effective search."], ['Truly cross-platform', "Ellacard works perfectly on every device. Use pinch / rotate gestures on mobile. Record videos directly in your web browser on desktop."], ['Drag-and-drop', "Everything is drag-and-drop, whether you're uploading images or videos from your computer, rearranging content on card pages, or rearranging group video contributions."], ['Instant videos with no editing', "Only Ellacard offers the ability to create directed, themed videos with a group <i>instantly</i> and <i>automatically</i> from any device <i>without any video editing</i>. It's free to try, just <a href='/videos'>pick a theme</a> to get started!"]], function (_ref, i) {
    var title = _ref[0],
      content = _ref[1];
    return _c('v-col', {
      key: i,
      attrs: {
        "cols": "12",
        "sm": "6",
        "md": "4"
      }
    }, [_c('v-card', {
      staticClass: "fill-height",
      attrs: {
        "color": _vm.COLORS[i % _vm.COLORS.length] + ' lighten-5'
      }
    }, [_c('v-card-title', {
      domProps: {
        "innerHTML": _vm._s(title)
      }
    }), _c('v-card-text', {
      domProps: {
        "innerHTML": _vm._s(content)
      }
    })], 1)], 1);
  }), 1), _c('div', {
    staticClass: "mt-6 text-md-h6 text-center"
  }, [_vm._v("Find more questions / answers in our "), _c('router-link', {
    attrs: {
      "to": {
        name: 'faq'
      }
    }
  }, [_vm._v("FAQ")]), _vm._v(" or contact us: "), _c('a', {
    attrs: {
      "href": "mailto:contact@ellacard.com"
    }
  }, [_vm._v("contact@ellacard.com")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "content-class": _vm.isDismissable ? 'snackbar-dismissable' : '',
      "eager": "",
      "fullscreen": "",
      "transition": false,
      "persistent": !_vm.isDismissable,
      "value": _vm.value
    },
    on: {
      "input": function (x) {
        return _vm.$emit('input', x);
      }
    }
  }, [_c('v-snackbar', _vm._b({
    attrs: {
      "value": _vm.value
    },
    on: {
      "input": function (x) {
        return _vm.$emit('input', x);
      }
    },
    nativeOn: {
      "click": function ($event) {
        _vm.isDismissable && _vm.$emit('input', false);
      }
    }
  }, 'v-snackbar', _vm.$attrs, false), [_vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.target && !(_vm.target.blob.template && _vm.target.blob.mask.placeholder == _vm.target.blob.video.src) ? _c('div', {
    staticClass: "video-blob-controls absolute-fill d-flex justify-center align-center",
    class: {
      'is-playing': !_vm.target.error && !_vm.target.isPaused,
      'is-editable': _vm.isEditable,
      'is-selected': _vm.isSelected
    },
    on: {
      "click": function () {
        if (_vm.isEditable && !_vm.isSelected && _vm.target.isPaused) { _vm.$emit('select'); }else { _vm.target.playPause(); }
      }
    }
  }, [_vm.showProcessing ? _c('v-dialog', {
    attrs: {
      "width": "300"
    },
    model: {
      value: _vm.showProcessing,
      callback: function ($$v) {
        _vm.showProcessing = $$v;
      },
      expression: "showProcessing"
    }
  }, [_c('v-card', [_c('v-btn', {
    staticClass: "float-right mt-3 mr-3",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.showProcessing = false;
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "innerHTML": _vm._s(_vm.mdiClose)
    }
  })], 1), _c('v-card-title', [_vm._v("Video processing")]), _c('v-card-text', {
    staticClass: "d-flex align-center"
  }, [_vm._v(" Status: "), _c('span', {
    staticClass: "mx-2 warning--text"
  }, [_vm._v("in progress")]), _c('v-btn', {
    attrs: {
      "small": "",
      "icon": "",
      "color": "primary",
      "loading": _vm.target.reloadStatus.pending
    },
    on: {
      "click": _vm.target.reload
    }
  }, [_c('v-icon', {
    domProps: {
      "innerHTML": _vm._s(_vm.mdiRefresh)
    }
  })], 1)], 1), _c('v-card-text', [_vm._v("This video is still being processed so that it can play back in all web browsers / devices.")])], 1)], 1) : _vm._e(), _vm.target.error ? _c('v-btn', {
    directives: [{
      name: "blur",
      rawName: "v-blur"
    }],
    staticClass: "control",
    attrs: {
      "fab": "",
      "dark": "",
      "color": "primary",
      "loading": _vm.target.reloadStatus.pending
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.showProcessing = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiPlay)
    }
  })], 1) : [_c('v-btn', {
    directives: [{
      name: "blur",
      rawName: "v-blur"
    }],
    staticClass: "control",
    attrs: {
      "fab": "",
      "dark": "",
      "color": "primary",
      "disabled": _vm.target.loadStatus.pending
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.target.isPaused ? _vm.mdiPlay : _vm.mdiPause)
    }
  })], 1), _c('div', {
    staticClass: "playback-details"
  }, [_c('v-slider', {
    staticClass: "control playback-slider px-3",
    attrs: {
      "hide-details": "",
      "step": "0",
      "disabled": _vm.target.loadStatus.pending,
      "value": _vm.target.playhead / _vm.target.duration * 100.0
    },
    on: {
      "input": _vm.target.seek,
      "start": _vm.target.pause,
      "click": function ($event) {
        $event.stopPropagation();
        return function () {
          if (_vm.isEditable && !_vm.isSelected && _vm.target.isPaused) { _vm.$emit('select'); }
        }.apply(null, arguments);
      },
      "mousedown": function ($event) {
        $event.stopPropagation();
      }
    },
    nativeOn: {
      "touchstart": function ($event) {
        $event.stopPropagation();
      }
    }
  }), _c('div', {
    staticClass: "d-flex px-3 pb-1 white--text timestamp-shadow"
  }, [_c('div', [_vm._v(_vm._s(_vm._f("video-timestamp")(_vm.target.playhead)))]), _c('v-spacer'), _c('div', [_vm._v(_vm._s(_vm._f("video-timestamp")(_vm.target.duration)))])], 1)], 1)]], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }
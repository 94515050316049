var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-overlay', {
    attrs: {
      "opacity": ".8",
      "z-index": "999",
      "value": true
    }
  }, [_c('div', [_c('v-btn', {
    staticStyle: {
      "position": "fixed",
      "top": "12px",
      "right": "12px"
    },
    attrs: {
      "light": "",
      "fab": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('cancel');
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "innerHTML": _vm._s(_vm.mdiClose)
    }
  })], 1), _c('div', {
    attrs: {
      "id": "captcha"
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
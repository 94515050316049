var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "absolute-fill d-flex justify-center overflow-hidden",
    style: _vm.crop
  }, [_vm.loadStatus.pending || !_vm.isPreview && _vm.blob.template && !_vm.blob.user_id && _vm.isEditable && !_vm.isSelected ? _c('div', {
    staticClass: "absolute-fill template-editable",
    staticStyle: {
      "z-index": "1"
    }
  }) : _vm._e(), _vm.isTemplatePlaceholder ? _c('img', {
    staticClass: "absolute-fill user-select-none",
    attrs: {
      "width": "100%",
      "height": "100%",
      "draggable": "false",
      "src": _vm.blob.video.src
    },
    on: {
      "load": function ($event) {
        return _vm.$emit('loaded');
      },
      "error": function ($event) {
        return _vm.$emit('loaded');
      },
      "abort": function ($event) {
        return _vm.$emit('loaded');
      }
    }
  }) : _vm._e(), _c('video', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isTemplatePlaceholder,
      expression: "!isTemplatePlaceholder"
    }],
    ref: "playback",
    staticClass: "playback black",
    style: {
      transform: _vm.blob.video.mirror ? 'scale(-1, 1)' : null
    },
    attrs: {
      "width": "100%",
      "height": "100%",
      "playsinline": "",
      "poster": _vm.isTemplatePlaceholder ? null : _vm.isLocal ? null : _vm.blob.video.src + '_poster_0',
      "src": _vm.isTemplatePlaceholder ? null : _vm.src
    },
    on: {
      "loadeddata": _vm.onLoadedData
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="overflow-x-hidden">

    <video-dialog
      hash="#intro-group-cards"
      poster="https://cdn.ellacard.com/start/group_cards_intro_poster.jpg"
      :src="`https://cdn.ellacard.com/start/group_cards_intro_hd${IS_MOBILE ? '480' : '720'}.mp4`"
    />

    <video-dialog
      hash="#intro-group-videos"
      poster="https://cdn.ellacard.com/start/group_video_intro_poster.jpg"
      :src="`https://cdn.ellacard.com/start/group_video_intro_hd${IS_MOBILE ? '480' : '720'}.mp4`"
    />

    <video-dialog
      hash="#intro-gift-cards"
      poster="https://cdn.ellacard.com/start/gift_cards_poster.jpg"
      src="https://cdn.ellacard.com/start/gift_cards_720.mp4"
    />

    <send-sample-dialog v-if="$route.hash?.startsWith('#demo')" :sample="SAMPLE" />

    <v-container class="mt-6 mt-sm-12 py-sm-12 text-center text-sm-left">
      <div
        class="float-sm-right d-block ml-sm-3 mb-6 mb-sm-3"
        :style="$vuetify.breakpoint.xsOnly ? 'max-width: 100%;' : 'max-width: 60%; width: 640px;'"
      >
        <v-responsive :aspect-ratio="640 / 294">
          <img
            class="d-block"
            width="100%"
            alt="Sample group greeting card from Ellacard with custom pictures, videos, GIFs, and text"
            src="https://cdn.ellacard.com/start/header_laptop.jpg"
          />
        </v-responsive>
      </div>

      <!-- You had something like this previously, but in theory, the static image will yield better SEO -->
      <!--
      <div
        class="float-sm-right ml-sm-3 mb-6 mb-sm-3 relative"
        :style="$vuetify.breakpoint.xsOnly ? '' : 'max-width: 60%; width: 640px;'"
        <outline-laptop>
          <video
            style="cursor: pointer;"
            width="100%"
            playsinline autoplay muted loop
            poster="https://cdn.ellacard.com/start/header_poster.jpg"
            srcset="
              https://cdn.ellacard.com/start/header_360.mp4 360w,
              https://cdn.ellacard.com/start/header_480.mp4 480w,
              https://cdn.ellacard.com/start/header_640.mp4 640w
            "
            src="https://cdn.ellacard.com/start/header_360.mp4"
            @click="$router.push({ name: $route.name, hash: '#intro-group-videos' })"
          />
        </outline-laptop>
      </div>
      -->

      <h1 class="text-h4 text-sm-h3 text-md-h2">
        Easy to make, <br>amazing to receive
      </h1>
      <h2 class="text-h6 text-sm-h5 mt-6">
        Create and send group greeting cards, invitations, and collaborative videos
      </h2>
      <div class="mt-6 mx-n5 d-flex flex-wrap justify-center justify-sm-start">
        <v-btn
          v-for="[ btn, name, icon ] in [
            [ 'View all cards', 'catalog', mdiCards ],
            [ 'View all video themes', 'catalog-video', mdiVideoVintage ],
            ...(global.xgc ? [] : [ [ 'View all gift cards', 'catalog-gift-cards', mdiWalletGiftcard ] ]),
            [ 'Pricing', 'pricing', mdiCurrencyUsd ],
            [ 'Business', 'business', mdiDomain ]
          ]"
          :key="btn"
          class="ma-2"
          style="text-transform: none;"
          text color="pink"
          :to="{ name }"
        >
          <v-icon class="mr-2" v-html="icon" />{{btn}}
        </v-btn>
      </div>
      <div style="clear: both;" />
    </v-container>

    <clip-waves class="mt-6" :offset-top=".75" :offset-bottom=".9">
      <!-- <v-responsive class="relative" :aspect-ratio="{ xs: 1.8, sm: 2.4, md: 3, lg: 3.7, xl: 3.7 }[$vuetify.breakpoint.name]"> -->
      <div
        class="relative"
        v-bind:style="{ height: ({ xs: 220, sm: 280, md: 340, lg: 400, xl: 460 }[$vuetify.breakpoint.name]) + 'px' }"
      >
        <img
          class="absolute"
          width="100%"
          srcset="
            https://cdn.ellacard.com/start/parallax_1920_2.jpg 1920w,
            https://cdn.ellacard.com/start/parallax_1440_2.jpg 1440w,
            https://cdn.ellacard.com/start/parallax_960_2.jpg 960w
          "
          src="https://cdn.ellacard.com/start/parallax_960_2.jpg 960w"
          alt="Collage of cards and videos"
        />
      </div>
    </clip-waves>

    <start-section
      class="mt-12"
      id="group-cards"
      title="Send a group greeting card filled with heartfelt messages"
      subtitle="Sign greeting cards with text, images, GIFs, videos, and more"
    >
      <v-img
        class="mx-auto"
        style="max-width: max(80%, 500px);"
        :aspect-ratio="1183 / 700"
        alt="Sample card with custom pictures, videos, GIFs, and text"
        src="https://cdn.ellacard.com/start/cards.jpg"
      />
      <div class="mx-n2 mt-4 mb-n2 d-flex flex-wrap justify-center">
        <v-btn class="ma-2" dark color="pink lighten-3" :to="{ name: 'catalog' }">Start a card</v-btn>
        <v-btn class="ma-2" dark color="pink lighten-3" :to="{ name: $route.name, hash: '#intro-group-cards' }">How it works<v-icon class="ml-2"v-html="mdiVideo" /></v-btn>
        <v-btn class="ma-2" dark color="amber" exact :to="$routeAssign({ hash: '#demo:sign' })">
          Send yourself a sample
        </v-btn>
      </div>
    </start-section>

    <clip-waves
      class="mt-12 white--text"
      style="background: linear-gradient(#7fa8d7, #28547e);"
      pad-wave-height
      :offset-top=".4" :offset-bottom=".2"
    >
      <start-section
        class="py-6"
        id="group-videos"
        title="Simply amazing group video gifts"
        subtitle="Send professional quality group videos for any occasion – no editing required"
      >
        <outline-laptop class="mx-auto" style="max-width: max(80%, 500px);">
          <video
            width="100%"
            playsinline autoplay muted loop
            src="https://cdn.ellacard.com/start/header.mp4"
          />
        </outline-laptop>
        <div class="mx-n2 mt-4 mb-n2 d-flex flex-wrap justify-center">
          <v-btn class="ma-2" dark color="teal lighten-2" :to="{ name: 'catalog-video' }">Start a video</v-btn>
          <v-btn class="ma-2" dark color="teal lighten-2" :to="{ name: $route.name, hash: '#intro-group-videos' }">How it works<v-icon class="ml-2"v-html="mdiVideo" /></v-btn>
        </div>
      </start-section>
    </clip-waves>

    <start-section
      v-if="global.xgc"
      class="mt-12"
      id="gift-cards"
      title="Group greeting cards"
      subtitle="Add more to an Ellacard group greeting card"
    >
      <v-row>
        <v-col cols="12" sm="6">
          <v-card class="ml-3 float-right" width="100">
            <v-img :aspect-ratio="5/7" src="https://cdn.ellacard.com/start/card-add-msg.jpeg" />
          </v-card>
          <h5 class="text-h5">Messages</h5>
          Resize and drag your own custom message with custom fonts and colors.
        </v-col>
        <v-col cols="12" sm="6">
          <v-card class="ml-3 float-right" width="100">
            <v-img :aspect-ratio="5/7" src="https://cdn.ellacard.com/start/card-add-img.jpeg" />
          </v-card>
          <h5 class="text-h5">Pictures</h5>
          Drag and drop your own pictures. Pinch to resize, rotate, and choose from lots of different crisp frame effects.
        </v-col>
        <v-col cols="12" sm="6">
          <v-card class="ml-3 float-right" width="100">
            <v-img :aspect-ratio="5/7" src="https://cdn.ellacard.com/start/card-add-group-video.jpeg" />
          </v-card>
          <h5 class="text-h5">Videos</h5>
          Easily upload or record a video right in your browser, or use our one-of-a-kind tools to make a custom video with a group.
        </v-col>
        <v-col cols="12" sm="6">
          <v-card class="ml-3 float-right" width="100">
            <v-img :aspect-ratio="5/7" src="https://cdn.ellacard.com/start/card-add-gif.jpeg" />
          </v-card>
          <h5 class="text-h5">GIFs</h5>
          Add some fun to your card with our integrated GIF library.
        </v-col>
      </v-row>
    </start-section>

    <start-section
      v-else
      class="mt-12"
      id="gift-cards"
      title="Ellacard has group gift cards"
      subtitle="Wide selection of merchants – batch ordering is perfect for employee rewards"
    >
      <v-img
        class="mx-auto"
        style="max-width: 594px;"
        :aspect-ratio="594 / 295"
        alt="Gift card merchants"
        src="https://cdn.ellacard.com/start/giftcard_merchants_594x295.png"
      />
      <div class="mx-n2 mb-n2 mt-4 d-flex flex-wrap justify-center">
        <v-btn class="ma-2" dark color="pink lighten-3" :to="{ name: 'catalog' }">Start a card</v-btn>
        <v-btn class="ma-2" dark color="purple lighten-2" :to="{ name: $route.name, hash: '#intro-gift-cards' }">How it works<v-icon class="ml-2"v-html="mdiVideo" /></v-btn>
      </div>
    </start-section>

    <clip-waves
      class="my-12 white--text"
      style="background: linear-gradient(#7fa8d7, #28547e);"
      pad-wave-height
      :offset-top=".15" :offset-bottom=".35"
    >
      <start-section
        class="py-6"
        id="how-it-works"
        title="How it works"
      >
        <start-how-it-works dark />
        <div class="mx-n2 mb-n2 pt-6 d-flex flex-wrap justify-center">
          <v-btn class="ma-2" dark color="pink lighten-3" :to="{ name: 'catalog' }">Start a card</v-btn>
          <v-btn class="ma-2" dark color="teal lighten-2" :to="{ name: 'catalog-video' }">Start a video</v-btn>
        </div>
      </start-section>
    </clip-waves>

    <start-section
      class="mt-12"
      id="categories"
      title="The perfect greeting card for any occasion"
    >
      <start-categories />
      <div class="mx-n2 mb-n2 mt-4 d-flex flex-wrap justify-center">
        <v-btn class="ma-2" dark color="pink lighten-3" :to="{ name: 'catalog' }">Start a card</v-btn>
        <v-btn class="ma-2" dark color="teal lighten-2" :to="{ name: 'catalog-video' }">Start a video</v-btn>
      </div>
    </start-section>

    <clip-waves
      class="mt-12 white--text"
      style="background: linear-gradient(#7fa8d7, #28547e);"
      pad-wave-height
      :offset-top=".5" :offset-bottom=".2"
    >
      <start-section
        class="py-6"
        id="business"
        title="Business tools"
        subtitle="Additional tools, features, and discounts for businesses / organizations"
      >
        <start-business-tools />
      </start-section>
    </clip-waves>

    <start-section
      class="mt-12"
      id="do-more"
      title="Do more with Ellacard"
      subtitle="Advanced creative and collaborative tools"
    >
      <start-features />
      <div class="mx-n2 mb-n2 mt-4 d-flex flex-wrap justify-center">
        <v-btn class="ma-2" dark color="pink lighten-3" :to="{ name: 'catalog' }">Start a card</v-btn>
        <v-btn class="ma-2" dark color="teal lighten-2" :to="{ name: 'catalog-video' }">Start a video</v-btn>
      </div>
    </start-section>

    <clip-waves
      class="my-12 white--text"
      style="background: linear-gradient(#7fa8d7, #28547e);"
      pad-wave-height
      :offset-top="0" :offset-bottom=".2"
    >
      <start-section class="py-6" id="reviews" title="See how much people love Ellacard">
        <start-testimonials />
      </start-section>
    </clip-waves>

    <start-section
      class="my-12"
      id="pricing"
      title="Pricing"
    >
      <div class="mb-6 text-center">See our <router-link :to="{ name: 'pricing' }">pricing page</router-link> for more details</div>
      <start-pricing class="mx-auto" @start="v => $router.push({ name: 'catalog' + (v == 'video' ? '-video' : '') })" />
    </start-section>

    <clip-waves
      class="mt-6 white--text"
      style="background: linear-gradient(#7fa8d7, #28547e);"
      pad-wave-height
      :offset-top=".5"
    >
      <v-container>
        <h2 class="my-12 text-h5 text-center">Check our <v-btn href="/faq">FAQ</v-btn> for more details</h2>
        <h2 class="mb-12 text-h5 text-center">Please <v-btn href="mailto:contact@ellacard.com">contact us</v-btn> with additional questions</h2>
      </v-container>
    </clip-waves>

  </div>
</template>


<script>
import ClipWaves from '@/components/ClipWaves.vue';
import OutlineLaptop from '@/components/OutlineLaptop.vue';
import Price from '@/components/Price.vue';
import StartBusinessTools from '@/components/StartBusinessTools.vue';
import StartCategories from '@/components/StartCategories.vue';
import StartFeatures from '@/components/StartFeatures.vue';
import StartHowItWorks from '@/components/StartHowItWorks.vue';
import StartPricing from '@/components/StartPricing.vue';
import StartSection from '@/components/StartSection.vue';
import StartTestimonials from '@/components/StartTestimonials.vue';
import VideoDialog from '@/components/VideoDialog.vue';
import birthday from '@/category-landing-pages/birthday.json';
import { DEFAULT as DEFAULT_SKIN } from '@/utils/skin.js';
import { mdiVideo, mdiCards, mdiVideoVintage, mdiWalletGiftcard, mdiCurrencyUsd, mdiDomain } from '@mdi/js';


export default {
  name: 'start',

  components: {
    ClipWaves,
    OutlineLaptop,
    Price,
    StartBusinessTools,
    StartCategories,
    StartFeatures,
    StartHowItWorks,
    StartPricing,
    StartSection,
    StartTestimonials,
    VideoDialog,
    SendSampleDialog: () => import('@/components/SendSampleDialog.vue')
  },

  created() {
    const SAMPLE = { design_id: '07c9159f-d487-4e32-bb3d-b0cf822e63d0', info: {}, ...birthday.sample };
    SAMPLE.skin = Object.assign({}, DEFAULT_SKIN, _.omitBy(SAMPLE.skin || {}, _.isNull));
    Object.assign(this, { SAMPLE, mdiVideo, mdiCards, mdiVideoVintage, mdiWalletGiftcard, mdiCurrencyUsd, mdiDomain });
  }
}
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12" lg="6" class="d-flex flex-wrap justify-center align-end">
        <v-img class="mx-2 mb-n2" :aspect-ratio="480 / 158" min-width="64" max-width="194" src="https://cdn.ellacard.com/start/google_logo_white.png" />
        <div>
          <div class="px-1 mb-n1 d-flex align-baseline">
            <v-spacer />
            <div class="caption mr-2">50 reviews</div>
            <div class="title font-weight-bold yellow--text">4.8</div>
          </div>
          <v-rating dense readonly half-increments x-large color="yellow" :value="4.8" />
        </div>
      </v-col>
      <v-col cols="12" lg="6" class="d-flex flex-wrap justify-center align-end">
        <v-img class="mx-2 mb-2" :aspect-ratio="480 / 96" min-width="64" max-width="230" src="https://cdn.ellacard.com/start/facebook_logo_white.png" />
        <div>
          <div class="px-1 mb-n1 d-flex align-baseline">
            <v-spacer />
            <div class="caption mr-2">15 reviews</div>
            <div class="title font-weight-bold yellow--text">5.0</div>
          </div>
          <v-rating dense readonly half-increments x-large color="yellow" :value="5" />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" v-for="(r, i) in REVIEWS" :key="i">
        <quote-card class="pa-3 text-body-1" v-bind="r" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import QuoteCard from '@/components/QuoteCard.vue';

export default {
  name: 'start-testimonials',

  components: { QuoteCard },

  created() {
    this.REVIEWS = [
      {
        img: 'https://cdn.ellacard.com/start/reviews/shobhit.jpg',
        text: `I used Ellacard to celebrate my dad’s 50th birthday and it was simple to use. I chose the comedy night video theme. Everyone was able to add to the video without issues, and the final video was awesome. I highly recommend it.`,
        author: 'Shobhit K.'
      },
      {
        img: 'https://cdn.ellacard.com/start/reviews/andrew.jpg',
        text: `I use Ellacard to send ecards to my staff for their birthdays and work anniversaries, etc. It is nice that I can add gift cards. One time I had an issue, and their customer service responded very fast (like ten minutes) and fixed the problem. I will definitely keep coming here.`,
        author: 'Andrew'
      },
      {
        img: 'https://cdn.ellacard.com/start/reviews/richard.jpg',
        text: `I have used several of these group greeting card platforms, and Ellacard offers the best features. I started an ecard and had over 70 people sign it. Cool that you can add photos and videos. It worked perfectly! Thanks Ellacard.`,
        author: 'Richard'
      }
    ];
  }
}
</script>

<template>
  <div>
    <v-row>
      <v-col v-for="[ name, id, icon, color ] in CATEGORIES" :key="name" cols="6" sm="4" lg="3">
        <v-card
          dark hover
          :color="color + ' lighten-2'"
          :to="toCatalog ? { name: 'catalog', query: { header: id, q: name } } : { name: 'start-category', params: { category: id } }"
        >
          <v-img :aspect-ratio="2" :src="`https://cdn.ellacard.com/start/categories/${name}.jpg`" />
          <div class="text-body-1 text-md-h6 pa-2 pa-sm-3 text-center">
            <v-icon class="mr-2" color="white" v-html="icon" />{{name}}
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col
        v-for="([ name, id, icon ], i) in categories" :key="id"
        cols="6" sm="4" lg="3"
        class="pa-md-6"
      >
        <v-card hover class="px-1 px-sm-3 py-3 d-flex flex-column justify-center align-center text-center" :to="{ name: 'start-category', params: { category: id } }" :color="colors[i % categories.length] + ' lighten-5'" style="background-opacity: .5;">
          <v-icon :color="colors[i % categories.length]" x-large v-html="icon" />
          <h3 class="text-body-1 text-sm-h6 font-weight-bold" v-html="name" />
        </v-card>
      </v-col>
    </v-row> -->
    <p class="mt-6 mb-0 mx-sm-2 mx-md-12 text-center text-md-h6">
      Find more in our fully-searchable catalogs of <a href="/cards">cards</a> and <a href="/videos">video themes</a>
    </p>
  </div>
</template>

<script>
import { CORE_CATEGORIES, buildCategoryURL } from '@/utils/categories.js';

const COLORS = [
  'pink',
  'green',
  'indigo',
  'blue',
  'orange',
  'deep-purple',
  'light-green',
  'deep-orange',
  'purple'
];

export default {
  name: 'start-categories',
  props: { toCatalog: Boolean },
  created() {
    this.CATEGORIES = CORE_CATEGORIES.map((x, i) => [ x[0], buildCategoryURL(x[0]), x[1], COLORS[i % COLORS.length] ]);
  }
}
</script>

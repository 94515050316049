var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav-menu', _vm._g(_vm._b({
    attrs: {
      "name": "Group gift cards",
      "title": "Add gift cards",
      "subtitle": "Your whole group can add to one gift card",
      "action": "View all gift cards",
      "icon": _vm.mdiWalletGiftcard,
      "to": {
        name: 'catalog-gift-cards'
      }
    }
  }, 'nav-menu', _vm.$attrs, false), _vm.$listeners), [_c('v-row', {
    staticClass: "ma-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "5"
    }
  }, [_c('div', {
    staticClass: "caption"
  }, [_vm._v("Wide selection of merchants")]), _vm._l(_vm.MERCHANTS, function (_ref) {
    var name = _ref[0],
      providerId = _ref[1],
      img = _ref[2];
    return _c('div', {
      staticClass: "mx-n2"
    }, [_c('v-btn', {
      attrs: {
        "text": "",
        "color": "primary",
        "to": {
          name: 'catalog-gift-cards',
          params: {
            providerId: providerId
          }
        }
      }
    }, [_c('v-img', {
      staticClass: "mr-2",
      attrs: {
        "width": "32",
        "aspect-ratio": 300 / 190,
        "src": img
      }
    }), _vm._v(_vm._s(name) + " ")], 1)], 1);
  })], 2), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "7"
    }
  }, [_c('div', {
    staticClass: "caption mb-1"
  }, [_vm._v("Send gift cards in bulk")]), _c('v-btn', {
    staticClass: "ma-n2",
    attrs: {
      "text": "",
      "color": "primary",
      "to": {
        name: 'batch-order'
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiCheckboxMultipleBlank)
    }
  }), _vm._v("Create bulk order ")], 1), _c('v-row', {
    staticClass: "mx-n1 mt-2 mb-1"
  }, [_c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', [_c('v-img', {
    attrs: {
      "aspect-ratio": 5 / 7,
      "src": "/nav_gift_cards/giftcard_batch_1.png"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', [_c('v-img', {
    attrs: {
      "aspect-ratio": 5 / 7,
      "src": "/nav_gift_cards/giftcard_batch_2.png"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', [_c('v-img', {
    attrs: {
      "aspect-ratio": 5 / 7,
      "src": "/nav_gift_cards/giftcard_batch_3.png"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "caption mt-6 mb-1"
  }, [_vm._v("Send internationally")]), _c('v-btn', {
    staticClass: "ma-n2",
    attrs: {
      "text": "",
      "color": "primary",
      "to": {
        name: 'international-gift-cards'
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": "",
      "color": "primary"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiEarth)
    }
  }), _vm._v("Supported countries / merchants ")], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
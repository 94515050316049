var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', _vm._l(_vm.CATEGORIES, function (_ref) {
    var name = _ref[0],
      id = _ref[1],
      icon = _ref[2],
      color = _ref[3];
    return _c('v-col', {
      key: name,
      attrs: {
        "cols": "6",
        "sm": "4",
        "lg": "3"
      }
    }, [_c('v-card', {
      attrs: {
        "dark": "",
        "hover": "",
        "color": color + ' lighten-2',
        "to": _vm.toCatalog ? {
          name: 'catalog',
          query: {
            header: id,
            q: name
          }
        } : {
          name: 'start-category',
          params: {
            category: id
          }
        }
      }
    }, [_c('v-img', {
      attrs: {
        "aspect-ratio": 2,
        "src": ("https://cdn.ellacard.com/start/categories/" + name + ".jpg")
      }
    }), _c('div', {
      staticClass: "text-body-1 text-md-h6 pa-2 pa-sm-3 text-center"
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "color": "white"
      },
      domProps: {
        "innerHTML": _vm._s(icon)
      }
    }), _vm._v(_vm._s(name) + " ")], 1)], 1)], 1);
  }), 1), _vm._m(0)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "mt-6 mb-0 mx-sm-2 mx-md-12 text-center text-md-h6"
  }, [_vm._v(" Find more in our fully-searchable catalogs of "), _c('a', {
    attrs: {
      "href": "/cards"
    }
  }, [_vm._v("cards")]), _vm._v(" and "), _c('a', {
    attrs: {
      "href": "/videos"
    }
  }, [_vm._v("video themes")])]);
}]

export { render, staticRenderFns }
<template>
  <v-responsive
    v-if="poster"
    class="d-flex align-center overflow-visible"
    :aspect-ratio="$vuetify.breakpoint.xsOnly ? 16 / 9 : 5 / 7"
    :width="w"
  >
    <v-card tile>
      <v-img :src="poster" />
    </v-card>
  </v-responsive>
  <v-card v-else class="overflow-visible" v-bind:style="{ width: w + 'px', height: (w / (5 / 7)) + 'px' }" tile>
    <v-lazy>
      <card-preview
        :mode="mode"
        :card="order"
        :is-card-owner="isCardOwner"
        :is-verified-recipient="isVerifiedRecipient"
        :width="w"
      />
    </v-lazy>
  </v-card>
</template>

<script>
import CardPreview from '@/components/CardPreview.vue';
import { nullCoverDesignId } from '@/mixins/public-design.js';
import { findGroupVideoBlob } from '@/utils/group-video.js';

export default {
  name: 'account-order-preview',

  components: {
    CardPreview
  },

  props: [ 'mode', 'order', 'isCardOwner', 'isVerifiedRecipient', 'width' ],

  computed: {
    poster() {
      if (this.order.design_id != nullCoverDesignId)
        return null;
      const b = findGroupVideoBlob(this.order);
      return b ? b.group_video.poster : '/poster-placeholder.png';
    },

    w() {
      return this.width || (this.$vuetify.breakpoint.xsOnly ? 112 : 84);
    }
  }
}
</script>

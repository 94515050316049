<template>
  <nav-menu
    name="Group videos"
    title="Make a video with a group"
    subtitle="Instant results, no video editing skills required"
    action="View all video themes"
    :icon="mdiVideoVintage"
    :to="{ name: 'catalog-video' }"
    v-bind="$attrs" v-on="$listeners"
  >
    <v-row class="ma-0">
      <v-col cols="12" sm="6">
        <div class="caption mb-2">Professional quality themes help you get creative</div>
        <v-row class="ma-n1">
          <v-col
            v-for="[ templateId, q, name, poster ] in [
              [ 'c02e39f8-92ab-42de-8cb9-71bf0bce37b5', 'Birthday', 'Birthday stand-up', 'poster_6cafbc3d-9a94-40da-9998-eb1b318d51f1.jpg' ],
              [ '5f1223b7-deb4-4fd2-ab87-334ee8850dc7', 'Wedding', 'Bridal shower', 'poster_faff2a97-96ed-4060-9844-ccf43bdbb229.jpg' ],
              [ '49a43bad-9ecf-423f-8929-0b8ff6532745', 'Graduation', 'Graduation cheers', 'poster_f87c459b-c259-4361-b3e5-67ed55edd3b7.jpg' ],
              [ '9ffd2882-d92f-4eb5-9f2f-5f302d73c86c', 'Work', 'Retirement party', 'poster_9b517036-d37b-4db0-98dc-43eace3503d5.jpg' ]
            ]"
            :key="templateId"
            class="pa-1"
            cols="6"
          >
            <v-card hover :to="{ name: 'catalog-video', params: { templateId }, query: { q } }">
              <v-img
                :aspect-ratio="16 / 9"
                :alt="name"
                :src="`https://cdn.ellacard.com/group-video-template/${templateId}/${poster}`"
              />
            </v-card>
            <!-- <router-link :to="{ name: 'catalog-video', params: { templateId }, query: { q } }" v-html="name" /> -->
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="caption mb-1">Instant results, no matter how big your group is, and it works on any device</div>
        <v-btn class="ma-n2" text color="primary" :to="{ name: 'catalog-video', hash: '#intro' }">
          <v-icon small class="mr-1" v-html="mdiPlay" />See how it works
        </v-btn>
        <outline-phone class="mt-3">
          <v-lazy>
            <video
              style="cursor: pointer;"
              width="100%"
              playsinline autoplay muted loop
              src="https://cdn.ellacard.com/start/group_video_header_720.mp4"
              srcset="
                https://cdn.ellacard.com/start/group_video_header_720.mp4 720w,
                https://cdn.ellacard.com/start/group_video_header_640.mp4 640w,
                https://cdn.ellacard.com/start/group_video_header_480.mp4 480w,
                https://cdn.ellacard.com/start/group_video_header_320.mp4 320w
              "
              @click="$router.push({ name: 'catalog-video', hash: '#intro' })"
            />
          </v-lazy>
        </outline-phone>
      </v-col>
    </v-row>
  </nav-menu>
</template>

<script>
import NavMenu from '@/components/NavMenu.vue';
import OutlinePhone from '@/components/OutlinePhone.vue';
import { mdiPlay, mdiVideoVintage } from '@mdi/js';

export default {
  name: 'nav-menu-group-videos',

  components: {
    NavMenu,
    OutlinePhone
  },

  created() {
    Object.assign(this, { mdiPlay, mdiVideoVintage });
  }
}
</script>

<template>
  <div>
    <p class="mb-12"><ella-card /> is the easiest way to create and send a beautiful group greeting card for any occasion. Start with our extensive searchable catalog or design your own greeting card. <router-link :to="{ name: 'design' }">Become an Ellacard designer</router-link> and get paid for every sale of your design. Add anything you want to your card: stylized text, pictures, videos, GIFs<template v-if="!global.xgc">, gift cards</template>, and more. All Ellacard products are <b>collaborative</b> – it's never been easier for a group to come together to create a memorable greeting card, and it all works right in your browser on any device.</p>

    <p class="mb-12">Want to create something truly memorable? Try Ellacard's <router-link :to="{ name: 'start-group-videos' }">group videos</router-link> – anyone can start with one of our themes to create a beautiful video with friends, family, or coworkers, <b>no editing required</b>. Ellacard guides your group through exactly what to do to make a video, and automatically <b>instantly</b> makes it for you. <router-link :to="{ name: 'catalog-video' }">Start creating a video</router-link> for free!</p>

    <p class="mb-12"><ella-card /> is <router-link :to="{ name: 'business' }">perfect for businesses</router-link>. Use Ellacard for internal employee engagement, like kudos, birthdays, or welcome videos, or use it for outreach to customers / donors. Ellacard makes it easy to send bulk campaigns featuring your greeting cards and videos – perfect for sending thank you cards or engaging customers. We've built a whole suite of tools to manage organization members and track usage. Wrap our cards, videos, and our collaborative design tools with your own custom branding. Ellacard also has a <router-link :to="{ name: 'api-docs' }">robust API</router-link> and can be easily embedded in your own site.</p>

    <v-row>
      <v-col
        v-for="([ title, content ], i) in [
          [
            'Built-in collaboration',
            `Every part of Ellacard was designed with collaboration in mind, from group cards with hundreds of pages to hour+ long custom group videos to gift cards split across dozens of contributors.`
          ],
          [
            'The best visuals',
            `From funny animated card designs to emotional video graphics, Ellacard makes it easy to create beautiful content. Send cards with confetti, romantic rose petals, or even a spring rain storm.`
          ],
          [
            'Search that works',
            `Find exactly the right greeting card with our super fast and effective search.`
          ],
          [
            'Truly cross-platform', `Ellacard works perfectly on every device. Use pinch / rotate gestures on mobile. Record videos directly in your web browser on desktop.`
          ],
          [
            'Drag-and-drop',
            `Everything is drag-and-drop, whether you're uploading images or videos from your computer, rearranging content on card pages, or rearranging group video contributions.`
          ],
          [
            'Instant videos with no editing',
            `Only Ellacard offers the ability to create directed, themed videos with a group <i>instantly</i> and <i>automatically</i> from any device <i>without any video editing</i>. It's free to try, just <a href='/videos'>pick a theme</a> to get started!`
          ]
        ]"
        :key="i"
        cols="12" sm="6" md="4"
      >
        <v-card class="fill-height" :color="COLORS[i % COLORS.length] + ' lighten-5'">
          <v-card-title v-html="title" />
          <v-card-text v-html="content" />
        </v-card>
      </v-col>
    </v-row>
    <div class="mt-6 text-md-h6 text-center">Find more questions / answers in our <router-link :to="{ name: 'faq' }">FAQ</router-link> or contact us: <a href="mailto:contact@ellacard.com">contact@ellacard.com</a></div>
  </div>
</template>

<script>
import EllaCard from '@/components/EllaCard.vue';

export default {
  name: 'start-section',
  components: { EllaCard },
  props: { title: String, subtitle: String, id: String },
  created() {
    this.COLORS = [
      'pink',
      'green',
      'indigo',
      'blue',
      'orange',
      'deep-purple',
      'light-green',
      'deep-orange',
      'purple'
    ];
  }
}
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticStyle: {
      "max-width": "600px"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', [_vm._v("Personal card")]), _c('v-spacer'), _c('div', {
    staticClass: "primary--text"
  }, [_c('price', {
    attrs: {
      "product": "CARD_PERSONAL"
    }
  }), _vm._v(" + 10¢"), _c('span', {
    staticClass: "caption grey--text"
  }, [_vm._v(" / recipient*")])], 1)], 1), _c('div', {
    staticClass: "mt-n1 caption"
  }, [_vm._v("Signed by you only")]), _c('div', {
    staticClass: "mt-3 d-flex"
  }, [_c('div', [_vm._v("Group card")]), _c('v-spacer'), _c('div', {
    staticClass: "primary--text"
  }, [_c('price', {
    attrs: {
      "product": "CARD_GROUP"
    }
  }), _vm._v(" + 10¢"), _c('span', {
    staticClass: "caption grey--text"
  }, [_vm._v(" / contributor*")]), _vm._v(" + 10¢"), _c('span', {
    staticClass: "caption grey--text"
  }, [_vm._v(" / recipient*")])], 1)], 1), _c('div', {
    staticClass: "mt-n1 caption"
  }, [_vm._v("Invite contributors to sign")]), _c('div', {
    staticClass: "mt-3 d-flex"
  }, [_c('div', [_vm._v("Event invitation")]), _c('v-spacer'), _c('div', {
    staticClass: "primary--text"
  }, [_c('price', {
    attrs: {
      "product": "CARD_INVITATION"
    }
  }), _vm._v(" + 10¢"), _c('span', {
    staticClass: "caption grey--text"
  }, [_vm._v(" / recipient*")])], 1)], 1), _c('div', {
    staticClass: "mt-n1 caption"
  }, [_vm._v("Includes RSVPs")])]), _c('div', {
    staticClass: "mt-6 caption green--text"
  }, [_vm._v("* Send your card to "), _c('i', [_vm._v("recipients")]), _vm._v(" or invite "), _c('i', [_vm._v("contributors")]), _vm._v(" to sign a group card for "), _c('v-chip', {
    staticClass: "px-1",
    attrs: {
      "color": "green",
      "dark": "",
      "label": "",
      "x-small": ""
    }
  }, [_vm._v("free")]), _vm._v(" via a shared link / social media post (as opposed to an email / text message sent by Ellacard).")], 1), _c('div', {
    staticClass: "mt-9",
    staticStyle: {
      "max-width": "600px"
    }
  }, [_c('div', {
    staticClass: "mt-3 d-flex"
  }, [_vm._m(0), _c('v-spacer'), _c('price', {
    attrs: {
      "value": "0"
    }
  }), _c('span', {
    staticClass: "primary--text mx-1"
  }, [_vm._v("–")]), _c('price', {
    attrs: {
      "value": "20"
    }
  })], 1), _c('div', {
    staticClass: "mt-3 d-flex"
  }, [_vm._m(1), _c('v-spacer'), _c('price', {
    attrs: {
      "value": "0.50"
    }
  }), _c('span', {
    staticClass: "primary--text ml-1"
  }, [_vm._v(" + 5%")])], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mb-n1"
  }, [_vm._v("Group videos")]), _c('div', {
    staticClass: "caption"
  }, [_vm._v("Custom video templates available")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mb-n1"
  }, [_vm._v("Gift cards")]), _c('div', {
    staticClass: "caption"
  }, [_vm._v("Includes international gift cards")])]);
}]

export { render, staticRenderFns }
import { render, staticRenderFns } from "./QuoteCard.vue?vue&type=template&id=ca57516e&scoped=true"
import script from "./QuoteCard.vue?vue&type=script&lang=js"
export * from "./QuoteCard.vue?vue&type=script&lang=js"
import style0 from "./QuoteCard.vue?vue&type=style&index=0&id=ca57516e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca57516e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VImg,VSpacer})

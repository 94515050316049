var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.poster ? _c('v-responsive', {
    staticClass: "d-flex align-center overflow-visible",
    attrs: {
      "aspect-ratio": _vm.$vuetify.breakpoint.xsOnly ? 16 / 9 : 5 / 7,
      "width": _vm.w
    }
  }, [_c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.poster
    }
  })], 1)], 1) : _c('v-card', {
    staticClass: "overflow-visible",
    style: {
      width: _vm.w + 'px',
      height: _vm.w / (5 / 7) + 'px'
    },
    attrs: {
      "tile": ""
    }
  }, [_c('v-lazy', [_c('card-preview', {
    attrs: {
      "mode": _vm.mode,
      "card": _vm.order,
      "is-card-owner": _vm.isCardOwner,
      "is-verified-recipient": _vm.isVerifiedRecipient,
      "width": _vm.w
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <nav-menu
    name="Group greeting cards"
    title="Send any card as a group greeting card"
    subtitle="Invite your group to sign remotely"
    action="View greeting card categories"
    :icon="mdiCards"
    :to="{ name: 'catalog-categories', query: { type: 'greeting-cards' } }"
    v-bind="$attrs" v-on="$listeners"
  >
    <div class="pa-3 d-sm-flex flex-row-reverse">
      <div class="flex-shrink-0 ml-sm-3" v-bind:style="{ width: $vuetify.breakpoint.xsOnly ? '100%' : '320px' }">
        <template v-if="!global.xgc">
          <div class="caption">Contribute to group gift cards</div>
          <div class="mx-n2 mb-6">
            <v-btn text color="primary" :to="{ name: 'catalog-gift-cards' }">
              <v-icon small class="mr-1" v-html="mdiWalletGiftcard" />View all gift cards
            </v-btn>
          </div>
        </template>

        <div class="mb-6 caption">Perfect for <router-link :to="{ name: 'business' }">business</router-link> (remote or in-person)</div>

        <v-responsive class="overflow-visible" :aspect-ratio="320 / 246">
          <v-lazy>
            <v-card outlined>
              <video
                class="d-block"
                width="100%" playsinline="" autoplay="autoplay" muted="muted" loop="loop"
                src="https://cdn.ellacard.com/nav/group_cards_320x246.mp4"
              />
            </v-card>
          </v-lazy>
        </v-responsive>
        <div class="mt-2 mb-3 caption">Unlimited contributors, unlimited pages. Scheduled send via <span class="green--text">email</span> or <span class="green--text">text message</span>.</div>
      </div>

      <div class="flex-grow-1 mb-6 mb-sm-0">
        <template v-for="c, i in categories">
          <div v-if="c.seasonality != categories[i-1]?.seasonality" class="caption" v-bind:class="{ 'mt-4': i }">
            <template v-if="c.seasonality == 0">Private collections</template>
            <template v-else-if="c.seasonality == 1">Featured seasonal occasions</template>
            <template v-else>{{i ? '' : 'Group cards for any occasion'}}</template>
          </div>
          <v-btn
            class="ml-n2"
            text exact
            :color="c.icon_color || '#1976d2'"
            :to="{ name: 'catalog-category', params: { category: c.name } }"
          >
            <v-icon small class="mr-1" v-html="c.icon" />
            <div>{{c.title}}</div>
          </v-btn>
          <br>
        </template>
      </div>
    </div>
  </nav-menu>
</template>

<script>
import NavMenu from '@/components/NavMenu.vue';
import loadCategories from '@/mixins/load-categories.js';
import { mdiCards, mdiWalletGiftcard } from '@mdi/js';

export default {
  name: 'nav-menu-group-greeting-cards',

  mixins: [ loadCategories ],

  components: {
    NavMenu
  },

  data() {
    return {
      categories: []
    };
  },

  created() {
    Object.assign(this, { mdiCards, mdiWalletGiftcard });
  },

  watch: {
    loadCategoriesKey: {
      immediate: true,
      async handler() {
        this.categories = (await this.loadCategories()).filter(x => x.seasonality >= 0 && x.seasonality <= 2 && [ 0, 1 ].includes(x.type));
      }
    }
  }
}
</script>

<template>
  <span :class="(color || 'primary') + '--text' + (bold ? ' font-weight-bold' : '')" style="white-space: nowrap;">
    <template v-if="v == ''">N/A</template>
    <template v-else>
      <currency :code="cur" :negative="v.startsWith('-')" />
      <span>{{left}}</span>
      <template v-if="right">
        <span>.</span>
        <span style="font-size: .6em; line-height: 1.43; vertical-align: text-top;" v-text="right" />
      </template>
      <span v-if="per" :class="'ml-2 caption ' + (color || 'grey') + '--text' + (bold ? ' font-weight-bold' : '')" >/ {{per}}</span>
    </template>
  </span>
</template>

<script>
import Currency from '@/components/Currency.vue';

const PRODUCT_PRICES = {
  CARD_PERSONAL: '2.00',
  CARD_GROUP: '4.00',
  CARD_INVITATION: '7.00',
  ADDTL_RECIPIENTS_BLOCK: '1.00',
  ADDTL_CONTRIBUTORS_BLOCK: '1.00'
};

export default {
  name: 'price',

  components: { Currency },

  props: {
    product: String,
    value: [ String, Number ],
    per: String,
    color: String,
    cur: { type: String, default: 'USD' },
    bold: Boolean,
    showDecimal: Boolean
  },

  computed: {
    v() {
      return PRODUCT_PRICES[this.product] || (this.value == null ? '' : String(this.value));
    },

    left() {
      const left = this.v.split('.')[0].replace(/-/, '').replace(/^0+/, '');
      return this.right ? left : (left || '0');
    },

    right() {
      const v = this.v.split('.')[1];
      return this.showDecimal ? (v || '00') : (v == '00') ? null : v;
    }
  }
}
</script>

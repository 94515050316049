var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: _vm.blob.giftcard.id,
    staticClass: "absolute-fill elevation-8 gc-border"
  }, [_vm.placeholder ? _c('div', {
    staticClass: "absolute-fill placeholder"
  }) : _vm._e(), _c('img', {
    staticClass: "user-select-none gc-border",
    attrs: {
      "src": _vm.blob.giftcard.product_img,
      "draggable": "false"
    },
    on: {
      "load": _vm.onLoad
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive-bounds', {
    attrs: {
      "aspect-ratio": 5 / 7,
      "width": _vm.width
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var bounds = _ref.bounds;
        return [_c('div', {
          staticClass: "card-preview-page",
          class: {
            editable: _vm.editable
          },
          style: {
            transform: ("scale(" + (bounds.w / _vm.W) + ")"),
            width: _vm.W + 'px',
            height: _vm.H + 'px',
            'background-image': _vm.card.background.img ? ("url('" + (_vm.card.background.img) + "')") : null,
            'background-color': _vm.card.background.color
          }
        }, _vm._l(_vm.blobs, function (blob) {
          return _c('blob', {
            key: blob.id,
            ref: blob.id,
            refInFor: true,
            class: 'blob--' + blob.id,
            attrs: {
              "mode": _vm.mode,
              "is-page-active": _vm.editable,
              "scale": bounds.w / _vm.W,
              "blob": blob,
              "skin": _vm.card.skin || {},
              "is-primary": blob.user_id && blob.user_id == _vm.globalUserId,
              "is-card-owner": _vm.isCardOwner,
              "is-verified-recipient": _vm.isVerifiedRecipient,
              "static": _vm.static
            },
            on: {
              "loaded": function ($event) {
                return _vm.loaded(blob.id);
              },
              "input": function (x) {
                return _vm.$emit('update-blob', x);
              }
            }
          });
        }), 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }
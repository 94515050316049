import Vue from 'vue';
import VueRouter from 'vue-router';
import globalStorageMixin from '@/mixins/global-storage.js';

function onNavigationFailure(e) {
  // Ignore errors of these types:
  // https://v3.router.vuejs.org/guide/advanced/navigation-failures.html#navigationfailuretype
  for (const key of [ 'duplicated', 'redirected', 'aborted' ])
    if (VueRouter.isNavigationFailure(e, VueRouter.NavigationFailureType[key]))
      return e.to;
  throw e;
}

// // Monkey-patch VueRouter's 'push' function to A) avoid logging extraneous exceptions and B) add an additional 'stack'
// // parameter to the 'location'. If that parameter is ±1, we will try to use the browser's navigation to go backwards
// // (-1) or forwards (+1). Note that we do not support the 'onComplete' and 'onAbort' parameters.
// let stack = [ location.href.substr(location.origin.length) ];
// let stackI = 0;
// let isAppNav = false;

// // This event is triggered when we call the router's 'go' method or when we use the browser's back / forwards buttons.
// // It is not triggered when we call 'push' or 'replace'.
// function onHistoryStateChange(e) {
//   const target = location.href.substr(location.origin.length);
//   if (stack[stackI] == target) {
//     // We're already at the correct position in the stack
//   } else if (stack[stackI - 1] == target) {
//     stackI--;
//   } else if (stack[stackI + 1] == target) {
//     stackI++;
//   } else if (isAppNav) {
//     // Our current route is not in the stack. Note that we're careful to check that we actually created this nav event
//     // ourselves - our 'stack' state goes away if the browser gets refreshed, but the browser's does not.
//     stackI++;
//     stack.splice(stackI, stack.length);
//     stack.push(target);

//     // Make sure the stack doesn't grow too big
//     while (stack.length > 20) {
//       stack.shift();
//       stackI--;
//     }
//   }
// }

// addEventListener('popstate', onHistoryStateChange);

// onHistoryStateChange();

// const _go = VueRouter.prototype.go;
// VueRouter.prototype.go = function(n) {
//   return new Promise(
//     r => {
//       const fn = () => {
//         removeEventListener('popstate', fn);
//         isAppNav = false;
//         r();
//       };
//       isAppNav = true;
//       addEventListener('popstate', fn);
//       _go.call(this, n);
//     }
//   );
// }

// const _push = VueRouter.prototype.push;
// VueRouter.prototype.push = async function(routerLocation) {
//   if (this.app.$children[0]?.showNavMenu)
//     this.app.$children[0].showNavMenu = false;

//   // When we call 'push', instead of navigating directly to the new location and always adding to the browser's history
//   // stack, if the previous entry in our stack aligns with that route, then just go back, and if the next entry in our
//   // stack aligns with that route, then go forwards.

//   const target = this.resolve(routerLocation).href;
//   let go;
//   if (stack[stackI - 1] == target)
//     go = this.go(-1);
//   else if (stack[stackI + 1] == target)
//     go = this.go(1);

//   if (go) {
//     // Our stack can get corrupted, so if we usurped the default behavior, make sure we did it correctly
//     return await go;
//     // console.log(this)
//     // if (target == this.resolve(this.currentRoute).href)
//     //   return this.currentRoute;
//     // console.log('Corrupted stack??')
//   }

//   return await _push.call(this, routerLocation).catch(onNavigationFailure).then(() => {
//     isAppNav = true;
//     onHistoryStateChange();
//     isAppNav = false;
//   });
// }

// const _replace = VueRouter.prototype.replace;
// VueRouter.prototype.replace = function(routerLocation) {
//   stack[stackI] = this.resolve(routerLocation).href;
//   onHistoryStateChange();
//   return _replace.call(this, routerLocation).catch(onNavigationFailure);
// }

// // We never want to go 'back' out of the website
// const _back = VueRouter.prototype.back;
// VueRouter.prototype.back = function() {
//   stackI--;
//   onHistoryStateChange();
//   return _back.call(this);
// }

for (const key of [ 'push', 'replace' ]) {
  const patched = VueRouter.prototype[key];
  VueRouter.prototype[key] = function(to) {
    return patched.call(this, to).catch(onNavigationFailure);
  }
}

Vue.use(VueRouter);

// https://www.digitalocean.com/community/tutorials/vuejs-lazy-loading-vue-cli-3-webpack
function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}

// A regex that you can use with route params to match UUIDs only
const UUID = '([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})';

const routes = [
  {
    path: '/',
    name: 'default',
    redirect(to) {
      return { name: globalStorageMixin.data().global.jwt ? 'catalog' : 'start' };
    }
  },
  {
    path: '/start',
    name: 'start',
    component: loadView('Start')
  },
  {
    path: '/start/group-cards',
    name: 'start-group-cards',
    component: loadView('StartGroupCards'),
    meta: { title: 'Group Cards' }
  },
  {
    path: '/start/group-videos',
    name: 'start-group-videos',
    component: loadView('StartGroupVideos'),
    meta: { title: 'Group Videos' }
  },
  {
    path: '/start/gift-cards',
    name: 'start-gift-cards',
    component: loadView('StartGiftCards'),
    meta: { title: 'Gift Cards' }
  },
  {
    path: '/start/business',
    name: 'start-business',
    component: loadView('StartBusiness'),
    meta: { title: 'Business' }
  },
  {
    path: '/start/business-internal',
    name: 'start-business-internal',
    component: loadView('StartBusinessInternal'),
    meta: { title: 'Business - Internal' }
  },
  {
    path: '/start/business-outreach',
    name: 'start-business-outreach',
    component: loadView('StartBusinessOutreach'),
    meta: { title: 'Business - Outreach' }
  },
  {
    path: '/start/charity',
    name: 'start-charity',
    component: loadView('StartCharity'),
    meta: { title: 'Charity' }
  },
  {
    path: '/start/holiday',
    name: 'start-holiday',
    component: loadView('StartHoliday'),
    meta: { title: 'Holiday' }
  },
  {
    path: '/start/yearbooks',
    name: 'start-yearbooks',
    component: loadView('StartYearbooks'),
    meta: { title: 'Yearbooks' }
  },
  {
    path: '/start/:category',
    name: 'start-category',
    props: true,
    component: loadView('StartCategory')
  },
  {
    path: `/videos/:templateId${UUID}?`,
    name: 'catalog-video',
    props: true,
    component: loadView('CatalogVideo'),
    meta: { title: 'Videos', hide: [ 'footer' ] }
  },
  {
    path: '/collections/:collectionId',
    name: 'collections',
    props: true,
    component: loadView('Collections'),
    meta: { title: 'Collection', hide: [ 'header', 'footer' ] }
  },
  {
    path: '/collections/:collectionId/add',
    name: 'collections-add',
    props: true,
    component: loadView('CollectionsAdd'),
    meta: { title: 'Add to Collection', hide: [ 'header', 'footer' ] }
  },
  {
    path: `/cards/:selectedId${UUID}?`,
    name: 'catalog',
    props: true,
    component: loadView('Catalog'),
    meta: {
      title: 'Cards',
      hide: [ 'footer' ],
      canonical: r => {
        if (r.params.selectedId)
          return '/cards/' + r.params.selectedId;
        const q = [];
        for (const key of [ 'q', 'sort-by' ])
          if (r.query[key])
            q.push(key + '=' + encodeURIComponent(r.query[key]));
        return '/cards' + (q.length ? ('?' + q.join('&')) : '');
      }
    }
  },
  // {
  //   path: `/cards/index/:designId${UUID}`,
  //   name: 'card-design',
  //   props: true,
  //   component: loadView('CanonicalDesign'),
  //   meta: { hide: [ 'footer' ] }
  // },
  {
    path: '/categories',
    name: 'catalog-categories',
    component: loadView('CatalogCategories'),
    meta: { title: 'Categories', hide: [ 'footer' ] }
  },
  {
    path: `/categories/:category/:selectedId${UUID}?`,
    name: 'catalog-category',
    props: true,
    component: loadView('CatalogCategory'),
    meta: {
      hide: [ 'footer' ],
      canonical: r => {
        if (r.params.selectedId)
          return '/cards/' + r.params.selectedId;
        const q = [];
        for (const key of [ 'q', 'sort-by' ])
          if (r.query[key])
            q.push(key + '=' + encodeURIComponent(r.query[key]));
        return '/categories/' + r.params.category + (q.length ? ('?' + q.join('&')) : '');
      }
    }
  },
  {
    path: '/gift-cards/:providerId?',
    name: 'catalog-gift-cards',
    props: true,
    component: loadView('CatalogGiftCards'),
    meta: { title: 'Gift cards', hide: [ 'footer' ] }
  },
  {
    path: `/project/:orderId?`,
    name: 'project',
    props: true,
    component: loadView('Project'),
    meta: { title: 'Project', hide: [ 'header', 'footer' ] }
  },
  {
    path: '/account',
    name: 'account',
    component: loadView('Account'),
    meta: { title: 'Account', hide: [ 'footer' ], index: r => false },
    children: [
      {
        path: 'overview',
        name: 'account-overview',
        component: loadView('AccountOverview'),
        meta: { title: 'Overview' }
      },
      {
        path: 'orders/:orderId?',
        name: 'account-orders',
        props: true,
        component: loadView('AccountOrders'),
        meta: { title: 'Orders' }
      },
      {
        path: 'signed/:orderId?',
        name: 'account-signed',
        props: true,
        component: loadView('AccountSigned'),
        meta: { title: 'Signed' }
      },
      {
        path: 'received',
        name: 'account-received',
        component: loadView('AccountReceived'),
        meta: { title: 'Received' }
      },
      {
        path: 'contacts',
        name: 'account-contacts',
        component: loadView('AccountContacts'),
        meta: { title: 'Contacts' }
      },
      {
        path: 'design',
        name: 'account-design',
        component: loadView('AccountDesign'),
        meta: { title: 'Design Portal' },
        redirect: { name: 'account-design-cards' },
        children: [
          {
            path: 'overview',
            name: 'account-design-overview',
            component: loadView('AccountDesignOverview'),
            meta: { title: 'Overview' }
          },
          {
            path: 'cards/:designId?',
            props: true,
            name: 'account-design-cards',
            component: loadView('AccountDesignCards'),
            meta: { title: 'Cards' }
          },
          {
            path: 'videos/:templateId?',
            props: true,
            name: 'account-design-videos',
            component: loadView('AccountDesignVideos'),
            meta: { title: 'Videos' }
          }
        ]
      },
      {
        path: 'org',
        name: 'account-org',
        component: loadView('AccountOrg'),
        meta: { title: 'Organization' },
        redirect: { name: 'account-org-overview' },
        children: [
          {
            path: 'overview',
            name: 'account-org-overview',
            component: loadView('AccountOrgOverview'),
            meta: { title: 'Overview' }
          },
          {
            path: 'users',
            name: 'account-org-users',
            component: loadView('AccountOrgUsers'),
            meta: { title: 'Users' }
          },
          {
            path: 'user-activity',
            name: 'account-org-user-activity',
            component: loadView('AccountOrgUserActivity'),
            meta: { title: 'User Activity' }
          },
          {
            path: 'orders/:orderId?',
            props: true,
            name: 'account-org-orders',
            component: loadView('AccountOrgOrders'),
            meta: { title: 'Orders' }
          },
          {
            path: 'unsubscribes',
            name: 'account-org-unsubscribes',
            component: loadView('AccountOrgUnsubscribes'),
            meta: { title: 'Unsubscribes' }
          },
          {
            path: 'branding',
            name: 'account-org-branding',
            component: loadView('AccountOrgBranding'),
            meta: { title: 'Branding' }
          },
          {
            path: 'embedding',
            name: 'account-org-embedding',
            component: loadView('AccountOrgEmbedding'),
            meta: { title: 'Embedding' }
          },
          {
            path: 'keys',
            name: 'account-org-keys',
            component: loadView('AccountOrgKeys'),
            meta: { title: 'Keys' }
          }
        ]
      }
    ]
  },
  {
    path: '/design',
    name: 'design',
    props: true,
    component: loadView('Design'),
    meta: { title: 'Design' }
  },
  {
    path: '/video-template-docs',
    name: 'video-template-docs',
    component: loadView('VideoTemplateDocs'),
    meta: { title: 'Video Template Docs' }
  },
  {
    path: '/api-docs',
    name: 'api-docs',
    component: loadView('APIDocs'),
    meta: { title: 'API Docs' }
  },
  {
    path: '/business',
    name: 'business',
    component: loadView('Business'),
    meta: { title: 'Business' }
  },
  {
    path: '/featured/index',
    name: 'featured-index',
    component: loadView('FeaturedIndex'),
    meta: { title: 'Featured Index', hide: [ 'footer' ] }
  },
  {
    path: `/featured/:id${UUID}?`,
    name: 'featured',
    props: true,
    component: loadView('Featured'),
    meta: { title: 'Featured' }
  },
  {
    path: '/admin',
    name: 'admin',
    component: loadView('Admin'),
    meta: { title: 'Admin', hide: [ 'footer' ], index: r => false },
    children: [
      {
        path: 'review',
        name: 'admin-review',
        component: loadView('AdminReview'),
        children: [
          {
            path: 'card/:designId',
            name: 'admin-review-card',
            props: true,
            component: loadView('AdminReviewCard')
          },
          {
            path: 'video/:templateId',
            name: 'admin-review-video',
            props: true,
            component: loadView('AdminReviewVideo')
          }
        ]
      },
      {
        path: 'user',
        name: 'admin-user',
        component: loadView('AdminUser'),
        children: [
          {
            path: ':userId',
            name: 'admin-user-details',
            props: true,
            component: loadView('AdminUserDetails')
          }
        ]
      },
      {
        path: 'order/:token?',
        name: 'admin-order',
        component: loadView('AdminOrder')
      },
      {
        path: 'tracking',
        name: 'admin-tracking',
        props: true,
        component: loadView('AdminTracking')
      },
      {
        path: 'domain-risks',
        name: 'admin-domain-risks',
        component: loadView('AdminDomainRisks')
      },
      {
        path: 'orgs/:orgId?',
        name: 'admin-orgs',
        props: true,
        component: loadView('AdminOrgs')
      },
      {
        path: 'promo-codes',
        name: 'admin-promo-codes',
        component: loadView('AdminPromoCodes')
      },
      {
        path: 'payments',
        name: 'admin-payments',
        component: loadView('AdminPayments')
      },
      {
        path: 'manage-catalog',
        name: 'admin-manage-catalog',
        component: loadView('AdminCatalog')
      },
      {
        path: 'manage-gift-card-catalog',
        name: 'admin-manage-gift-card-catalog',
        component: loadView('AdminGiftCardCatalog')
      },
      {
        path: 'gift-cards',
        name: 'admin-gift-cards',
        component: loadView('AdminGiftCards')
      },
      {
        path: 'categories/:name?',
        name: 'admin-categories',
        props: true,
        component: loadView('AdminCatalogHeaders')
      },
      {
        path: 'designers',
        name: 'admin-designers',
        component: loadView('AdminDesigners')
      },
      {
        path: 'unsubscribes',
        name: 'admin-unsubscribes',
        component: loadView('AdminUnsubscribes')
      },
      {
        path: 'faq-editor',
        name: 'admin-faq-editor',
        component: loadView('AdminFAQEditor')
      },
      {
        path: 'blog-editor/:id?',
        name: 'admin-blog-editor',
        props: true,
        component: loadView('AdminBlogEditor')
      },
      {
        path: 'pricing',
        name: 'admin-pricing',
        component: loadView('AdminPricing')
      },
      {
        path: 'customer-segments',
        name: 'admin-customer-segments',
        component: loadView('AdminCustomerSegments')
      },
      {
        path: 'analytics',
        name: 'admin-analytics',
        component: loadView('AdminAnalytics')
      },
      {
        path: 'web-media',
        name: 'admin-web-media',
        component: loadView('AdminWebMedia')
      }
    ]
  },
  {
    path: `/sign/:orderId${UUID}`,
    name: 'card-sign',
    props: true,
    component: loadView('CardSign'),
    meta: { title: 'Sign', hide: [ 'header', 'footer' ] }
  },
  {
    path: `/video-project/:projectId${UUID}`,
    name: 'video-project',
    props: true,
    component: loadView('GroupVideoProject'),
    meta: { title: 'Video Project', hide: [ 'header', 'footer' ] }
  },
  {
    path: `/open/:readToken${UUID}`,
    name: 'card-open',
    props: true,
    component: loadView('CardOpen'),
    meta: { title: 'Open', hide: [ 'header', 'footer' ] }
  },
  {
    path: '/img-to-design',
    name: 'img-to-design',
    component: loadView('ImgToDesign'),
    meta: { title: 'Image to Card' }
  },
  {
    path: '/batch-order',
    name: 'batch-order',
    component: loadView('BatchOrder'),
    meta: { title: 'Batch order' }
  },
  {
    path: '/bulk-email',
    name: 'bulk-email',
    component: loadView('BulkEmail'),
    meta: { title: 'Bulk email' }
  },
  {
    path: `/bulk-email/:campaignId${UUID}`,
    name: 'bulk-email-campaign',
    props: true,
    component: loadView('BulkEmailCampaign'),
    meta: { title: 'Bulk email campaign', hide: [ 'header', 'footer' ] }
  },
  {
    path: '/file-transfer',
    name: 'file-transfer',
    component: loadView('FileTransfer'),
    meta: { title: 'File transfer' }
  },
  {
    path: '/confirm-unsubscribe',
    name: 'confirm-unsubscribe',
    component: loadView('ConfirmUnsubscribe'),
    meta: { title: 'Confirm unsubscribe', hide: [ 'header', 'footer' ] }
  },
  {
    path: '/unsubscribe',
    name: 'unsubscribe',
    component: loadView('Unsubscribe'),
    meta: { title: 'Unsubscribe' }
  },
  {
    path: '/report-sender',
    name: 'report-sender',
    component: loadView('ReportSender'),
    meta: { title: 'Report Sender' }
  },
  {
    path: '/about',
    name: 'about',
    component: loadView('About'),
    meta: { title: 'About' }
  },
  {
    path: '/faq',
    name: 'faq',
    component: loadView('FAQ'),
    meta: { title: 'FAQ' }
  },
  {
    path: '/blog',
    name: 'blog',
    component: loadView('Blog'),
    meta: { title: 'Blog' }
  },
  {
    path: '/blog/:key',
    name: 'blog-post',
    props: true,
    component: loadView('BlogPost'),
    meta: { title: 'Blog' }
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: loadView('Pricing'),
    meta: { title: 'Pricing' }
  },
  {
    path: '/international-gift-cards',
    name: 'international-gift-cards',
    component: loadView('InternationalGiftCards'),
    meta: { title: 'International Gift Cards' }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: loadView('PrivacyPolicy'),
    meta: { title: 'Privacy Policy' }
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: loadView('Cookies'),
    meta: { title: 'Cookies' }
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: loadView('TermsAndConditions'),
    meta: { title: 'Terms and Conditions' }
  },
  {
    path: '/site-map',
    name: 'site-map',
    component: loadView('SiteMap'),
    meta: { title: 'Site Map' }
  },
  {
    path: '/release-notes/:date?',
    name: 'release-notes',
    props: true,
    component: loadView('ReleaseNotes'),
    meta: { title: 'Release Notes', hide: [ 'footer' ] }
  },
  {
    path: '/social',
    name: 'social-media',
    component: loadView('SocialMedia'),
    meta: { title: 'Social Media' }
  },
  {
    path: '/chrome-extension',
    name: 'chrome-extension',
    redirect() {
      return { name: 'default' };
    }
  },
  {
    path: '/physical-virtual-cards',
    name: 'physical-virtual-cards',
    component: loadView('PhysicalVirtualCards')
  },
  {
    path: '/sign-in/:payload',
    name: 'sign-in',
    props: true,
    component: loadView('SignIn'),
    meta: { index: r => false, hide: [ 'header', 'footer' ] }
  },
  {
    path: '/notify',
    name: 'notify',
    component: loadView('Notify'),
    meta: { hide: [ 'header', 'footer' ] }
  },

  // We prefix "private" routes with an underscore - these are omitted from indexing in robots.txt
  {
    path: '/_/capture',
    name: '_capture',
    component: loadView('_Capture'),
    meta: { index: r => false, hide: [ 'header', 'footer' ] }
  },
  {
    path: '/_/print/:token',
    name: '_print',
    props: true,
    component: loadView('_Print'),
    meta: { index: r => false, hide: [ 'header', 'footer' ] }
  },
  {
    path: '/_/embed',
    name: '_embed-root',
    component: loadView('_EmbedRoot'),
    meta: { hide: [ 'header', 'footer' ] },
    children: [
      {
        path: 'checkout',
        name: 'embed-checkout',
        component: loadView('EmbedCheckout')
      },
      {
        path: 'catalog',
        name: 'embed-catalog',
        component: loadView('EmbedCatalog')
      },
      {
        path: 'sign/:orderId',
        name: 'embed-sign',
        props: true,
        component: loadView('CardSign')
      },
      {
        path: 'open/:readToken',
        name: 'embed-open',
        props: true,
        component: loadView('CardOpen')
      }
    ]
  },
  {
    path: '/_/reset',
    name: '_reset',
    redirect() {
      try {
        localStorage.clear();
      } catch (e) {}
      const data = globalStorageMixin.data().global;
      data.jwt = null;
      data.order = null;
      data.localBlobSrcMap = {};
      data.cookies = null;
      return { name: 'default' };
    }
  },
  {
    path: '/_/embedded-order',
    name: '_embedded-order',
    redirect() {
      let order;
      try {
        order = JSON.parse(localStorage.getItem('ellacard.embedded_order'));
        delete order.reply_to;
        localStorage.removeItem('ellacard.embedded_order');
      } catch (e) {}
      return { name: 'project', params: { orderId: 'new', order }, hash: '#type' };
    }
  },

  // Legacy redirects
  { path: '/start/business/internal', redirect: { name: 'start-business-internal' } },
  { path: '/start/business/outreach', redirect: { name: 'start-business-outreach' } },
  { path: '/available-gift-cards', redirect: { name: 'international-gift-cards' } },
  { path: '/account/organization-tools', redirect: { name: 'account-org' } },
  { path: '/account/designs', redirect: { name: 'account-design-cards' } },
  { path: '/design/videos/docs', redirect: { name: 'video-template-docs' } },
  { path: '/design/cards/:designId?', redirect: { name: 'account-design-cards' } },
  { path: '/design/videos/:templateId?', redirect: { name: 'account-design-videos' } },
  { path: '/design/payments', redirect: { name: 'account-design-overview' } },
  { path: '/img-to-card', redirect: { name: 'img-to-design' } },

  {
    path: '*',
    name: 'not-found',
    component: loadView('RouteNotFound'),
    meta: { index: r => false }
  }
];

function scrollBehavior(to, from, savedPosition) {
  if (savedPosition)
    return to.meta.dynamic ? to.meta.load.then(() => savedPosition) : savedPosition;
  if (!to.name || !from.name)
    return { x: 0, y: 0 };
  if (to.hash && document.getElementById(to.hash.substr(1)))
    // We use hashes to do things like bring up dialogs, so only scroll if we've verified that the selector exists
    return { selector: to.hash };
  if (to.matched[0].name == from.matched[0].name)
    // Don't scroll for nested views
    return;
  return { x: 0, y: 0 };
}

const router = new VueRouter({ mode: 'history', routes, scrollBehavior });

const STRIP_QUERY_PREFIXES = [ 'g_ad_' ];
const PERSIST_QUERY_PARAMS = [ 'gclid', 'msclkid' ];

router.beforeEach((to, from, next) => {
  // Set the title appropriately (I can't believe I have to do this myself)
  let title = 'Ellacard';
  let index = true;
  for (let i = to.matched.length - 1; i >= 0; i--) {
    if (to.matched[i].meta.title)
      title = to.matched[i].meta.title + ' | ' + title;
    if (index && to.matched[i].meta.index)
      index = to.matched[i].meta.index(to);
  }
  document.title = title;

  const noIndexEls = document.head.querySelectorAll('meta[name="robots"][content="noindex"]');
  if (index) {
    for (const noIndexEl of noIndexEls)
      noIndexEl.remove();
  } else if (!noIndexEls.length) {
    const m = document.createElement('meta');
    m.name = 'robots';
    m.content = 'noindex';
    document.head.appendChild(m);
  }

  // Set the canonical meta tag - note that we're careful to ignore all query / hash parameters
  const getCanonicalPath = to.matched[ to.matched.length - 1 ].meta?.canonical;
  const canonicalPath = getCanonicalPath && getCanonicalPath(to) || to.path.replace(/\/$/, '').toLowerCase();
  let el = document.head.querySelector('link[rel="canonical"]');
  if (!el) {
    el = document.createElement('link');
    el.setAttribute('rel', 'canonical');
    document.head.appendChild(el);
  }
  el.href = location.origin + canonicalPath;

  if (to.query) {
    let found = false;
    const query = { ...to.query };
    for (const key of Object.keys(query))
      for (const prefix of STRIP_QUERY_PREFIXES)
        if (key.startsWith(prefix))
          found = delete query[key];
    if (found) {
      // Make sure we capture the original navigation event before we strip the parameters
      setTimeout(() => $event.log('nav', _.omitBy(JSON.parse(JSON.stringify(_.pick(to, [ 'name', 'params', 'hash', 'query' ]))), _.isEmpty)));

      return next({ name: to.name, params: to.params, hash: to.hash, query });
    }
  }

  if (from.query) {
    const add = {};
    for (const p of PERSIST_QUERY_PARAMS)
      if (from.query[p] && !(to.query || {})[p])
        // Make sure we don't drop persistent query params
        add[p] = from.query[p];
    if (Object.values(add).length)
      return next({ name: to.name, params: to.params, hash: to.hash, query: { ...to.query, ...add } });
  }

  next();
});

router.afterEach((to, from) => {
  // https://developers.google.com/analytics/devguides/collection/gtagjs/pages#page_view_event
  gtag('event', 'page_view', { page_title: document.title, page_location: location.href, page_path: to.fullPath });

  // Note that we use JSON parse / stringify to recursively remove all undefined properties from the route object
  $event.log('nav', _.omitBy(JSON.parse(JSON.stringify(_.pick(to, [ 'name', 'params', 'hash', 'query' ]))), _.isEmpty));
});

export default router;

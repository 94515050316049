var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('error-dialog', {
    attrs: {
      "status": _vm.sendCodeStatus
    }
  }), _c('error-dialog', {
    attrs: {
      "status": _vm.verifyCodeStatus
    }
  }), _vm.showCaptcha ? _c('captcha-overlay', {
    on: {
      "cancel": function ($event) {
        _vm.showCaptcha = false;
      },
      "complete": _vm.sendCode
    }
  }) : _vm._e(), !_vm.sendCodeStatus.resolved ? _c('v-slide-x-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('v-text-field', {
    staticClass: "mt-0",
    attrs: {
      "name": "Email address",
      "label": "Email address",
      "hint": "We'll send you an email with a temporary code",
      "persistent-hint": "",
      "error": !!_vm.email && !_vm.isValid(_vm.email)
    },
    on: {
      "keydown": [function ($event) {
        $event.stopPropagation();
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        _vm.showCaptcha = !!_vm.isValid(_vm.email);
      }]
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('v-btn', {
          staticStyle: {
            "margin-bottom": "5px"
          },
          attrs: {
            "small": "",
            "color": "primary",
            "loading": _vm.sendCodeStatus.pending,
            "disabled": !_vm.isValid(_vm.email)
          },
          on: {
            "click": function ($event) {
              _vm.showCaptcha = true;
            }
          }
        }, [_vm._v(" Send ")])];
      },
      proxy: true
    }], null, false, 893907781),
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1) : _c('v-slide-x-reverse-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('code-input', {
    ref: "code-input",
    staticClass: "mb-1 mt-2",
    attrs: {
      "busy": _vm.verifyCodeStatus.pending,
      "invalid": _vm.codeHasError,
      "exp": _vm.exp,
      "type": "email"
    },
    on: {
      "reset": function ($event) {
        return _vm.sendCodeStatus.reset();
      },
      "reset-code": function ($event) {
        _vm.codeHasError = false;
      },
      "verify": _vm.verifyCode
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <!-- It can be a little annoying to navigate using the hover effect from the side menu, you may want to make this change: :open-on-hover="!click && !persistent && header" -->
  <component
    :is="$vuetify.breakpoint.xsOnly ? VDialog : VMenu"
    v-no-scroll
    eager content-class="grey lighten-5 prominent" width="600"
    v-bind="$vuetify.breakpoint.xsOnly ? {} : {
      'max-width': 'calc(100vw - 32px)',
      'open-on-hover': !click && !persistent,
      'close-on-click': !persistent,
      'close-on-content-click': !persistent,
      bottom: header,
      'offset-y': header,
      left: !header,
      'offset-x': !header,
      'nudge-right': header ? 0 : 1
    }"
    v-model="value"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="header"
        v-blur
        v-bind="attrs" v-on="on"
        class="header-menu ma-3 px-0"
        text color="ec-dark-blue"
        :disabled="disabled"
        @click="click = !click"
      >
        {{name}}
        <v-icon class="mr-n1" v-html="mdiMenuDown" />
      </v-btn>
      <v-list-item
        v-else
        v-blur
        v-bind="attrs" v-on="on"
        :class="(disabled ? 'grey' : 'ec-dark-blue') + '--text'"
        :disabled="disabled"
        @click="click = !click"
      >
        <v-icon class="ml-n2 hidden-xs-only" color="ec-dark-blue" v-html="mdiMenuLeft" />
        <v-list-item-title class="text-body-2">
          {{name}}<span class="hidden-sm-and-up">…</span>
        </v-list-item-title>
      </v-list-item>
    </template>

    <div
      class="overflow-auto"
      v-bind:style="{
        width: $vuetify.breakpoint.xsOnly ? '100%' : '600px',
        'max-width': $vuetify.breakpoint.xsOnly ? '100%' : 'calc(100vw - 32px)',
        'max-height': $vuetify.breakpoint.xsOnly ? '100%' : header ? 'calc(100vh - 120px)' : 'calc(100vh - 32px)'
      }"
    >
      <v-card class="ec-dark-blue--text nav-menu-header pa-3" flat tile :to="to">
        <v-btn class="float-right ml-2 mb-2" icon @click.capture.stop.prevent="value = false">
          <v-icon v-html="mdiClose" />
        </v-btn>
        <div class="text-h6 text-sm-h5" style="line-height: 1.5;" v-html="title" />
        <div class="mt-1 mb-4" v-html="subtitle" />
        <v-btn class="px-2" dark color="primary">
          <v-icon v-html="icon" />
          <span class="mx-2" style="text-transform: none;" v-html="action" />
          <v-icon v-html="mdiChevronRight" />
        </v-btn>
      </v-card>
      <v-divider />
      <div class="nav-menu-content">
        <slot />
      </div>
    </div>
  </component>
</template>

<style scoped lang="scss">
.header-menu {
  height: unset !important;
  text-transform: none;
  border-bottom: 2px solid $ec-blue;
  border-radius: 0;
  transition: border-bottom-color .1s;
}

.header-menu.theme--light.v-btn::before {
  display: none;
}

.header-menu.v-btn--disabled {
  border-bottom-color: rgba(0, 0, 0, 0.26);
}

.nav-menu-header {
  transition: background-color .3s;
}

.nav-menu-header:hover {
  background-color: #EEEEEE;
}
</style>

<style>
.nav-menu-content .v-btn {
  font-size: unset;
  letter-spacing: unset;
  text-transform: none;
  padding: 0 8px !important;
  height: unset !important;
  min-height: 36px;
  min-width: unset !important;
}

.nav-menu-content .v-btn::before {
  opacity: 0;
}

.nav-menu-content .v-btn > .v-btn__content {
  display: flex;
  align-items: center;
  text-align: left !important;
  padding: 4px 0;
  max-width: 100%;
  white-space: normal;
}
</style>

<script>
import { VDialog, VMenu } from 'vuetify/lib'
import { mdiChevronRight, mdiClose, mdiMenuDown, mdiMenuLeft } from '@mdi/js';

export default {
  name: 'nav-menu',

  props: {
    name: String,
    title: String,
    subtitle: String,
    header: Boolean,
    disabled: Boolean,
    action: String,
    icon: String,
    to: Object
  },

  data() {
    return {
      value: false,
      click: false,
      persistent: false
    };
  },

  created() {
    Object.assign(this, { VDialog, VMenu, mdiChevronRight, mdiClose, mdiMenuDown, mdiMenuLeft });
  },

  watch: {
    click(v) {
      this.$emit('spotlight', v);
    },

    value(v) {
      if (!v)
        this.click = false;
    }
  }
}
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    directives: [{
      name: "no-scroll",
      rawName: "v-no-scroll"
    }],
    attrs: {
      "width": "400",
      "value": _vm.value
    },
    on: {
      "input": _vm.reset
    }
  }, [_c('v-alert', {
    staticClass: "ma-0 break-word",
    attrs: {
      "icon": _vm.mdiAlert,
      "type": _vm.display.type
    }
  }, [_c('v-btn', {
    staticClass: "float-right mt-n2 mr-n2 ml-3 mb-3",
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.reset
    }
  }, [_c('v-icon', {
    domProps: {
      "innerHTML": _vm._s(_vm.mdiClose)
    }
  })], 1), _vm.isVisibleError ? _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.display.message)
    }
  }) : _vm._t("default", function () {
    return [_c('div', {
      domProps: {
        "innerHTML": _vm._s(_vm.display.message)
      }
    })];
  }), _vm.display.showContactUs ? _c('div', {
    staticClass: "mt-4 ml-n10 font-italic caption"
  }, [_vm._v("Seeing recurrent issues? Let us know: "), _c('a', {
    staticClass: "white--text",
    attrs: {
      "href": "mailto:support@ellacard.com"
    }
  }, [_vm._v("support@ellacard.com")])]) : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
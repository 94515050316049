<template>
  <v-dialog v-no-scroll width="500" v-bind="$attrs" :value="value" @input="$emit('cancel')">
    <v-card>
      <v-btn class="float-right mt-3 mr-3" icon @click="$emit('cancel')">
        <v-icon v-html="mdiClose" />
      </v-btn>
      <v-card-title>Create an Ellacard organization</v-card-title>
      <v-card-subtitle>No payment required</v-card-subtitle>
      <slot></slot>
      <v-divider />
      <v-card-text class="pt-4">
        <org-sign-up-form :hide-email="hideEmail" @cancel="$emit('cancel')" @complete="$emit('complete')" />
      </v-card-text>
      <!--
      <v-divider />
      <v-card-text class="py-4 ec-light-blue ec-dark-blue--text text-body-1">
        <p>When you click submit, we'll give you access to our suite of organization tools from your Ellacard account. From there, you'll be able to add credits and invite other users to join. You can add / remove other administrators later.</p>
        <p><span class="font-weight-bold">There's no obligation</span> – we'll reach out with more details.</p>
        Please contact us with any questions: <a href="mailto:contact@ellacard.com">contact@ellacard.com</a>
      </v-card-text>
      -->
    </v-card>
  </v-dialog>
</template>


<script>
import OrgSignUpForm from '@/components/OrgSignUpForm.vue';
import { mdiClose } from '@mdi/js';

export default {
  name: 'org-sign-up-dialog',

  components: {
    OrgSignUpForm
  },

  props: [ 'value', 'hideEmail' ],

  created() {
    Object.assign(this, { mdiClose });
  }
}
</script>

<template>
  <div class="white--text text-h6">
    <span class="mb-6 quoted">
      <div class="before">&ldquo;</div>
      <span v-html="text" />
      <div class="after">&rdquo;</div>
    </span>
    <div class="d-flex align-center mt-4">
      <v-avatar v-if="img" class="d-block mr-2" size="40">
        <v-img :alt="author" :src="img" />
      </v-avatar>
      <div class="font-italic" v-html="author" />
      <v-spacer />
    </div>
    <a v-if="brand" target="_blank" :href="link">
      <img class="mt-6" height="48" :src="brand" />
    </a>
  </div>
</template>


<style scoped lang="scss">
.quoted {
  position: relative;
  z-index: 1;
  font-style: italic;
  padding-left: 28px;
  padding-right: 28px;
}
.before, .after {
  position: absolute;
  user-select: none;
  font-size: 6rem !important;
  font-weight: 300;
  line-height: 0px;
  color: $ec-light-blue;
  z-index: -1;
}
.before {
  top: 34px;
  left: -24px;
}
.after {
  right: 0px;
  bottom: -18px;
}
</style>


<script>
export default {
  name: 'quote-card',
  props: [ 'img', 'text', 'author', 'brand', 'link' ]
}
</script>

<template>
  <v-dialog
    v-no-scroll eager content-class="fill-16x9"
    :value="$route.hash == hash"
    @input="$router.push($routeAssign({ hash: undefined }))"
  >
    <v-card dark style="position: relative;">
      
      <v-btn
        style="z-index: 1; position: absolute; top: 12px; right: 12px;"
        fab small dark color="rgba(0,0,0,.5)"
        @click="$router.push($routeAssign({ hash: undefined }))"
      >
        <v-icon v-html="mdiClose" />
      </v-btn>

      <v-responsive :aspect-ratio="16/9">
        <video-player ref="p" class="absolute-fill" allow-fullscreen :src="src" :srcset="srcset" :poster="poster" />
      </v-responsive>
    </v-card>
  </v-dialog>
</template>


<script>
// This compontnt allows you to show a video into a dialog with a dedicated hash controlling its visibility
//
// Usage:
// <video-dialog
//   hash="#intro-group-videos"
//   title="Group videos"
//   :src="`https://cdn.ellacard.com/start/group_video_intro_hd${IS_MOBILE ? '480' : '720'}.mp4`"
//   poster="https://cdn.ellacard.com/start/group_video_intro_poster.jpg"
// />

import VideoPlayer from '@/components/VideoPlayer.vue';
import { mdiClose } from '@mdi/js';

export default {
  name: 'video-dialog',

  components: {
    VideoPlayer
  },

  props: [ 'hash', 'title', 'src', 'srcset', 'poster' ],

  created() {
    Object.assign(this, { mdiClose });
  },

  watch: {
    '$route.hash'(v) {
      this.$refs.p.t = 0;
      this.$refs.p.paused = (v != this.hash);
    }
  }
}
</script>

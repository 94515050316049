<template>
  <div>
    <video-dialog
      hash="#business-admin-tools"
      poster="https://cdn.ellacard.com/start/business/org_users.png"
      src="https://cdn.ellacard.com/start/org_admin_tools_720.mp4"
    />

    <v-row class="my-6 flex-row-reverse">
      <v-col cols="12" sm="6">
        <!-- TODO: update / restore the demo video -->
        <!--
        <v-card dark hover @click.capture.stop="$router.push({ name: $route.name, hash: '#business-admin-tools' })">
          <v-img src="https://cdn.ellacard.com/start/business/org_users.png" :aspect-ratio="16/9" />
          <div class="absolute-fill d-flex justify-center align-center">
            <v-btn fab x-large color="primary">
              <v-icon x-large v-html="mdiPlay" />
            </v-btn>
          </div>
        </v-card>
        -->
        <v-card>
          <v-responsive :aspect-ratio="16 / 9">
            <video width="100%" playsinline="" autoplay="autoplay" muted="muted" loop="loop" src="https://cdn.ellacard.com/start/business/branding_640x360.mp4" class="d-block" />
          </v-responsive>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <h3 class="mb-6 text-h5 text-md-h4">Ellacard is the premier platform for sharing appreciation and building community</h3>
        <div class="mb-6 text-md-h6">
          Send greeting cards and videos between employees, or use Ellacard for outreach to better engage customers / donors. Perfect for kudos, thanks, birthdays, and more.
        </div>
        <div class="ma-n2 d-flex flex-wrap">
          <v-btn class="ma-2" dark color="pink" :to="{ name: 'business' }">
            <v-icon class="mr-2" v-html="mdiDomain" />Business portal
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <div class="my-6 text-md-h6 text-sm-center">
      <div class="mx-sm-12 px-sm-12">Please contact us to get started – we're happy to answer any questions or give you a demo</div>
      <v-btn class="mt-4" href="mailto:contact@ellacard.com">Contact us</v-btn>
    </div>
  </div>
</template>

<script>
import VideoDialog from '@/components/VideoDialog.vue';
import { mdiDomain, mdiPlay } from '@mdi/js';

export default {
  name: 'start-business-tools',
  components: { VideoDialog },
  data() {
    return {
      mdiDomain, mdiPlay
    };
  }
}
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.$vuetify.breakpoint.smAndUp ? _c('v-card', {
    staticClass: "float-right ml-3",
    staticStyle: {
      "width": "45%"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center pa-1"
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1",
      "small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiArrowLeft)
    }
  }), _c('v-icon', {
    attrs: {
      "color": "grey lighten-1",
      "small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiArrowRight)
    }
  }), _c('v-card', {
    staticClass: "mx-1 flex-grow-1 caption grey--text text--lighten-1 px-1",
    attrs: {
      "height": "24",
      "outlined": ""
    }
  }, [_vm._v("ellacard.com")]), _c('v-icon', {
    attrs: {
      "color": "grey lighten-1",
      "small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiRefresh)
    }
  })], 1), _c('v-divider'), _c('v-responsive', {
    attrs: {
      "aspect-ratio": 4 / 3
    }
  }, [_c('video', {
    staticStyle: {
      "position": "absolute"
    },
    attrs: {
      "width": "100%",
      "playsinline": "",
      "autoplay": "",
      "muted": "",
      "loop": "",
      "src": _vm.STEPS[_vm.selected].src
    },
    domProps: {
      "muted": true
    }
  })])], 1) : _vm._e(), _c('div', {
    staticClass: "my-n6 my-sm-n12"
  }, _vm._l(_vm.STEPS, function (step, i) {
    return _c('div', {
      staticClass: "my-6 my-sm-12 d-flex align-center",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          _vm.selected = _vm.$vuetify.breakpoint.xsOnly ? -1 : i;
        }
      }
    }, [_c('v-btn', {
      staticClass: "flex-grow-0 mr-2",
      attrs: {
        "icon": "",
        "x-large": "",
        "dark": _vm.dark,
        "color": _vm.selected == i ? _vm.dark ? 'grey darken-3' : 'primary' : null,
        "aria-label": step.title
      }
    }, [_c('v-icon', {
      attrs: {
        "x-large": ""
      },
      domProps: {
        "innerHTML": _vm._s(step.icon)
      }
    })], 1), _c('div', [_c('h5', {
      staticClass: "text-h6 text-sm-h5"
    }, [_c('a', {
      staticStyle: {
        "color": "unset",
        "text-decoration": "unset"
      },
      domProps: {
        "innerHTML": _vm._s(step.title)
      }
    })]), _c('p', {
      staticClass: "mb-0",
      domProps: {
        "innerHTML": _vm._s(step.subtitle)
      }
    })])], 1);
  }), 0), _c('div', {
    staticStyle: {
      "clear": "both"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
// A super lightweight directive for watching resize on an element
// Usage: v-el-resize="onResize"
// The 'onResize' method will be called with an object containing 'width' and 'height'

const RESIZE_TRIGGER_STYLE = `
display: block !important;
position: absolute !important;
top: 0 !important;
left: 0 !important;
width: 100% !important;
height: 100% !important;
overflow: hidden !important;
pointer-events: none !important;
z-index: -1 !important;
opacity: 0 !important;
`;

function createResizeTrigger() {
  const el = document.createElement('object');
  el.setAttribute('aria-hidden', 'true');
  el.setAttribute('tabindex', -1);
  el.setAttribute('style', RESIZE_TRIGGER_STYLE);
  el.type = 'text/html';
  return el;
}

export default {
  inserted(el, binding) {
    const onResize = () => binding.value({ width: el.offsetWidth, height: el.offsetHeight });
    if (window.ResizeObserver) {
      el._resizeObserver = new ResizeObserver(onResize);
      el._resizeObserver.observe(el);
    } else {
      el._resizeTrigger = createResizeTrigger();
      el._onResize = () => {
        if (el._pending)
          cancelAnimationFrame(el._pending);
        el._pending = requestAnimationFrame(onResize);
      };
      el._resizeTrigger.onload = function() {
        this.contentDocument.defaultView.addEventListener('resize', el._onResize);
        el._onResize();
      };
      el._resizeTrigger.data = 'about:blank';
      el.appendChild(el._resizeTrigger);
    }
  },

  componentUpdated(el, binding, vnode) {
    if (el._resizeObserver)
      return;
    if (!Array.from(el.children).find(x => x == el._resizeTrigger)) {
      el._resizeTrigger.data = 'about:blank';
      el.appendChild(el._resizeTrigger);
    }
  },

  unbind(el, binding) {
    if (el._resizeObserver)
      el._resizeObserver.unobserve(el);
    else
      el.removeChild(el._resizeTrigger);
  }
};

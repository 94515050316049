/*
Use this directive on ALL instances of 'v-dialog'

There is an issue on mobile browsers where the background can scroll underneath a dialog. This is a well-known problem.
Simply applying 'position: fixed' and 'overflow: hidden' as suggested by this euphoric tweet
( https://mobile.twitter.com/vincentriemer/status/1136281635979030528 ) is inadequate to fully solve the problem in the
case when the user has already scrolled down the page part way, and the mobile browser's toolbar and navigation bar
have disappeared (both Safari and Chrome on iOS). This is also a problem in other frameworks, like with bootstrap
modals: https://getbootstrap.com/docs/4.2/components/modal/

This fix "freezes" the body of the document and forces the browser's action bars to reappear (by restricting the body
height). A better solution would leave the browser's action bars in whatever state they are currently in. Also note
that Safari (but not Chrome) on iOS has a somewhat related issue: when the mobile keyboard comes up, it changes the
window size again, which enables scrolling over the dialog. This is desired, but Safari shows some white space at the
bottom of that scrollable area.

https://github.com/vuetifyjs/vuetify/issues/3875
https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/

TODO:
https://stackoverflow.com/questions/6011223/how-to-completely-hide-the-navigation-bar-in-iphone-html5
*/

function update(el, value) {
  if (!window.IS_MOBILE)
    return;

  const html = document.documentElement;
  const b = document.body;
  if (value) {
    // We still have out iOS Safari problem when partially scrolled with nav bars hidden. One idea is to compare the
    // height of the window to the height of the dialog content container (which is not the same element as the passed
    // in 'el'), but this doesn't seem to pick up the difference in heights.
    // const els = document.getElementsByClassName('v-dialog__content v-dialog__content--active');
    // console.log('Window / content height comparison: ', window.innerHeight, els[0].clientHeight);

    const y = scrollY;
    if (!Object.keys(b._scrollLock.active).length) {
      const scrollbar = window.innerWidth - html.clientWidth;
      if (scrollbar)
        b.style.paddingRight = `${scrollbar}px`;

      html.classList.add('scroll-lock');
      b.style.top = `-${y}px`;
    }
    if (!(el._scrollLockId in b._scrollLock.active))
      b._scrollLock.active[el._scrollLockId] = y;
  } else {
    if (el._scrollLockId in b._scrollLock.active) {
      const y = b._scrollLock.active[el._scrollLockId];
      delete b._scrollLock.active[el._scrollLockId];
      if (!Object.keys(b._scrollLock.active).length) {
        html.classList.remove('scroll-lock');
        b.style.top = '';
        scrollTo(0, y);
      }
    }
  }
}

export default {
  inserted(el, binding, vnode) {
    const b = document.body;
    if (!b._scrollLock)
      b._scrollLock = { n: 0, active: {} };
    el._scrollLockId = ++b._scrollLock.n;
    if (vnode.componentInstance)
      update(el, vnode.componentInstance.value);
  },

  componentUpdated(el, binding, vnode) {
    if (vnode.componentInstance)
      update(el, vnode.componentInstance.value);
  },

  unbind(el, binding, vnode) {
    update(el, false);
    document.body._scrollLock.n--;
  }
};

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "max-width": "600px"
    }
  }, [_c('pricing-base', {
    staticClass: "mb-12"
  }), _c('a', {
    attrs: {
      "id": "subscriptions"
    }
  }), _c('h2', {
    staticClass: "text-h5 text-center mb-3"
  }, [_vm._v("Annual subscription plans")]), _c('div', {
    staticClass: "text-center mb-6"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "to": {
        name: 'pricing',
        hash: '#subscriptions'
      }
    }
  }, [_vm._v("Select plan…")])], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', [_vm._v("Individual")]), _c('v-spacer'), _c('div', {
    staticClass: "primary--text"
  }, [_c('price', {
    attrs: {
      "value": "40",
      "per": "year"
    }
  })], 1)], 1), _c('div', {
    staticClass: "caption",
    staticStyle: {
      "max-width": "400px",
      "line-height": "1rem"
    }
  }, [_vm._v("For those looking to create and send multiple greeting cards / invitations for personal use")]), _c('div', {
    staticClass: "mt-4 d-flex"
  }, [_c('div', [_vm._v("Business basic")]), _c('v-spacer'), _c('div', {
    staticClass: "primary--text"
  }, [_c('price', {
    attrs: {
      "value": "60",
      "per": "year"
    }
  })], 1)], 1), _c('div', {
    staticClass: "caption",
    staticStyle: {
      "max-width": "400px",
      "line-height": "1rem"
    }
  }, [_vm._v("For a few administrators to create and send greeting cards / invitations on behalf of their organization")]), _c('div', {
    staticClass: "mt-4 d-flex"
  }, [_c('div', [_vm._v("Business plus")]), _c('v-spacer'), _c('div', {
    staticClass: "primary--text"
  }, [_c('price', {
    attrs: {
      "value": "150+",
      "per": "year"
    }
  })], 1)], 1), _c('div', {
    staticClass: "caption mb-12",
    staticStyle: {
      "max-width": "400px",
      "line-height": "1rem"
    }
  }, [_vm._v("For businesses that want to share credit among employees so they can all create and send greeting cards / invitations")]), _c('a', {
    attrs: {
      "id": "pre-paid-credit"
    }
  }), _c('h2', {
    staticClass: "text-h5 text-center mb-3"
  }, [_vm._v("Pre-paid credit")]), _c('div', {
    staticClass: "text-center mb-6"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "to": {
        name: 'pricing',
        hash: '#pre-paid-credit'
      }
    }
  }, [_vm._v("Add credit…")])], 1), _vm._l(_vm.CREDIT_PACKS, function (_ref) {
    var amt = _ref[0],
      price = _ref[1],
      discount = _ref[2],
      details = _ref[3];
    return _c('div', {
      staticClass: "mt-3 d-flex"
    }, [_c('div', [_c('div', {
      staticClass: "mb-n1"
    }, [_vm._v("$" + _vm._s(amt) + " of credit")]), _c('div', {
      staticClass: "caption"
    }, [_vm._v(_vm._s(details))])]), _c('v-spacer'), discount ? _c('v-chip', {
      staticClass: "flex-shrink-0 mr-2 px-2",
      attrs: {
        "label": "",
        "outlined": "",
        "small": "",
        "color": "red"
      }
    }, [_vm._v(_vm._s(discount) + "% off")]) : _vm._e(), _c('price', {
      attrs: {
        "value": price
      }
    })], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.$vuetify.breakpoint.xsOnly ? _vm.VDialog : _vm.VMenu, _vm._b({
    directives: [{
      name: "no-scroll",
      rawName: "v-no-scroll"
    }],
    tag: "component",
    attrs: {
      "eager": "",
      "content-class": "grey lighten-5 prominent",
      "width": "600"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_vm.header ? _c('v-btn', _vm._g(_vm._b({
          directives: [{
            name: "blur",
            rawName: "v-blur"
          }],
          staticClass: "header-menu ma-3 px-0",
          attrs: {
            "text": "",
            "color": "ec-dark-blue",
            "disabled": _vm.disabled
          },
          on: {
            "click": function ($event) {
              _vm.click = !_vm.click;
            }
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" " + _vm._s(_vm.name) + " "), _c('v-icon', {
          staticClass: "mr-n1",
          domProps: {
            "innerHTML": _vm._s(_vm.mdiMenuDown)
          }
        })], 1) : _c('v-list-item', _vm._g(_vm._b({
          directives: [{
            name: "blur",
            rawName: "v-blur"
          }],
          class: (_vm.disabled ? 'grey' : 'ec-dark-blue') + '--text',
          attrs: {
            "disabled": _vm.disabled
          },
          on: {
            "click": function ($event) {
              _vm.click = !_vm.click;
            }
          }
        }, 'v-list-item', attrs, false), on), [_c('v-icon', {
          staticClass: "ml-n2 hidden-xs-only",
          attrs: {
            "color": "ec-dark-blue"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.mdiMenuLeft)
          }
        }), _c('v-list-item-title', {
          staticClass: "text-body-2"
        }, [_vm._v(" " + _vm._s(_vm.name)), _c('span', {
          staticClass: "hidden-sm-and-up"
        }, [_vm._v("…")])])], 1)];
      }
    }]),
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, 'component', _vm.$vuetify.breakpoint.xsOnly ? {} : {
    'max-width': 'calc(100vw - 32px)',
    'open-on-hover': !_vm.click && !_vm.persistent,
    'close-on-click': !_vm.persistent,
    'close-on-content-click': !_vm.persistent,
    bottom: _vm.header,
    'offset-y': _vm.header,
    left: !_vm.header,
    'offset-x': !_vm.header,
    'nudge-right': _vm.header ? 0 : 1
  }, false), [_c('div', {
    staticClass: "overflow-auto",
    style: {
      width: _vm.$vuetify.breakpoint.xsOnly ? '100%' : '600px',
      'max-width': _vm.$vuetify.breakpoint.xsOnly ? '100%' : 'calc(100vw - 32px)',
      'max-height': _vm.$vuetify.breakpoint.xsOnly ? '100%' : _vm.header ? 'calc(100vh - 120px)' : 'calc(100vh - 32px)'
    }
  }, [_c('v-card', {
    staticClass: "ec-dark-blue--text nav-menu-header pa-3",
    attrs: {
      "flat": "",
      "tile": "",
      "to": _vm.to
    }
  }, [_c('v-btn', {
    staticClass: "float-right ml-2 mb-2",
    attrs: {
      "icon": ""
    },
    on: {
      "!click": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        _vm.value = false;
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "innerHTML": _vm._s(_vm.mdiClose)
    }
  })], 1), _c('div', {
    staticClass: "text-h6 text-sm-h5",
    staticStyle: {
      "line-height": "1.5"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  }), _c('div', {
    staticClass: "mt-1 mb-4",
    domProps: {
      "innerHTML": _vm._s(_vm.subtitle)
    }
  }), _c('v-btn', {
    staticClass: "px-2",
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('v-icon', {
    domProps: {
      "innerHTML": _vm._s(_vm.icon)
    }
  }), _c('span', {
    staticClass: "mx-2",
    staticStyle: {
      "text-transform": "none"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.action)
    }
  }), _c('v-icon', {
    domProps: {
      "innerHTML": _vm._s(_vm.mdiChevronRight)
    }
  })], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "nav-menu-content"
  }, [_vm._t("default")], 2)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
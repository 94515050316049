// Helper functions for editing orders, particularly around merging changes from the server with local changes. See
// purchase-dialog and card-sign components.

import constraints from '@/card-geometry-constraints.js';
import { blankDesignId } from '@/mixins/public-design.js';
import { rotate, scale } from '@/utils/geometry.js';
import { LOCALE, TZ } from '@/utils/intl.js';
import { DEFAULT as DEFAULT_SKIN } from '@/utils/skin.js';
import uuid4 from '@/utils/uuid.js';


function isBlobVisible(b, mode, designId) {
  if (b.btn?.form)
    return false;
  if (b.for_design_id && (designId != b.for_design_id))
    return false;
  if ((mode != 'view') || !b.template)
    return true;
  if (b.type == 'TEXT')
    return hasContent(b.text.text) && (b.text.text != 'Message here');
  if (b.type == 'IMG')
    return !b.mask || (b.mask.placeholder != b.img.src);
  return true;
}


function isBlobEditable(b, mode, userId, isCardOwner) {
  if (!(isCardOwner || (b.user_id == userId) || (b.template && !b.user_id)))
    return false;
  if (b.hasOwnProperty('opacity') && !b.opacity)
    return false;
  if (!(b.user_id || b.template || (mode == 'design')))
    return false;
  return true;
}


// The actual text value of a content-editable text blob may contain HTML tags. This function checks to see if it
// contains any actual visible (non-whitespace) characters.
function hasContent(html) {
  let open = false;
  for (const c of html || '') {
    if (c == '<')
      open = true;
    else if (c == '>')
      open = false;
    else if (open)
      continue;
    else
      return true;
  }
  return false;
}


// Some blobs should be filtered out from saving, typically when they contain placeholder values
function shouldSaveBlob(blob) {
  if (blob.template)
    return true;
  switch (blob.type) {
    case 'TEXT':
      return hasContent(blob.text.text) && (blob.text.text != 'Message here');
    case 'VIDEO':
      return !!blob.video.src;
  }
  return true;
}


function mergeBlobs(target, source, defer) {
  // Preconditions: assumes these blobs already have matching IDs and are of the same type
  if (!source)
    return;
  if (defer) {
    // The target blob has been updated, presumably while we were in the midst of saving, so defer to our local
    // changes. The one thing we do need to pull over is the updated 'src' in the case of images and videos.
    switch (target.type) {
      case 'VIDEO':
        target.video.src = source.video.src;
        break;
      case 'IMG':
        // For image templates, we don't necessarily want to copy in the 'src' from the server. Consider the following
        // cases that show whose 'src' we should defer to:
        // - Target has blob URL, server has real URL => server
        // - Target has placeholder URL, server has real URL => target (we likely deleted it while saving)
        // - Target has blob URL, server has placeholder URL => target (we added an image while saving)
        // Note that the first case is ambiguous - it's technically possible that a user added a new image to a
        // template, then quickly replaced it in the middle of that first save, but thta's unlikely...
        if (!target.template || (target.img.src.startsWith('blob') && source.img.src != source.mask.placeholder))
          target.img.src = source.img.src;
        break;
    }
    return;
  }
  if (target.type == 'TEXT') {
    // When dealing with a text blob, we want to be careful to preserve the actual 'text.text' object so as not to
    // interfere with the contenteditable focused element
    source = _.cloneDeep(source);
    delete source.text.text;
    Object.assign(target.text, source.text);
    delete source.text;
  }
  Object.assign(target, source);
}


// For the algorithm to compute polygon intersection, it is more useful to represent our rotated blob rectangles as
// vertex arrays
function toPolygon(blob) {
  const dx = blob.w / 2;
  const dy = blob.h / 2;
  const corners = [
    { x: blob.x - dx, y: blob.y - dy },
    { x: blob.x + dx, y: blob.y - dy },
    { x: blob.x + dx, y: blob.y + dy },
    { x: blob.x - dx, y: blob.y + dy }
  ];
  const r = blob.r * Math.PI / 180
  const sinR = Math.sin(r);
  const cosR = Math.cos(r);
  return corners.map(
    corner => {
      const x = (corner.x - blob.x) * cosR - (corner.y - blob.y) * sinR + blob.x;
      const y = (corner.x - blob.x) * sinR + (corner.y - blob.y) * cosR + blob.y;
      return { x, y };
    }
  );
}


// Function taken from here: https://stackoverflow.com/a/12414951/8439453
function doPolygonsIntersect(a, b) {
  var polygons = [ a, b ];
  var minA, maxA, projected, i, i1, j, minB, maxB;

  for (i = 0; i < polygons.length; i++) {
    // for each polygon, look at each edge of the polygon, and determine if it separates
    // the two shapes
    var polygon = polygons[i];
    for (i1 = 0; i1 < polygon.length; i1++) {

      // grab 2 vertices to create an edge
      var i2 = (i1 + 1) % polygon.length;
      var p1 = polygon[i1];
      var p2 = polygon[i2];

      // find the line perpendicular to this edge
      var normal = { x: p2.y - p1.y, y: p1.x - p2.x };

      minA = maxA = undefined;
      // for each vertex in the first shape, project it onto the line perpendicular to the edge
      // and keep track of the min and max of these values
      for (j = 0; j < a.length; j++) {
        projected = normal.x * a[j].x + normal.y * a[j].y;
        if (minA === undefined || projected < minA) {
          minA = projected;
        }
        if (maxA === undefined || projected > maxA) {
          maxA = projected;
        }
      }

      // for each vertex in the second shape, project it onto the line perpendicular to the edge
      // and keep track of the min and max of these values
      minB = maxB = undefined;
      for (j = 0; j < b.length; j++) {
        projected = normal.x * b[j].x + normal.y * b[j].y;
        if (minB === undefined || projected < minB) {
          minB = projected;
        }
        if (maxB === undefined || projected > maxB) {
          maxB = projected;
        }
      }

      // if there is no overlap between the projects, the edge we are looking at separates the two
      // polygons, and we know there is no overlap
      if (maxA < minB || maxB < minA) {
        return false;
      }
    }
  }
  return true;
};


// These vertex arrays define the four quadrants of a card page
const { W, H } = constraints.page;
const QUADRANT_POLYGONS = [
  [ { x: 0, y: 0 }, { x: W / 2, y: 0 }, { x: W / 2, y: H / 2 }, { x: 0, y: H / 2 } ],
  [ { x: W / 2, y: 0 }, { x: W, y: 0 }, { x: W, y: H / 2 }, { x: W / 2, y: H / 2 } ],
  [ { x: 0, y: H / 2 }, { x: W / 2, y: H / 2 }, { x: W / 2, y: H }, { x: 0, y: H } ],
  [ { x: W / 2, y: H / 2 }, { x: W, y: H / 2 }, { x: W, y: H }, { x: W / 2, y: H } ]
];


// This function returns the index of the first page with one of your blobs or a group video, otherwise the index of
// the first page that has room to sign (or the index of the end of the page list if we need a new page).
function findOpenToPageIndex(order, userId) {
  let i;
  for (i = 0; i < order.pages.length; i++)
    for (const blob of order.pages[i].blobs)
      if ((userId && (blob.user_id == userId)) || (blob.type == 'GROUP_VIDEO'))
        return i;

  // Logic to find a page with an open quadrant
  // TODO: when you implement push events to auto-update the browser ala Google Docs, then you should uncomment this
  // loop. The problem is that this logic is leading to overlapping text.
  // for (i = 0; i < order.pages.length; i++) {
  //   const blobPolygons = order.pages[i].blobs.map(toPolygon);
  //   for (const quadrantPolygon of QUADRANT_POLYGONS)
  //     if (!blobPolygons.find(x => doPolygonsIntersect(quadrantPolygon, x)))
  //       return i;
  // }

  return i;
}


function isZoomed() {
  return window.visualViewport && window.visualViewport.scale != 1;
}


// This function determines whether or not a blob can be transformed - whether or not we should show the handles to
// move it around / rotate it
function canTransform(blob, mode) {
  if (blob.fixed_position)
    return false;
  if (mode == 'design')
    return true;
  if (mode != 'purchase' && mode != 'edit')
    return false;
  if (!blob.template)
    return true;
  // A template blob can only be transformed if it has a mask and it is not using the placeholder src
  return !!blob.mask && ((blob.img?.src || blob.video?.src) != blob.mask.placeholder);
}


// Computes the mounds of the rotated mask rectangle
function getUnrotatedBounds(mask) {
  const r = mask.r * Math.PI / 180;
  const c1 = rotate([ 0, 0 ], [ mask.w / 2, mask.h / 2 ], r);
  const c2 = rotate([ 0, 0 ], [ mask.w / 2, - mask.h / 2 ], r);
  const c3 = rotate([ 0, 0 ], [ - mask.w / 2, - mask.h / 2 ], r);
  const c4 = rotate([ 0, 0 ], [ - mask.w / 2, mask.h / 2 ], r);
  const corners = [ c1, c2, c3, c4 ];
  return [
    Math.min(...corners.map(c => c[0])),
    Math.max(...corners.map(c => c[0])),
    Math.min(...corners.map(c => c[1])),
    Math.max(...corners.map(c => c[1]))
  ];
}


// For replacing a template blob 'b' with an image / video
function setTemplateBlob(userId, b, src, w, h) {
  let scale;
  if (b.mask.r0) {
    const [ x_min, x_max, y_min, y_max ] = getUnrotatedBounds(b.mask);
    scale = Math.max((x_max - x_min) / w, (y_max - y_min) / h);
    b.x = b.mask.x + (x_min + x_max) / 2;
    b.y = b.mask.y + (y_min + y_max) / 2;
    b.r = 0;
  } else {
    scale = Math.max(b.mask.w / w, b.mask.h / h);
    b.x = b.mask.x;
    b.y = b.mask.y;
    b.r = b.mask.r;
  }
  b.w = scale * w;
  b.h = scale * h;
  const type = b.type.toLowerCase();
  b[type].src = src;
  b[type].frame = null;
  b.user_id = userId;
}


// Removes and returns an object from an array (if found) otherwise returns undefined
function pop(arr, pred) {
  const i = arr.findIndex(pred);
  return i == -1 ? undefined : arr.splice(i, 1)[0];
}


function buildDefaultOrder() {
  return {
    state: 'PENDING',
    type: 'GROUP',
    info: {
      from: null,
      recipients: [],
      contributors: [],
      send_at: (new Date()).toISOString(),
      send_at_contributors: (new Date()).toISOString(),
      send_reminder: true
    },
    design_id: blankDesignId,
    background: { img: null, color: '#FFF' },
    pages: [],
    skin: _.cloneDeep(DEFAULT_SKIN),
    msg: '',
    locale: LOCALE,
    tz: TZ
  };
}


function updateOrderDesign(order, designId, design) {
  const designPages = _.cloneDeep(design.pages);
  for (const page of designPages) {
    for (const blob of page.blobs) {
      blob.for_design_id = designId;
      if (!blob.user_id)
        blob.user_id = null;
    }
  }

  // TODO: remove this kludge once you've updated all pending orders
  // In the past, we only assigned the 'for_design_id' property to cover templates, now we use it to identify any
  // design blobs
  if ((order.pages.length >= 1) && (order.design_id != blankDesignId))
    for (const blob of order.pages[0].blobs)
      if (!blob.user_id && !blob.for_design_id)
        blob.for_design_id = order.design_id;

  // If our order has page templates that would overlap with the design, push those page templates on down
  const firstPageTemplateI = order.pages.findIndex(x => x.blobs.find(y => y.template == 'page'));
  if ((firstPageTemplateI != -1) && (firstPageTemplateI < designPages.length)) {
    const extracted = order.pages.map(x => x.blobs.filter(x => x.for_design_id));
    for (let i = firstPageTemplateI; i < designPages.length; i++)
      order.pages = [ { id: uuid4(), blobs: [] }, ...order.pages ];
    for (let i = 0; i < extracted.length; i++)
      order.pages[i].blobs = extracted[i].concat(order.pages[i].blobs.filter(x => !x.for_design_id));
  }

  order.design_id = designId;
  order.background = design.background;
  let i;
  for (i = 0; i < order.pages.length; i++) {
    const designBlobs = (i < designPages.length) ? designPages[i].blobs : [];
    const pendingBlobs = order.pages[i].blobs.filter(x => !x.for_design_id || (x.template == 'cover'));
    for (const designBlob of designBlobs) {
      // If this design has any cover template blobs and we've saved it previously, then we will already have a
      // reference to those specific blob IDs (possibly with data in them), so we replace the corresponding design
      // blobs with our pending blobs.
      const pendingBlob = pop(pendingBlobs, x => x.id == designBlob.id);
      if (pendingBlob)
        Object.assign(designBlob, pendingBlob);
    }

    // Note that this structure may include blobs for other designs, but that's where the 'for_design_id'
    // parameter comes in - none of those blobs will actually show up in the card-editor
    order.pages[i].blobs = designBlobs.concat(pendingBlobs);
  }

  // All the existing order pages have now been updated with the design, now just add any additional pages from the
  // design
  for (; i < designPages.length; i++)
    order.pages.push(designPages[i]);
}


const DEFAULT_FORM_INPUTS = [
  {
    title: 'Are you able to attend?',
    subtitle: 'Choose one',
    type: 'RADIO',
    choices: [
      'Yes, I\'ll be there',
      'No, I can\'t make it'
    ]
  },
  {
    title: 'How many people are in your party?',
    subtitle: '',
    type: 'INT'
  },
  {
    title: 'Is there anything else we should know?',
    subtitle: 'e.g. dietary restrictions',
    type: 'TEXT'
  }
];

const DEFAULT_EVENT = {
  name: null,
  from: null,
  to: null,
  location: null,
  is_virtual: false,
  address: null,
  url: null
};

function updateOrderType(order, type) {
  order.type = type;
  if (type == 'INVITATION') {
    // Make sure we have at least one page on which to put the form-btn blob
    if (!order.pages.length)
      order.pages.push({ id: uuid4(), blobs: [] });
    // Make sure a form-btn blob doesn't already exist
    if (!order.pages.find(x => x.blobs.find(y => (y.type == 'BTN') && y.btn.form)))
      order.pages[0].blobs.push({
        id: uuid4(),
        user_id: order.user_id || '-',
        x: 457.5,
        y: 756,
        w: 190,
        h: 112,
        r: 0,
        type: 'BTN',
        btn: { form: { inputs: _.cloneDeep(DEFAULT_FORM_INPUTS) }, color: '#1976d2', scale: 2, text: 'RSVP' }
      });
    if (order.skin.email_subject == 'You received a card')
      order.skin.email_subject = "You're invited";
  } else {
    // Remove any existing form-btn blobs
    for (const page of order.pages)
      page.blobs = page.blobs.filter(x => (x.type != 'BTN') || !x.btn.form);
    if (order.skin.email_subject == "You're invited")
      order.skin.email_subject = 'You received a card';
  }
  if (type != 'GROUP')
    order.skin.private_pages = false;
  order.event = order.event || (type == 'INVITATION' ? _.cloneDeep(DEFAULT_EVENT) : undefined);
  order.lock = { PERSONAL: 'FORCE', INVITATION: 'MANUAL' }[type] || null;
  // If you wanted to have a default message (this default is pretty redundant in the emails / texts):
  // this.order.msg = this.order.msg || (type == 'GROUP' ? `We're making a group card on Ellacard. Please sign it!` : '');

  if (!order.info.did_set_send_at) {
    if (type == 'GROUP') {
      const d = new Date();
      d.setDate(d.getDate() + 7);
      d.setHours(9);
      d.setMinutes(30);
      order.info.send_at = d.toISOString();
    } else {
      order.info.send_at = (new Date()).toISOString();
    }
  }
}


export { isBlobVisible, isBlobEditable, hasContent, shouldSaveBlob, mergeBlobs, findOpenToPageIndex, isZoomed, canTransform, setTemplateBlob, updateOrderDesign, buildDefaultOrder, updateOrderType };

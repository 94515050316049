<template>
  <div>
    <div style="max-width: 600px;">
      <div class="d-flex">
        <div>Personal card</div>
        <v-spacer />
        <div class="primary--text">
          <price product="CARD_PERSONAL" /> + 10¢<span class="caption grey--text"> / recipient*</span>
        </div>
      </div>
      <div class="mt-n1 caption">Signed by you only</div>

      <div class="mt-3 d-flex">
        <div>Group card</div>
        <v-spacer />
        <div class="primary--text">
          <price product="CARD_GROUP" /> + 10¢<span class="caption grey--text"> / contributor*</span> + 10¢<span class="caption grey--text"> / recipient*</span>
        </div>
      </div>
      <div class="mt-n1 caption">Invite contributors to sign</div>

      <div class="mt-3 d-flex">
        <div>Event invitation</div>
        <v-spacer />
        <div class="primary--text">
          <price product="CARD_INVITATION" /> + 10¢<span class="caption grey--text"> / recipient*</span>
        </div>
      </div>
      <div class="mt-n1 caption">Includes RSVPs</div>
    </div>

    <div class="mt-6 caption green--text">* Send your card to <i>recipients</i> or invite <i>contributors</i> to sign a group card for <v-chip class="px-1" color="green" dark label x-small>free</v-chip> via a shared link / social media post (as opposed to an email / text message sent by Ellacard).</div>

    <div class="mt-9" style="max-width: 600px;">
      <div class="mt-3 d-flex">
        <div>
          <div class="mb-n1">Group videos</div>
          <div class="caption">Custom video templates available</div>
        </div>
        <v-spacer />
        <price value="0" /><span class="primary--text mx-1">–</span><price value="20" />
      </div>

      <div class="mt-3 d-flex">
        <div>
          <div class="mb-n1">Gift cards</div>
          <div class="caption">Includes international gift cards</div>
        </div>
        <v-spacer />
        <price value="0.50" /><span class="primary--text ml-1"> + 5%</span>
      </div>
    </div>
  </div>
</template>

<script>
import Price from '@/components/Price.vue';

export default {
  name: 'pricing-base',
  components: { Price }
}
</script>

import AsyncStatus from '@/utils/async-status.js';

export default {
  methods: {
    // $await(promise) {
    //   // For handling async computed properties
    //   if (!promise.hasOwnProperty('_v')) {
    //     promise._v = undefined;
    //     promise.then(v => promise._v = v).finally(this.$forceUpdate);
    //   }
    //   return promise._v;
    // },

    $track(key) {
      // DEPRECATED - this is inadequate as the function could be called more than once simultaneously
      const kernel = this[key];
      const status = new AsyncStatus();
      if (!kernel) {
        console.warn('Tracked function not found', this.$options.name, key);
        return status;
      }
      if (kernel._status)
        // This function is already tracked
        return kernel._status;
      this[key] = function() {
        // It's important to have a function closure here to capture the arguments
        return status.track(() => kernel(...arguments));
      };
      this[key]._status = status;
      return status;
    }
  }
};

<template>
  <div class="overflow-hidden" v-bind:style="{ 'clip-path': path, ...pad }">
    <div v-el-resize="({ width, height }) => { w = width; h = height; }">
      <slot class="fill-height" />
    </div>
  </div>
</template>

<script>
function buildWave(x, y, w, h) {
  return `M${x},${y+.5*h}Q${x+.25*w},${y-.5*h} ${x+.5*w},${y+.5*h}Q${x+.75*w},${y+1.5*h} ${x+w},${y+.5*h}L${x+w},${y+h}L${x},${y+h}L${x},${y+.5*h}Z`;
}

export default {
  name: 'clip-waves',

  props: { offsetTop: Number, offsetBottom: Number, waveHeight: Number, padWaveHeight: Boolean },

  data() {
    return {
      w: 0,
      h: 0
    }
  },

  computed: {
    waveH() {
      return this.waveHeight || (this.w * .03);
    },

    waveTH() {
      return (this.offsetTop == undefined) ? 0 : this.waveH;
    },

    waveBH() {
      return (this.offsetBottom == undefined) ? 0 : this.waveH;
    },

    path() {
      let { w, h, waveTH, waveBH } = this;
      if (!w || !h)
        return '';
      if (this.padWaveHeight)
        h += waveTH + waveBH;
      const t = this.offsetTop || 0;
      const b = this.offsetBottom || 0;
      const waveT = waveTH ? buildWave(t*w-w, 0, w, waveTH) + ' ' + buildWave(t*w, 0, w, waveTH) : '';
      const box = `M${t*w-w},${waveTH}L${t*w+w},${waveTH}L${b*w+w},${h-waveBH}L${b*w-w},${h-waveBH}Z`;
      const waveB = waveBH ? buildWave(b*w-w, h, w, -waveBH) + ' ' + buildWave(b*w, h, w, -waveBH) : '';
      return `path('${waveT + ' ' + box + ' ' + waveB}')`;
    },

    pad() {
      if (!this.padWaveHeight)
        return {};
      const style = {};
      if (this.padWaveHeight) {
        if (this.waveTH)
          style['padding-top'] = `${this.waveTH}px`;
        if (this.waveBH)
          style['padding-bottom'] = `${this.waveBH}px`;
      }
      return style;
    }
  }
};
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: _vm.blob.group_video.project_id,
    staticClass: "absolute-fill"
  }, [_vm.blob.group_video.img_src ? [_vm.placeholder ? _c('div', {
    staticClass: "absolute-fill placeholder"
  }) : _vm._e(), _c('img', {
    staticClass: "user-select-none",
    attrs: {
      "src": _vm.blob.group_video.img_src,
      "draggable": "false"
    },
    on: {
      "load": _vm.onLoad
    }
  })] : _c('v-card', {
    staticClass: "absolute-fill",
    attrs: {
      "dark": "",
      "tile": ""
    }
  }, [_vm.mode == 'preview' ? _c('v-img', {
    attrs: {
      "aspect-ratio": 16 / 9,
      "src": _vm.blob.group_video.poster
    }
  }) : _vm._e()], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
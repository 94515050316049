<template>
  <!-- Make sure you don't create a CSS stacking context at the top level -->
  <div v-bind:class="{ 'pointer-events-none': !isEditable }" @click.stop>
    <blob-mask
      style="z-index: 1;"
      :mask="blob.mask"
      :blob-transform="blobTransform"
      :blob-mask-transform="blobMaskTransform"
      :blob-mask-relative-transform="blobMaskRelativeTransform"
    >

      <div
        v-if="showPrivacyScreen"
        class="absolute-fill pointer-events-none d-flex align-center justify-center"
        style="z-index: 3;"
      >
        <v-btn style="pointer-events: auto;" x-large icon color="rgba(0, 0, 0, 0.27)" @click="showPrivate">
          <v-icon x-large v-html="mdiLock" />
        </v-btn>
      </div>

      <blob-frame
        v-bind:class="{ 'pointer-events-none': !isEditable, 'private-blob': showPrivacyScreen }"
        v-bind:style="blobFrameStyles"
        :frame="opts.frame"
        :options="opts.frame_options"
        :type="blob.type"
        :has-crop="!!blob.crop"
        @touchmove="e => !isEditable && e.preventDefault()"
        @mousedown.native="move"
        @touchstart.native="move"
      >
        <text-blob
          v-if="blob.type == 'TEXT'"
          ref="text"
          :blob="showPrivacyScreen ? { ...blob, text: { ...blob.text, font: 'Redacted Script' } } : blob"
          :mode="mode"
          :is-editable="isEditable"
          :is-selected="isSelected"
          :is-transform-in-progress="!!transformInProgress"
          :virtual-keyboard.sync="isShowingVirtualKeyboard"
          @delete="$emit('delete', true)"
          @loaded="$emit('loaded')"
          @deselect="$emit('deselect')"
          @click-text-event-link="$emit('click-text-event-link')"
        />
        <image-blob
          v-else-if="blob.type == 'IMG'"
          :blob="blob"
          :is-editable="isEditable"
          :is-preview="mode == 'preview'"
          :is-selected="isSelected"
          :static="static"
          @loaded="$emit('loaded')"
        />
        <video-blob
          v-else-if="blob.type == 'VIDEO'"
          ref="control-target"
          :blob="blob"
          :is-preview="mode == 'preview'"
          :is-editable="isEditable"
          :is-selected="isSelected"
          :is-page-active="isPageActive"
          @play-video="$emit('play-video')"
          @loaded="$emit('loaded')"
        />
        <giftcard-blob
          v-else-if="blob.type == 'GIFTCARD'"
          :blob="blob"
          @loaded="$emit('loaded')"
        />
        <group-video-blob
          v-else-if="blob.type == 'GROUP_VIDEO'"
          :mode="mode"
          :blob="blob"
          @loaded="$emit('loaded')"
        />
        <btn-blob
          v-else-if="blob.type == 'BTN'"
          :is-owner="isPrimary || isEditable"
          :blob="blob"
          :mode="mode"
          @loaded="$emit('loaded')"
          @click="$emit('click-btn')"
        />
        <gradient-blob v-else-if="blob.type == 'GRADIENT'" :blob="blob" />
      </blob-frame>
    </blob-mask>

    <!-- This section is on top of all the blob content across the whole page -->
    <div
      v-if="hasControls && !showPrivacyScreen && (mode != 'design')"
      style="z-index: 2;"
      class="absolute user-select-none"
      v-bind:class="{ 'pointer-events-none': !isDraggingOnPage }"
      v-bind:style="blobMaskTransform || blobTransform"
      @click.stop
    >
      <div
        v-if="isEditable && blob.text?.event_link"
        class="absolute mx-n4 my-n2"
        style="pointer-events: auto; cursor: pointer; width: calc(100% + 32px); height: calc(100% + 16px);"
        @click="$emit('click-text-event-link')"
      />
      <mask-blob-controls
        v-else-if="isEditable && blob.template && ((blob.type == 'IMG') || (blob.type == 'VIDEO')) && (blob.mask.placeholder == opts.src)"
        :user-id="userId"
        :blob="blob"
        :selected="selected"
        :mode="mode"
        :is-page-active="isPageActive && !isPageReadonly"
        :is-dragging-on-page="isDraggingOnPage"
        :scale="scale"
        @prompt-upload="select(); $emit('prompt-upload');"
        @prompt-record-video="select(); $emit('prompt-record-video');"
      />
      <video-blob-controls
        v-else-if="blob.type == 'VIDEO'"
        :target="controlTarget"
        :is-editable="isEditable"
        :is-selected="isSelected"
        @select="$emit('select')"
      />
      <giftcard-blob-controls
        v-else-if="blob.type == 'GIFTCARD'"
        :blob="blob"
        :skin="skin"
        :is-owner="isPrimary"
        :is-card-owner="isCardOwner"
        :is-verified-recipient="isVerifiedRecipient"
        :scale="scale"
        :mode="mode"
        @click="$emit('click-giftcard')"
      />
      <group-video-blob-controls
        v-else-if="blob.type == 'GROUP_VIDEO'"
        :blob="blob"
        :selected="selected"
        :is-owner="isPrimary"
        :scale="scale"
        :mode="mode"
        @click="$emit('click-group-video')"
      />
    </div>

    <!-- This section only shows up when the blob is selected and is on top of everything -->
    <div
      v-if="isSelected && (!isDraggingOnPage || (transformInProgress == 'move'))"
      style="z-index: 3;"
      class="absolute pointer-events-none user-select-none"
      v-bind:style="blobTransform"
    >
      <blob-selection-controls
        class="absolute-fill"
        v-bind:style="{ margin: `-${selectionControlsMargin}px` }"
        :blob="blob"
        :can-transform="canTransform"
        :is-primary="isPrimary"
        :is-cropping="isSelected && isCropping"
        :snap="snap"
        :mode="mode"
        :is-showing-virtual-keyboard="isShowingVirtualKeyboard"
        @resize="resize"
        @rotate="rotate"
        @delete="$emit('delete')"
      />
    </div>

  </div>
</template>

<style scoped>
.pointer-events-none {
  pointer-events: none;
  touch-action: none;
}
</style>

<script>
import BlobFrame from '@/components/BlobFrame.vue';
import BlobMask from '@/components/BlobMask.vue';
import BlobSelectionControls from '@/components/BlobSelectionControls.vue';
import BtnBlob from '@/components/BtnBlob.vue';
import GiftcardBlob from '@/components/GiftcardBlob.vue';
import GiftcardBlobControls from '@/components/GiftcardBlobControls.vue';
import GradientBlob from '@/components/GradientBlob.vue';
import GroupVideoBlob from '@/components/GroupVideoBlob.vue';
import GroupVideoBlobControls from '@/components/GroupVideoBlobControls.vue';
import ImageBlob from '@/components/ImageBlob.vue';
import MaskBlobControls from '@/components/MaskBlobControls.vue';
import TextBlob from '@/components/TextBlob.vue';
import VideoBlob from '@/components/VideoBlob.vue';
import VideoBlobControls from '@/components/VideoBlobControls.vue';
import constraints from '@/card-geometry-constraints.js';
import { isBlobEditable, hasContent, isZoomed, canTransform } from '@/utils/edit-order.js';
import { rotate, translate, scale } from '@/utils/geometry.js';
import { mdiLock } from '@mdi/js';

const constrain = constraints.constrain;

// This property governs how long to wait before responding to a 'move' event on a previously unselected blob. This
// helps ensure that we don't accidentally move our blobs when we're trying to swipe over the page. Used in the 'move'
// method.
const DETECT_SWIPE_DELAY_MS = 500;

const SNAP_WITHIN = 20;

// Lookup functions organized by blob type to determine if the controls should be showing (returns true if the type is
// not captured here). They recieve the component itself as an argument.
const HAS_CONTROLS = {
  BTN: c => false,
  IMG: c => c.blob.mask && (c.mode != 'view') && (c.isPrimary || c.isCardOwner || c.mode == 'preview'),
  TEXT: c => c.blob.template && !c.isSelected && (c.mode != 'view') && (c.isPrimary || c.isCardOwner || c.mode == 'preview'),
  VIDEO: c => (c.controlTarget && (!c.selected || c.isSelected)) || (c.blob.mask && (c.mode != 'view') && (c.isPrimary || c.isCardOwner || c.mode == 'preview')),
  GROUP_VIDEO: c => c.mode != 'preview'
};

function getScrollableParent(node) {
  // Looks up the hierarchy from the given node until we find a scrollable parent node
  if (!node)
    return null;
  if (node.scrollHeight > node.clientHeight && ![ 'visible', 'hidden' ].includes(getComputedStyle(node).overflowY))
    return node;
  return getScrollableParent(node.parentNode);
}

function getClientXY(e, offsetY=0) {
  if (e.type === 'touchend')
    return [ e.changedTouches[0].clientX, e.changedTouches[0].clientY + offsetY ];
  if (e.touches)
    return [ e.touches[0].clientX, e.touches[0].clientY + offsetY ];
  return [ e.clientX, e.clientY + offsetY ];
}

function isTouch(e) {
  return window.TouchEvent && (e instanceof TouchEvent);
}

export default {
  name: 'blob',

  components: {
    BlobFrame,
    BlobMask,
    BlobSelectionControls,
    BtnBlob,
    GiftcardBlob,
    GiftcardBlobControls,
    GradientBlob,
    GroupVideoBlob,
    GroupVideoBlobControls,
    ImageBlob,
    MaskBlobControls,
    TextBlob,
    VideoBlob,
    VideoBlobControls
  },

  props: [
    'blob',
    'userId',
    'skin',
    'selected',
    'isPageActive',
    'isPageReadonly',
    'isPrimary',
    'isCardOwner',
    'isVerifiedRecipient',
    'mode',
    'scale',
    'transformInProgress',
    'isRootTransformInProgress',
    'isDraggingOnPage',
    'static',
    'snap',
    'isCropping',
    'isAboveSelected'
  ],

  data() {
    return {
      controlTarget: null,
      isShowingVirtualKeyboard: false
    }
  },

  computed: {
    hasControls() {
      if (this.isCropping)
        return false;
      const fn = HAS_CONTROLS[this.blob.type];
      return fn ? fn(this) : true;
    },

    blobTransform() {
      const b = this.blob;
      return {
        top: (b.y - b.h / 2) + 'px',
        left: (b.x - b.w / 2) + 'px',
        width: b.w + 'px',
        height: b.h + 'px',
        transform: 'rotate(' + b.r + 'deg)'
      };
    },

    blobMaskTransform() {
      const m = this.blob.mask;
      return m && {
        top: (m.y - m.h / 2) + 'px',
        left: (m.x - m.w / 2) + 'px',
        width: m.w + 'px',
        height: m.h + 'px',
        transform: `rotate(${m.r}deg)`
      };
    },

    blobMaskRelativeTransform() {
      const b = this.blob;
      const m = b.mask;
      if (!m)
        return;
      // The center of the blob in the mask's coordinate system (centered on its origin)
      const b_center_m = translate(rotate([ m.x, m.y ], [ b.x, b.y ], m.r * Math.PI / 180), [ - m.x, - m.y ]);
      const [ left, top ] = translate(b_center_m, [ m.w / 2 - b.w / 2, m.h / 2 - b.h / 2 ]);
      return {
        width: b.w + 'px',
        height: b.h + 'px',
        left: left + 'px',
        top: top + 'px',
        transform: `rotate(${b.r - m.r}deg)`
      };
    },

    opacity() {
      const m = (this.isCropping && this.isAboveSelected) ? .5 : 1;
      return m * (this.blob.hasOwnProperty('opacity') ? this.blob.opacity : 1);
    },

    blobFrameStyles() {
      const styles = { cursor: this.isEditable ? 'pointer' : 'inherit', opacity: this.opacity };
      if (this.blob.crop && (this.blob.type == 'TEXT')) {
        for (const key of [ 'top', 'right', 'bottom', 'left' ])
          styles[key] = `-${this.blob.crop[key] || 0}%`;
        if (this.blob.crop.r)
          styles.transform = `rotate(${this.blob.crop.r}deg)`;
      }
      return styles;
    },

    canTransform() {
      return canTransform(this.blob, this.mode);
    },

    isEditable() {
      if (this.isCropping && !this.isSelected)
        return false;
      if (!this.isPageActive || this.isPageReadonly)
        return false;
      return isBlobEditable(this.blob, this.mode, this.userId, this.isCardOwner);
    },

    isSelected() {
      return this.selected === this.blob;
    },

    opts() {
      return this.blob[ this.blob.type.toLowerCase() ];
    },

    showPrivacyScreen() {
      return this.skin?.private_pages && !this.isCardOwner && !this.isVerifiedRecipient && (this.blob.template != 'cover') && this.blob.user_id && !this.isPrimary;
    }
  },

  created() {
    Object.assign(this, {
      // The 'buildTransformHandler' allows us to write consolidated code for handling transformations by both mouse
      // and touch. This semi-convoluted approach here is the best way I've found of being able to use the handlers in
      // the template.
      resize: this.buildTransformHandler(this.resizeKernel),
      move: this.buildTransformHandler(this.moveKernel),
      rotate: this.buildTransformHandler(this.rotateKernel),

      // We use this value for some of the math in our transforms in addition to as a style in the template
      selectionControlsMargin: 8,

      mdiLock
    });
  },

  mounted() {
    if (this.blob.type == 'BTN')
      this.$emit('loaded');
  },

  methods: {
    select() {
      if (!this.isSelected) {
        this.$emit('select');
        this.focus();
      }
    },

    // This function builds a handler suitable for a 'mousedown' or 'touchstart' event. The 'kernel' should be a
    // three-argument function that takes the event itself, and two registration functions for move and end events
    // respectively. Those registration functions should be called at most once within the kernel.
    buildTransformHandler(kernel) {
      let onMoveKernel, onEndKernel;
      return e => {
        const touchId = isTouch(e) && e.touches[0].identifier;
        const [ moveEventName, endEventName ] = isTouch(e) ? [ 'touchmove', 'touchend' ] : [ 'mousemove', 'mouseup' ];

        kernel(e, x => onMoveKernel = x, x => onEndKernel = x);

        let onMove = e => {
          e.stopPropagation();

          if (this.isShowingVirtualKeyboard)
            return;
          if (this.isRootTransformInProgress)
            return;
          if (isTouch(e) && (e.touches.length > 1 || touchId != e.touches[0].identifier || isZoomed()))
            return;
          onMoveKernel && onMoveKernel(e);

          // This seems to be necessary for transforming images (so perhaps necessary in general)
          this.$forceUpdate();
        };

        if (!this.canTransform)
          // Note that we leave the 'onEnd' handler alone in this case
          onMove = e => null;

        const onEnd = e => {
          onEndKernel && onEndKernel(e);
          removeEventListener(moveEventName, onMove);
          removeEventListener(endEventName, onEnd);
          this.endTransform();
        };

        // Note that these listeners are attached to the window, not the element
        addEventListener(moveEventName, onMove);
        addEventListener(endEventName, onEnd);
      };
    },

    startTransform(t) {
      return this.isEditable && this.$emit('update:transform-in-progress', t);
    },

    endTransform() {
      // Our transformation event handlers follow one of the following two sequences:
      // mousedown -> [mousemove] -> mouseup
      // touchstart -> [touchmove] -> touchend
      // This function is called at the end of 'mouseup' and 'touchend'. When our transformation has completed, the
      // browser will fire a 'click' event at the current location. This location may be OUTSIDE of this component
      // (i.e. we drag the rotate handler and our mouse goes off the button). We do stop propagation of all 'click'
      // events in a blob, but that won't catch these events (because the 'click' event will never fire in the blob).
      // We do know that the browser always fires the 'click' event after 'mouseup' and 'touchend', so we use
      // 'setTimeout' to wait until after those handlers have been called before updating the 'transformInProgress'
      // property so that parent 'click' handlers can choose to ignore events if that property is set to true.
      return this.isEditable && setTimeout(() => this.$emit('update:transform-in-progress', null));
    },

    getRealCoordinates(e, bounds) {
      // Returns the coordinates of the event in the coordinate system of the page
      return scale(translate(getClientXY(e), [ -bounds.x, -bounds.y ]), 1 / this.scale);
    },

    shouldPreserveAspectRatio() {
      if (this.isCropping)
        return false;
      switch (this.blob.type) {
        case 'BTN':
        case 'TEXT':
        case 'GRADIENT':
          return false;
        case 'IMG':
        case 'VIDEO':
          return !(this.mode == 'design' && this.blob.img.src == '/template-placeholder.png');
        default:
          return true;
      }
    },

    resizeKernel(e, onMove, onEnd) {
      this.startTransform('resize');
      const b = this.blob;
      const r = b.r * Math.PI / 180;
      const blobConstraints = constraints.blob[b.type];
      const edgeOffset = this.selectionControlsMargin - (blobConstraints.ALIGN_EDGES_OFFSET || 0);
      const m = this.selectionControlsMargin * 2;
      const bounds = this.$el.parentNode.getBoundingClientRect();

      const c0 = [ b.x, b.y ];
      const p0_real = this.getRealCoordinates(e, bounds);
      const p0_center = rotate(c0, p0_real, r);
      const anchor_sign_x = p0_center[0] < b.x ? 1 : -1;
      const anchor_sign_y = p0_center[1] < b.y ? 1 : -1;
      const anchor = translate(rotate([0,0], [ anchor_sign_x * b.w / 2, anchor_sign_y * b.h / 2 ], -r), c0);
      const p0 = translate(rotate(anchor, p0_real, r), [ - anchor[0], - anchor[1] ]);

      // We only use these properties if we need to preserve the aspect ratio
      const preserveAspectRatio = this.shouldPreserveAspectRatio();
      const aspectRatio = b.w / b.h;
      const w0 = b.w;
      const h0 = b.h;
      const minScale = Math.max(blobConstraints.MIN_W / b.w, blobConstraints.MIN_H / b.h);
      const maxScale = Math.min(blobConstraints.MAX_W / b.w, blobConstraints.MAX_H / b.h);
      const d0 = Math.sqrt(p0[0] * p0[0] + p0[1] * p0[1]);
      const crop0 = _.cloneDeep(b.crop);

      // Our current snapping logic does not support fixed aspect ratio blobs with rotation
      const ignoreSnap = preserveAspectRatio && (b.r % 180 != 0);

      onMove(
        e => {
          const p1_real = this.getRealCoordinates(e, bounds);
          let bestSnap = null;
          if (this.snap && !ignoreSnap) {
            // You could use something like this to limit movement on the page
            // p1_real[0] = 20 * Math.round(p1_real[0] / 20);
            // p1_real[1] = 20 * Math.round(p1_real[1] / 20);

            // If we're close to an edge, snap it
            for (const [ p1_real_i, guide, ...offsets ] of [ [ 0, 'vertical', 0, 600 ], [ 1, 'horizontal', 0, 840 ] ]) {
              let found = false;
              for (let i = 0; i < 2; i++) {
                const d = Math.abs(p1_real[p1_real_i] - offsets[i]);
                if (d < SNAP_WITHIN) {
                  p1_real[p1_real_i] = offsets[i] + (i ? 1 : -1) * edgeOffset;
                  if (!bestSnap || (d < bestSnap.d))
                    bestSnap = { d, i: p1_real_i };
                  found = true;
                  this.$emit('guide', guide, i);
                  break;
                }
              }
              if (!found)
                this.$emit('guide', guide, -1);
            }

            // TODO: if the blob has a fixed aspect ratio, we should adjust the component that isn't being snapped (according to the 'bestSnap'). One approach is to draw a line from the anchor through the center and work out the intercept for our 'p1_real' point, but I couldn't get it to work exactly right.
          }

          // The coordinates of the current point relative to the anchor in the rotated coordinate system
          const p1 = translate(rotate(anchor, p1_real, r), [ - anchor[0], - anchor[1] ]);

          // Make sure our dragged point does not go past the anchor
          p1[0] = anchor_sign_x > 0 ? Math.min(0, p1[0]) : Math.max(0, p1[0]);
          p1[1] = anchor_sign_y > 0 ? Math.min(0, p1[1]) : Math.max(0, p1[1]);

          if (preserveAspectRatio) {
            if (bestSnap) {
              // Note that this logic only works if the rotation is a multiple of 180 degrees
              if (bestSnap.i) {
                b.h = constrain(Math.abs(p1[1]) - m / 2, blobConstraints.MIN_H, blobConstraints.MAX_H);
                b.w = b.h * aspectRatio;
              } else {
                b.w = constrain(Math.abs(p1[0]) - m / 2, blobConstraints.MIN_W, blobConstraints.MAX_W);
                b.h = b.w / aspectRatio;
              }
            } else {
              // Determine the scale by looking at the distance moved along the diagonal
              const d1 = Math.sqrt(p1[0] * p1[0] + p1[1] * p1[1]);
              const scale = b.mask ? Math.max(d1 / d0, minScale) : constrain(d1 / d0, minScale, maxScale);
              b.w = w0 * scale;
              b.h = h0 * scale;
            }
          } else {
            b.w = constrain(Math.abs(p1[0]) - m / 2, blobConstraints.MIN_W, blobConstraints.MAX_W);
            b.h = constrain(Math.abs(p1[1]) - m / 2, blobConstraints.MIN_H, blobConstraints.MAX_H);
          }

          if (this.isCropping && crop0) {
            const [ activeX, passiveX ] = anchor_sign_x > 0 ? [ 'left', 'right' ] : [ 'right', 'left' ];
            const maxW = w0 + w0 * crop0[activeX] / 100;
            if (b.w > maxW)
              b.w = maxW;
            b.crop[activeX] = 100 * (maxW / b.w - 1);
            b.crop[passiveX] = crop0[passiveX] / (b.w / w0);

            const [ activeY, passiveY ] = anchor_sign_y > 0 ? [ 'top', 'bottom' ] : [ 'bottom', 'top' ];
            const maxH = h0 + h0 * crop0[activeY] / 100;
            if (b.h > maxH)
              b.h = maxH;
            b.crop[activeY] = 100 * (maxH / b.h - 1);
            b.crop[passiveY] = crop0[passiveY] / (b.h / h0);
          }

          // Now adjust the center point so that the anchor doesn't move
          const center = [ - anchor_sign_x * b.w / 2, - anchor_sign_y * b.h / 2 ];
          const center_real = translate(rotate([ 0, 0 ], center, -r), anchor);
          b.x = constrain(center_real[0], 0, constraints.page.W);
          b.y = constrain(center_real[1], 0, constraints.page.H);

          // If the center point got constrained, then we need to readjust the bounds using the anchor as our
          // reference. Note that for blobs with a fixed aspect ratio, we actually move the anchor.
          if (!preserveAspectRatio) {
            const center_constrained = translate(rotate(anchor, [ b.x, b.y ], r), [ - anchor[0], - anchor[1] ]);
            b.w = Math.abs(center_constrained[0]) * 2;
            b.h = Math.abs(center_constrained[1]) * 2;
          }
        }
      );
      onEnd(
        e => {
          this.$emit('guide', 'vertical', -1);
          this.$emit('guide', 'horizontal', -1);
          if (this.$refs.text)
            this.$refs.text.autoScaleText();
        }
      );
    },

    moveKernel(e, onMove, onEnd) {
      if (!this.isEditable)
        return;

      // What if we have a blob selected that happens to be underneath another editable blob? A click that intersected
      // both blobs would immediately deselect that blob in favor of the top one. Not sure that's desirable.
      // const [ clientX, clientY ] = getClientXY(e);
      // const intersectingBlobs = Array.from(document.elementsFromPoint(clientX, clientY)).filter(
      //   el => el.__vue__?.$options?.name == 'blob-frame'
      // ).map(el => el.__vue__.$parent.$parent);
      // if (intersectingBlobs.includes(this.selected))
      //   return;

      let wasSelected = this.isSelected;
      const t0 = Date.now();
      if (this.isSelected || !isTouch(e)) {
        // Setting 'transformInProgress' will prevent any swipe events from firing, so don't explicitly select this
        // until we're sure it's not a swipe event
        this.startTransform('move');
        this.select();
      }

      const scrollableParent = getScrollableParent(e.target) || { scrollTop: 0 };
      const [ x, y ] = getClientXY(e, scrollableParent.scrollTop).map(x => x / this.scale);
      const x0 = x - this.blob.x;
      const y0 = y - this.blob.y;

      const pageRect = this.$el.getBoundingClientRect();
      const blobConstraints = constraints.blob[this.blob.type];
      const edgeOffset = blobConstraints.ALIGN_EDGES_OFFSET || 0;

      let lastEvent = e;
      let didEnd = false;
      let didMove = false;
      const handleMove = e => {
        lastEvent = e;
        didMove = true;
        const [ x1, y1 ] = getClientXY(e, scrollableParent.scrollTop).map(x => x / this.scale);
        if (!wasSelected && isTouch(e)) {
          if (Date.now() - t0 < DETECT_SWIPE_DELAY_MS) {
            // TODO: it seems horribly redundant to have this logic here in addition to the v-touch directive. For
            // now, I've duplicated that logic here sans the minDistance criteria...
            // https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/directives/touch/index.ts

            if (Math.abs(y1 - y) < 0.5 * Math.abs(x1 - x))
              // This could be a swipe event, keep waiting to make sure it's not
              return;
          }
          wasSelected = true;
          this.startTransform('move');
        }
        this.select();

        if (this.isEditable) {
          // One idea for how to apply grid-snapping - I don't actually like it, since it only snaps the center point
          // this.blob.x = 30 * Math.round(constrain(x1 - x0, 0, 600) / 30);
          // this.blob.y = 30 * Math.round(constrain(y1 - y0, 0, 840) / 30);

          this.blob.x = constrain(x1 - x0, 0, 600);
          this.blob.y = constrain(y1 - y0, 0, 840);

          if (this.snap && (!this.blob.template || this.mode == 'design'))
            requestAnimationFrame(
              () => {
                const r = this.$el.firstChild.getBoundingClientRect();
                for (const [ key, guide, ...offsets ] of [
                  [
                    'x',
                    'vertical',
                    (r.left - pageRect.left) / this.scale - edgeOffset,
                    this.blob.x - 300,
                    (r.right - pageRect.right) / this.scale + edgeOffset
                  ],
                  [
                    'y',
                    'horizontal',
                    (r.top - pageRect.top) / this.scale - edgeOffset,
                    this.blob.y - 420,
                    (r.bottom - pageRect.top - (pageRect.width / (5 / 7))) / this.scale + edgeOffset
                  ]
                ]) {
                  let offsetI = -1;
                  for (const i in offsets)
                    if (Math.abs(offsets[i]) < SNAP_WITHIN)
                      offsetI = i;
                  if (offsetI != -1) {
                    this.blob[key] -= offsets[offsetI];
                    this.$emit('guide', guide, offsetI / 2);
                  } else {
                    this.$emit('guide', guide, -1);
                  }
                }
              }
            );
        }
      };

      // There's an extreme case we want to handle - suppose someone starts a swipe gesture, stops moving within the
      // detect-swipe delay, but then never lifts their finger off
      isTouch(e) && setTimeout(() => !didEnd && !this.isSelected && handleMove(lastEvent), DETECT_SWIPE_DELAY_MS);

      onMove(handleMove);
      onEnd(
        e => {
          this.$emit('guide', 'vertical', -1);
          this.$emit('guide', 'horizontal', -1);

          didEnd = true;
          const [ x1, y1 ] = getClientXY(e, scrollableParent.scrollTop).map(x => x / this.scale);
          if (isTouch(e) && !wasSelected)
            if (Date.now() - t0 < DETECT_SWIPE_DELAY_MS)
              if (Math.abs(y1 - y) < 0.5 * Math.abs(x1 - x) && Math.abs(x1 - x) > 16)
                // This was definitely a swipe event, don't select anything
                return;

          this.select();

          if (Math.abs(x1 - x) < 1 && Math.abs(y1 - y) < 1)
            // We just clicking on this, not actually moving it
            return;

          const [ clientX, clientY ] = getClientXY(e);
          const targets = Array.from(document.elementsFromPoint(clientX, clientY)).filter(
            el => {
              const name = { IMG: 'image-blob', VIDEO: 'video-blob' }[this.selected.type];
              return name && (el.__vue__?.$options?.name == name);
            }
          ).map(
            el => el.__vue__.blob
          ).filter(
            blob => (blob.id == this.blob.id) || blob.mask
          );

          // If dragging within a mask:
          // - We'll have ONE blob WITH a mask (same ID)

          // If dragging from a mask to the page:
          // - We'll have NO blobs

          // If dragging around the page (not part of a mask):
          // - We'll have ONE blob WITHOUT a mask (same ID)

          // If dragging into a mask:
          // - We'll have TWO blobs, first one WITHOUT a mask, second one WITH a mask

          // If dragging from one mask to another:
          // - We'll have ONE blob WITH a mask

          let target;
          if (this.mode != 'design') {
            if (!targets.length) {
              target = null;
            } else if (targets.length == 1) {
              if (targets[0].id == this.blob.id)
                target = this.blob;
              else
                target = targets[0];
            } else {
              target = targets[1];
            }
          }

          // Make sure to fire this event after the transform handler's wrapper around the 'onEnd' callback has
          // concluded to ensure 'endTransform' is called first. That function actually uses 'setTimeout' internally,
          // so we actually need another call to ensure we run after it. This is obviously messy and terrible...
          setTimeout(() => setTimeout(() => this.$emit('move-end', target)));
        }
      );
    },

    rotateKernel(e, onMove, onEnd) {
      this.startTransform('rotate');
      const { x, y } = this.$el.parentNode.getBoundingClientRect();
      const x0 = (x / this.scale) + this.blob.x;
      const y0 = (y / this.scale) + this.blob.y;
      onMove(
        e => {
          const [ x1, y1 ] = getClientXY(e).map(x => x / this.scale);
          const r = Math.atan2(y1 - y0, x1 - x0) * 180 / Math.PI - 90;
          this.blob.r = this.snap ? constraints.roundDegrees(r) : r;
        }
      );
    },

    // This method is called externally by the card-editor
    async focus(select) {
      await this.$nextTick();
      if (this.$refs.text)
        this.$refs.text.focus(select);
    },

    showPrivate() {
      this.$confirm({
        msg: 'This card has private signing enabled – you cannot see content added by others.',
        buttons: [ 'Ok' ],
        primary: 'Ok'
      });
    }
  },

  watch: {
    blob: {
      deep: true,
      async handler(b) {
        if (this.mode == 'design' && b.template == 'cover' && b.mask) {
          // If we're in design mode and moving a template around, then make sure our mask moves too
          b.mask.x = b.x;
          b.mask.y = b.y;
          b.mask.w = b.w;
          b.mask.h = b.h;
          b.mask.r = b.r;
          if (b.type == 'IMG')
            b.mask.placeholder = b.img.src;
        } else if (b.template) {
          // We need to set the user ID if we claimed ownership of this template
          if (b.type == 'TEXT') {
            if (hasContent(b.text.text) && (b.text.text != 'Message here')) {
              if (!b.user_id && this.userId)
                b.user_id = this.userId;
            } else {
              if (b.user_id)
                b.user_id = null;
            }
          } else if (b.type == 'IMG') {
            if (b.img.src != b.mask.placeholder) {
              if (!b.user_id)
                b.user_id = this.userId;
            } else {
              if (b.user_id)
                b.user_id = null;
            }
          }
        }
        this.$emit('input', b);
      }
    },

    'blob.type': {
      immediate: true,
      async handler() {
        // Set this property manually since we want to pass it into a child component
        await this.$nextTick();
        this.controlTarget = this.$refs['control-target'];
      }
    }
  }
}
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav-menu', _vm._g(_vm._b({
    attrs: {
      "name": "Invitations / RSVPs",
      "title": "Send any card as an invitation",
      "subtitle": "Customize and track RSVPs automatically",
      "action": "View invitation categories",
      "icon": _vm.mdiCards,
      "to": {
        name: 'catalog-categories',
        query: {
          type: 'invitations'
        }
      }
    }
  }, 'nav-menu', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pa-3 d-sm-flex"
  }, [_c('div', [_vm._l(_vm.categories, function (c, i) {
    var _vm$categories;
    return [c.seasonality != ((_vm$categories = _vm.categories[i - 1]) === null || _vm$categories === void 0 ? void 0 : _vm$categories.seasonality) ? _c('div', {
      staticClass: "caption",
      class: {
        'mt-4': i
      }
    }, [c.seasonality == 0 ? [_vm._v("Private collections")] : c.seasonality == 1 ? [_vm._v("Featured seasonal occasions")] : [_vm._v(_vm._s(i ? '' : 'Invitations for any occasion'))]], 2) : _vm._e(), _c('div', {
      staticClass: "mx-n2"
    }, [_c('v-btn', {
      attrs: {
        "text": "",
        "exact": "",
        "color": c.icon_color || '#1976d2',
        "to": {
          name: 'catalog-category',
          params: {
            category: c.name
          }
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s(c.icon)
      }
    }), _vm._v(" " + _vm._s(c.title) + " ")], 1)], 1)];
  }), _c('div', {
    staticClass: "mt-6 caption"
  }, [_vm._v("Design your own invitations")]), _vm._l(_vm.DESIGN_YOUR_OWN, function (_ref) {
    var name = _ref[0],
      icon = _ref[1],
      route = _ref[2];
    return _c('div', {
      staticClass: "mx-n2"
    }, [_c('v-btn', {
      attrs: {
        "text": "",
        "color": "primary",
        "to": Object.assign({
          name: 'catalog-category'
        }, route)
      }
    }, [_c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "small": "",
        "color": "primary"
      },
      domProps: {
        "innerHTML": _vm._s(icon)
      }
    }), _vm._v(_vm._s(name) + " ")], 1)], 1);
  })], 2), _c('div', {
    staticClass: "ml-sm-3 mt-6 mt-sm-0",
    style: {
      width: _vm.$vuetify.breakpoint.xsOnly ? '100%' : '320px'
    }
  }, [_c('v-responsive', {
    staticClass: "overflow-visible",
    attrs: {
      "aspect-ratio": 16 / 9
    }
  }, [_c('v-lazy', [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('video', {
    staticClass: "d-block",
    attrs: {
      "width": "100%",
      "playsinline": "",
      "autoplay": "autoplay",
      "muted": "muted",
      "loop": "loop",
      "src": "https://cdn.ellacard.com/nav/invitations_320x180.mp4"
    },
    domProps: {
      "muted": true
    }
  })])], 1)], 1), _c('div', {
    staticClass: "mt-2 caption"
  }, [_vm._v("Build RSVP forms with custom questions and automatically collect responses from recipients")])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
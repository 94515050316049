var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    class: (_vm.color || 'primary') + '--text' + (_vm.bold ? ' font-weight-bold' : ''),
    staticStyle: {
      "white-space": "nowrap"
    }
  }, [_vm.v == '' ? [_vm._v("N/A")] : [_c('currency', {
    attrs: {
      "code": _vm.cur,
      "negative": _vm.v.startsWith('-')
    }
  }), _c('span', [_vm._v(_vm._s(_vm.left))]), _vm.right ? [_c('span', [_vm._v(".")]), _c('span', {
    staticStyle: {
      "font-size": ".6em",
      "line-height": "1.43",
      "vertical-align": "text-top"
    },
    domProps: {
      "textContent": _vm._s(_vm.right)
    }
  })] : _vm._e(), _vm.per ? _c('span', {
    class: 'ml-2 caption ' + (_vm.color || 'grey') + '--text' + (_vm.bold ? ' font-weight-bold' : '')
  }, [_vm._v("/ " + _vm._s(_vm.per))]) : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <nav-menu
    name="Invitations / RSVPs"
    title="Send any card as an invitation"
    subtitle="Customize and track RSVPs automatically"
    action="View invitation categories"
    :icon="mdiCards"
    :to="{ name: 'catalog-categories', query: { type: 'invitations' } }"
    v-bind="$attrs" v-on="$listeners"
  >
    <div class="pa-3 d-sm-flex">

      <div>
        <template v-for="c, i in categories">
          <div v-if="c.seasonality != categories[i-1]?.seasonality" class="caption" v-bind:class="{ 'mt-4': i }">
            <template v-if="c.seasonality == 0">Private collections</template>
            <template v-else-if="c.seasonality == 1">Featured seasonal occasions</template>
            <template v-else>{{i ? '' : 'Invitations for any occasion'}}</template>
          </div>

          <div class="mx-n2">
            <v-btn
              text exact
              :color="c.icon_color || '#1976d2'"
              :to="{ name: 'catalog-category', params: { category: c.name } }"
            >
              <v-icon small class="mr-1" v-html="c.icon" />
              {{c.title}}
            </v-btn>
          </div>
        </template>

        <!--
        <div
          v-for="[ q, icon ] in [
            [ 'Party', mdiBalloon ],
            [ 'Holiday', mdiPineTree ],
            [ 'Baby Shower', mdiBabyCarriage ],
            [ 'Retirement', mdiBriefcaseCheck ],
            [ 'Save the Date', mdiCalendarHeart ]
          ]"
          class="mx-n2"
        >
          <v-btn text color="primary" :to="{ name: 'catalog', query: { q: [ 'Invitation', q ], type: 'INVITATION' } }">
            <v-icon small class="mr-1" v-html="icon" />{{q}}
          </v-btn>
        </div>
        -->

        <div class="mt-6 caption">Design your own invitations</div>
        <div v-for="[ name, icon, route ] in DESIGN_YOUR_OWN" class="mx-n2">
          <v-btn text color="primary" :to="{ name: 'catalog-category', ...route }">
            <v-icon small color="primary" class="mr-1" v-html="icon" />{{name}}
          </v-btn>
        </div>
      </div>

      <div class="ml-sm-3 mt-6 mt-sm-0" v-bind:style="{ width: $vuetify.breakpoint.xsOnly ? '100%' : '320px' }">
        <v-responsive class="overflow-visible" :aspect-ratio="16 / 9">
          <v-lazy>
            <v-card outlined>
              <video
                class="d-block"
                width="100%" playsinline="" autoplay="autoplay" muted="muted" loop="loop"
                src="https://cdn.ellacard.com/nav/invitations_320x180.mp4"
              />
            </v-card>
          </v-lazy>
        </v-responsive>
        <div class="mt-2 caption">Build RSVP forms with custom questions and automatically collect responses from recipients</div>
      </div>

    </div>
  </nav-menu>
</template>

<script>
import NavMenu from '@/components/NavMenu.vue';
import loadCategories from '@/mixins/load-categories.js';
import { mdiCards, mdiBalloon, mdiPineTree, mdiBabyCarriage, mdiBriefcaseCheck, mdiCalendarHeart, mdiImageEditOutline } from '@mdi/js';

const DESIGN_YOUR_OWN = [
  [
    'Blank canvas',
    'M 22.7 14.3 L 21.7 15.3 L 19.7 13.3 L 20.7 12.3 C 20.8 12.2 20.9 12.1 21.1 12.1 C 21.2 12.1 21.4 12.2 21.5 12.3 L 22.8 13.6 C 22.9 13.8 22.9 14.1 22.7 14.3 M 13 19.9 L 13 22 L 15.1 22 L 21.2 15.9 L 19.2 13.9 L 13 19.9 M 11 19.9 L 11 19.05 L 11.05 19 L 5 19 L 5 5 L 19 5 L 19 11.31 L 21 9.38 L 21 5 C 21 3.9 20.11 3 19 3 L 5 3 C 3.9 3 3 3.9 3 5 L 3 19 C 3 20.11 3.9 21 5 21 L 11 21 L 11 19.9 Z',
    { params: { category: 'invitations', selectedId: 'e6e1dd2f-faeb-46a5-9b5a-541c427ab0cf' }, query: { q: '#design-your-own' } }
  ],
  [
    'Add your own picture',
    mdiImageEditOutline,
    { params: { category: 'invitations', selectedId: 'bfcf4688-37b7-4617-8c52-4e9e3a8e4437' }, query: { q: '#design-your-own' } }
  ],
  [
    'Invitation templates',
    'M 22.8 16.2 L 14.4 16.2 L 14.4 22.8 L 22.8 22.8 Z M 24 14.4 L 24 22.8 C 24 23.463 23.462 24 22.8 24 L 14.4 24 C 13.737 24 13.2 23.463 13.2 22.8 L 13.2 14.4 C 13.2 13.74 13.734 13.2 14.4 13.2 L 15 13.2 L 15 12 L 16.2 12 L 16.2 13.2 L 21 13.2 L 21 12 L 22.2 12 L 22.2 13.2 L 22.8 13.2 C 23.462 13.2 24 13.737 24 14.4 Z M 17.754 21.6 L 15.846 19.692 L 16.482 19.056 L 17.754 20.328 L 20.682 17.4 L 21.318 18.036 Z M 6 2 L 18 2 C 19.105 2 20 2.895 20 4 L 20 10.227 L 14.127 10.2 L 14.127 11.715 L 13.385 11.715 C 12.561 11.715 11.9 12.397 11.9 13.231 L 11.92 22 L 6 22 C 4.895 22 4 21.105 4 20 L 4 4 C 4 2.895 4.895 2 6 2 Z M 6 4 L 6 8 L 18 8 L 18 4 Z',
    { params: { category: 'invitations' }, query: { q: '#design-your-own' } }
  ],
  [
    'Themed photo templates',
    'M 6 2 L 18 2 C 19.105 2 20 2.895 20 4 L 20 11 L 12.444 11 C 11.647 11 11 11.647 11 12.444 L 11 22 L 6 22 C 4.895 22 4 21.105 4 20 L 4 4 C 4 2.895 4.895 2 6 2 Z M 6 4 L 6 8 L 18 8 L 18 4 Z M 13.333 22 L 22.667 22 L 19.667 18 L 17.333 21 L 15.667 19 Z M 22.667 24 L 13.333 24 C 12.597 24 12 23.403 12 22.667 L 12 13.333 C 12 12.597 12.597 12 13.333 12 L 22.667 12 C 23.4 12 24 12.593 24 13.333 L 24 22.667 C 24 23.403 23.403 24 22.667 24 Z',
    { params: { category: 'invitations' }, query: { q: 'photo templates' } }
  ]
];

export default {
  name: 'nav-menu-group-greeting-cards',

  mixins: [ loadCategories ],

  components: {
    NavMenu
  },

  data() {
    return {
      categories: []
    };
  },

  created() {
    Object.assign(this, { DESIGN_YOUR_OWN, mdiCards, mdiBalloon, mdiPineTree, mdiBabyCarriage, mdiBriefcaseCheck, mdiCalendarHeart });
  },

  watch: {
    loadCategoriesKey: {
      immediate: true,
      async handler() {
        this.categories = (await this.loadCategories()).filter(x => x.seasonality >= 0 && x.seasonality <= 2 && [ 0, 2 ].includes(x.type));
      }
    }
  }
}
</script>

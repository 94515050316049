var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', _vm._b({
    staticClass: "overflow-visible",
    attrs: {
      "content-class": "v-responsive-bounded absolute-fill",
      "aspect-ratio": _vm.aspectRatio
    }
  }, 'v-responsive', _vm.$attrs, false), [_vm.bounds && _vm.bounds.w ? _vm._t("default", null, {
    "bounds": _vm.bounds
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
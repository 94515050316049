<template>
  <nav-menu
    name="Group gift cards"
    title="Add gift cards"
    subtitle="Your whole group can add to one gift card"
    action="View all gift cards"
    :icon="mdiWalletGiftcard"
    :to="{ name: 'catalog-gift-cards' }"
    v-bind="$attrs" v-on="$listeners"
  >
    <v-row class="ma-0">
      <v-col cols="6" sm="5">
        <div class="caption">Wide selection of merchants</div>
        <div v-for="[ name, providerId, img ] in MERCHANTS" class="mx-n2">
          <v-btn text color="primary" :to="{ name: 'catalog-gift-cards', params: { providerId } }">
            <v-img class="mr-2" width="32" :aspect-ratio="300 / 190" :src="img" />{{name}}
          </v-btn>
        </div>
      </v-col>
      <v-col cols="6" sm="7">
        <div class="caption mb-1">Send gift cards in bulk</div>
        <v-btn class="ma-n2" text color="primary" :to="{ name: 'batch-order' }">
          <v-icon small class="mr-1" v-html="mdiCheckboxMultipleBlank" />Create bulk order
        </v-btn>
        <v-row class="mx-n1 mt-2 mb-1">
          <v-col class="pa-1" cols="4">
            <v-card>
              <v-img :aspect-ratio="5 / 7" src="/nav_gift_cards/giftcard_batch_1.png" />
            </v-card>
          </v-col>
          <v-col class="pa-1" cols="4">
            <v-card>
              <v-img :aspect-ratio="5 / 7" src="/nav_gift_cards/giftcard_batch_2.png" />
            </v-card>
          </v-col>
          <v-col class="pa-1" cols="4">
            <v-card>
              <v-img :aspect-ratio="5 / 7" src="/nav_gift_cards/giftcard_batch_3.png" />
            </v-card>
          </v-col>
        </v-row>

        <div class="caption mt-6 mb-1">Send internationally</div>
        <v-btn class="ma-n2" text color="primary" :to="{ name: 'international-gift-cards' }">
          <v-icon small color="primary" class="mr-1" v-html="mdiEarth" />Supported countries / merchants
        </v-btn>
      </v-col>
    </v-row>
  </nav-menu>
</template>

<script>
import NavMenu from '@/components/NavMenu.vue';
import { mdiCheckboxMultipleBlank, mdiEarth, mdiWalletGiftcard } from '@mdi/js';

const MERCHANTS = [
  [ 'Amazon', 'RU:AMZ-US', 'https://gift.runa.io/static/product_assets/AMZ/AMZ-card.png' ],
  [ 'Apple', 'TR:DC82VBYLI4CC', 'https://giftrocket-s3.imgix.net/Brands/US/iTunes/Digital/B2B/iTunes_B2B.png' ],
  [ 'Google', 'RU:PLAY-US', 'https://gift.runa.io/static/product_assets/PLAY-US/PLAY-US-card.png' ],
  [ 'Home Depot', 'TR:AFO794BZA8LR', 'https://giftrocket-s3.imgix.net/Brands/US/The+Home+Depot/Digital/The+Home+Depot.png' ],
  [ `Lowe's`, 'RU:LOWES-US', 'https://gift.runa.io/static/product_assets/LOWES-US/LOWES-US-card.png' ],
  [ 'Starbucks', 'TR:2XG0FLQXBDCZ', 'https://giftrocket-s3.imgix.net/Brands/US/Starbucks/Digital/starbucks_image_for_certificate.png' ],
  [ 'Target', 'TR:SRDHFATO9KHN', 'https://giftrocket-s3.imgix.net/Brands/US/Target/Digital/Target.png' ],
  [ 'Walmart', 'RU:WMART-US', 'https://gift.runa.io/static/product_assets/WMART-US/WMART-US-card.png' ],
  [ 'More…', undefined, '/nav_gift_cards/giftcard_teaser.gif' ]
];

export default {
  name: 'nav-menu-group-gift-cards',

  components: {
    NavMenu
  },

  created() {
    Object.assign(this, { MERCHANTS, mdiCheckboxMultipleBlank, mdiEarth, mdiWalletGiftcard });
  }
}
</script>

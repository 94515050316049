/**
 * Use this class to track the status of async function calls
 *
 * TODO: add support for multiple simultaneous calls
 */
class AsyncStatus {

  constructor() {
    this.reset();
  }

  reset() {
    this.promise = new Promise(r => r());
    this.pending = false;
    this.completed = false;
    this.resolved = false;
    this.rejected = false;
    this.error = null;
  }

  track(fn) {
    this.reset();
    this.pending = true;
    const asyncStatus = this;
    this.promise = fn.apply(this, arguments)
      .then(data => {
        asyncStatus.resolved = true;
        asyncStatus.rejected = false;
        asyncStatus.error = null;
        return data;
      })
      .catch(e => {
        asyncStatus.resolved = false;
        asyncStatus.rejected = true;
        asyncStatus.error = e;
        console.error(e);
      })
      .finally(() => {
        asyncStatus.completed = true;
        asyncStatus.pending = false;
      });
    return this.promise;
  }

}


export default AsyncStatus;

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "root fill-height relative",
    on: {
      "click": function ($event) {
        (!_vm.paused || !_vm.disabled && !_vm.loading) && _vm.$emit('update:paused', !_vm.paused);
      }
    }
  }, [_c('div', {
    staticClass: "main-container"
  }, [_vm._t("default")], 2), _c('div', {
    staticClass: "msg-container",
    class: {
      hide: !_vm.paused
    },
    staticStyle: {
      "top": "0"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm._t("header")], 2), _c('div', {
    staticClass: "msg-container",
    class: {
      hide: !_vm.paused
    },
    staticStyle: {
      "bottom": "0"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm._t("fixed-footer"), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-btn', {
    staticClass: "ma-1",
    attrs: {
      "icon": "",
      "dark": "",
      "disabled": _vm.disabled,
      "loading": _vm.loading
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('update:paused', !_vm.paused);
      }
    }
  }, [_vm.paused ? _c('v-icon', [_vm._v(_vm._s(_vm.mdiPlay))]) : _c('v-icon', [_vm._v(_vm._s(_vm.mdiPause))])], 1), _c('div', {
    staticClass: "white--text"
  }, [_vm._v(_vm._s(_vm._f("video-timestamp")(_vm.t)))]), _c('v-slider', {
    staticClass: "px-3",
    attrs: {
      "hide-details": "",
      "color": "primary",
      "step": "0",
      "max": _vm.duration,
      "disabled": _vm.disabled,
      "value": _vm.t
    },
    on: {
      "input": function (t) {
        return _vm.$emit('update:t', t);
      },
      "mousedown": function ($event) {
        return _vm.$emit('update:paused', true);
      },
      "touchstart": function ($event) {
        return _vm.$emit('update:paused', true);
      }
    }
  }), _c('div', {
    staticClass: "pr-4 white--text"
  }, [_vm._v(_vm._s(_vm._f("video-timestamp")(_vm.duration)))]), _vm.allowFullscreen ? _c('v-btn', {
    staticClass: "ma-1",
    attrs: {
      "icon": "",
      "dark": ""
    },
    on: {
      "click": _vm.toggleFullscreen
    }
  }, [_vm.isFullscreen ? _c('v-icon', [_vm._v(_vm._s(_vm.mdiFullscreenExit))]) : _c('v-icon', [_vm._v(_vm._s(_vm.mdiFullscreen))])], 1) : _vm._e()], 1)], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('v-container', [_c('div', {
    staticClass: "pb-6 relative"
  }, [_c('a', {
    attrs: {
      "id": _vm.id
    }
  }), _c('h2', {
    staticClass: "text-h4 text-md-h3 text-center"
  }, [_c('a', {
    staticStyle: {
      "color": "unset",
      "text-decoration": "unset"
    },
    attrs: {
      "href": '#' + _vm.id
    },
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  })]), _vm.subtitle ? _c('h4', {
    staticClass: "mt-3 text-h6 text-sm-h5 text-center",
    domProps: {
      "innerHTML": _vm._s(_vm.subtitle)
    }
  }) : _vm._e()]), _vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
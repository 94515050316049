<template>
  <div style="position: relative;">
    <v-responsive
      style="position: absolute; z-index: 3;"
      :style="vertical ? 'left: 5.9%; top: 11%; width: 88.5%;' : 'left: 11%; top: 4.8%; width: 78%;'"
      :aspect-ratio="vertical ? 9 / 16 : 16 / 9"
    >
      <slot></slot>
    </v-responsive>
    <v-img
      style="position: relative; z-index: 2; pointer-events: none;"
      width="100%" :aspect-ratio="vertical ? 355 / 720 : 720 / 355"
      :src="`https://cdn.ellacard.com/start/mobile-outline${vertical ? '-vertical' : ''}.png`"
    />
  </div>
</template>

<script>
export default {
  name: 'outline-phone',
  props: { vertical: Boolean }
};
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "white--text text-h6"
  }, [_c('span', {
    staticClass: "mb-6 quoted"
  }, [_c('div', {
    staticClass: "before"
  }, [_vm._v("“")]), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.text)
    }
  }), _c('div', {
    staticClass: "after"
  }, [_vm._v("”")])]), _c('div', {
    staticClass: "d-flex align-center mt-4"
  }, [_vm.img ? _c('v-avatar', {
    staticClass: "d-block mr-2",
    attrs: {
      "size": "40"
    }
  }, [_c('v-img', {
    attrs: {
      "alt": _vm.author,
      "src": _vm.img
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "font-italic",
    domProps: {
      "innerHTML": _vm._s(_vm.author)
    }
  }), _c('v-spacer')], 1), _vm.brand ? _c('a', {
    attrs: {
      "target": "_blank",
      "href": _vm.link
    }
  }, [_c('img', {
    staticClass: "mt-6",
    attrs: {
      "height": "48",
      "src": _vm.brand
    }
  })]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }
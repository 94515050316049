var render = function () {
  var _vm$$route$hash;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overflow-x-hidden"
  }, [_c('video-dialog', {
    attrs: {
      "hash": "#intro-group-cards",
      "poster": "https://cdn.ellacard.com/start/group_cards_intro_poster.jpg",
      "src": ("https://cdn.ellacard.com/start/group_cards_intro_hd" + (_vm.IS_MOBILE ? '480' : '720') + ".mp4")
    }
  }), _c('video-dialog', {
    attrs: {
      "hash": "#intro-group-videos",
      "poster": "https://cdn.ellacard.com/start/group_video_intro_poster.jpg",
      "src": ("https://cdn.ellacard.com/start/group_video_intro_hd" + (_vm.IS_MOBILE ? '480' : '720') + ".mp4")
    }
  }), _c('video-dialog', {
    attrs: {
      "hash": "#intro-gift-cards",
      "poster": "https://cdn.ellacard.com/start/gift_cards_poster.jpg",
      "src": "https://cdn.ellacard.com/start/gift_cards_720.mp4"
    }
  }), (_vm$$route$hash = _vm.$route.hash) !== null && _vm$$route$hash !== void 0 && _vm$$route$hash.startsWith('#demo') ? _c('send-sample-dialog', {
    attrs: {
      "sample": _vm.SAMPLE
    }
  }) : _vm._e(), _c('v-container', {
    staticClass: "mt-6 mt-sm-12 py-sm-12 text-center text-sm-left"
  }, [_c('div', {
    staticClass: "float-sm-right d-block ml-sm-3 mb-6 mb-sm-3",
    style: _vm.$vuetify.breakpoint.xsOnly ? 'max-width: 100%;' : 'max-width: 60%; width: 640px;'
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 640 / 294
    }
  }, [_c('img', {
    staticClass: "d-block",
    attrs: {
      "width": "100%",
      "alt": "Sample group greeting card from Ellacard with custom pictures, videos, GIFs, and text",
      "src": "https://cdn.ellacard.com/start/header_laptop.jpg"
    }
  })])], 1), _c('h1', {
    staticClass: "text-h4 text-sm-h3 text-md-h2"
  }, [_vm._v(" Easy to make, "), _c('br'), _vm._v("amazing to receive ")]), _c('h2', {
    staticClass: "text-h6 text-sm-h5 mt-6"
  }, [_vm._v(" Create and send group greeting cards, invitations, and collaborative videos ")]), _c('div', {
    staticClass: "mt-6 mx-n5 d-flex flex-wrap justify-center justify-sm-start"
  }, _vm._l([['View all cards', 'catalog', _vm.mdiCards], ['View all video themes', 'catalog-video', _vm.mdiVideoVintage]].concat(_vm.global.xgc ? [] : [['View all gift cards', 'catalog-gift-cards', _vm.mdiWalletGiftcard]], [['Pricing', 'pricing', _vm.mdiCurrencyUsd], ['Business', 'business', _vm.mdiDomain]]), function (_ref) {
    var btn = _ref[0],
      name = _ref[1],
      icon = _ref[2];
    return _c('v-btn', {
      key: btn,
      staticClass: "ma-2",
      staticStyle: {
        "text-transform": "none"
      },
      attrs: {
        "text": "",
        "color": "pink",
        "to": {
          name: name
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      domProps: {
        "innerHTML": _vm._s(icon)
      }
    }), _vm._v(_vm._s(btn) + " ")], 1);
  }), 1), _c('div', {
    staticStyle: {
      "clear": "both"
    }
  })]), _c('clip-waves', {
    staticClass: "mt-6",
    attrs: {
      "offset-top": .75,
      "offset-bottom": .9
    }
  }, [_c('div', {
    staticClass: "relative",
    style: {
      height: {
        xs: 220,
        sm: 280,
        md: 340,
        lg: 400,
        xl: 460
      }[_vm.$vuetify.breakpoint.name] + 'px'
    }
  }, [_c('img', {
    staticClass: "absolute",
    attrs: {
      "width": "100%",
      "srcset": "https://cdn.ellacard.com/start/parallax_1920_2.jpg" + " 1920w, " + "https://cdn.ellacard.com/start/parallax_1440_2.jpg" + " 1440w, " + "https://cdn.ellacard.com/start/parallax_960_2.jpg" + " 960w",
      "src": "https://cdn.ellacard.com/start/parallax_960_2.jpg 960w",
      "alt": "Collage of cards and videos"
    }
  })])]), _c('start-section', {
    staticClass: "mt-12",
    attrs: {
      "id": "group-cards",
      "title": "Send a group greeting card filled with heartfelt messages",
      "subtitle": "Sign greeting cards with text, images, GIFs, videos, and more"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    staticStyle: {
      "max-width": "max(80%, 500px)"
    },
    attrs: {
      "aspect-ratio": 1183 / 700,
      "alt": "Sample card with custom pictures, videos, GIFs, and text",
      "src": "https://cdn.ellacard.com/start/cards.jpg"
    }
  }), _c('div', {
    staticClass: "mx-n2 mt-4 mb-n2 d-flex flex-wrap justify-center"
  }, [_c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "dark": "",
      "color": "pink lighten-3",
      "to": {
        name: 'catalog'
      }
    }
  }, [_vm._v("Start a card")]), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "dark": "",
      "color": "pink lighten-3",
      "to": {
        name: _vm.$route.name,
        hash: '#intro-group-cards'
      }
    }
  }, [_vm._v("How it works"), _c('v-icon', {
    staticClass: "ml-2",
    domProps: {
      "innerHTML": _vm._s(_vm.mdiVideo)
    }
  })], 1), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "dark": "",
      "color": "amber",
      "exact": "",
      "to": _vm.$routeAssign({
        hash: '#demo:sign'
      })
    }
  }, [_vm._v(" Send yourself a sample ")])], 1)], 1), _c('clip-waves', {
    staticClass: "mt-12 white--text",
    staticStyle: {
      "background": "linear-gradient(#7fa8d7, #28547e)"
    },
    attrs: {
      "pad-wave-height": "",
      "offset-top": .4,
      "offset-bottom": .2
    }
  }, [_c('start-section', {
    staticClass: "py-6",
    attrs: {
      "id": "group-videos",
      "title": "Simply amazing group video gifts",
      "subtitle": "Send professional quality group videos for any occasion – no editing required"
    }
  }, [_c('outline-laptop', {
    staticClass: "mx-auto",
    staticStyle: {
      "max-width": "max(80%, 500px)"
    }
  }, [_c('video', {
    attrs: {
      "width": "100%",
      "playsinline": "",
      "autoplay": "",
      "muted": "",
      "loop": "",
      "src": "https://cdn.ellacard.com/start/header.mp4"
    },
    domProps: {
      "muted": true
    }
  })]), _c('div', {
    staticClass: "mx-n2 mt-4 mb-n2 d-flex flex-wrap justify-center"
  }, [_c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "dark": "",
      "color": "teal lighten-2",
      "to": {
        name: 'catalog-video'
      }
    }
  }, [_vm._v("Start a video")]), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "dark": "",
      "color": "teal lighten-2",
      "to": {
        name: _vm.$route.name,
        hash: '#intro-group-videos'
      }
    }
  }, [_vm._v("How it works"), _c('v-icon', {
    staticClass: "ml-2",
    domProps: {
      "innerHTML": _vm._s(_vm.mdiVideo)
    }
  })], 1)], 1)], 1)], 1), _vm.global.xgc ? _c('start-section', {
    staticClass: "mt-12",
    attrs: {
      "id": "gift-cards",
      "title": "Group greeting cards",
      "subtitle": "Add more to an Ellacard group greeting card"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-card', {
    staticClass: "ml-3 float-right",
    attrs: {
      "width": "100"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 5 / 7,
      "src": "https://cdn.ellacard.com/start/card-add-msg.jpeg"
    }
  })], 1), _c('h5', {
    staticClass: "text-h5"
  }, [_vm._v("Messages")]), _vm._v(" Resize and drag your own custom message with custom fonts and colors. ")], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-card', {
    staticClass: "ml-3 float-right",
    attrs: {
      "width": "100"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 5 / 7,
      "src": "https://cdn.ellacard.com/start/card-add-img.jpeg"
    }
  })], 1), _c('h5', {
    staticClass: "text-h5"
  }, [_vm._v("Pictures")]), _vm._v(" Drag and drop your own pictures. Pinch to resize, rotate, and choose from lots of different crisp frame effects. ")], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-card', {
    staticClass: "ml-3 float-right",
    attrs: {
      "width": "100"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 5 / 7,
      "src": "https://cdn.ellacard.com/start/card-add-group-video.jpeg"
    }
  })], 1), _c('h5', {
    staticClass: "text-h5"
  }, [_vm._v("Videos")]), _vm._v(" Easily upload or record a video right in your browser, or use our one-of-a-kind tools to make a custom video with a group. ")], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-card', {
    staticClass: "ml-3 float-right",
    attrs: {
      "width": "100"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 5 / 7,
      "src": "https://cdn.ellacard.com/start/card-add-gif.jpeg"
    }
  })], 1), _c('h5', {
    staticClass: "text-h5"
  }, [_vm._v("GIFs")]), _vm._v(" Add some fun to your card with our integrated GIF library. ")], 1)], 1)], 1) : _c('start-section', {
    staticClass: "mt-12",
    attrs: {
      "id": "gift-cards",
      "title": "Ellacard has group gift cards",
      "subtitle": "Wide selection of merchants – batch ordering is perfect for employee rewards"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    staticStyle: {
      "max-width": "594px"
    },
    attrs: {
      "aspect-ratio": 594 / 295,
      "alt": "Gift card merchants",
      "src": "https://cdn.ellacard.com/start/giftcard_merchants_594x295.png"
    }
  }), _c('div', {
    staticClass: "mx-n2 mb-n2 mt-4 d-flex flex-wrap justify-center"
  }, [_c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "dark": "",
      "color": "pink lighten-3",
      "to": {
        name: 'catalog'
      }
    }
  }, [_vm._v("Start a card")]), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "dark": "",
      "color": "purple lighten-2",
      "to": {
        name: _vm.$route.name,
        hash: '#intro-gift-cards'
      }
    }
  }, [_vm._v("How it works"), _c('v-icon', {
    staticClass: "ml-2",
    domProps: {
      "innerHTML": _vm._s(_vm.mdiVideo)
    }
  })], 1)], 1)], 1), _c('clip-waves', {
    staticClass: "my-12 white--text",
    staticStyle: {
      "background": "linear-gradient(#7fa8d7, #28547e)"
    },
    attrs: {
      "pad-wave-height": "",
      "offset-top": .15,
      "offset-bottom": .35
    }
  }, [_c('start-section', {
    staticClass: "py-6",
    attrs: {
      "id": "how-it-works",
      "title": "How it works"
    }
  }, [_c('start-how-it-works', {
    attrs: {
      "dark": ""
    }
  }), _c('div', {
    staticClass: "mx-n2 mb-n2 pt-6 d-flex flex-wrap justify-center"
  }, [_c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "dark": "",
      "color": "pink lighten-3",
      "to": {
        name: 'catalog'
      }
    }
  }, [_vm._v("Start a card")]), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "dark": "",
      "color": "teal lighten-2",
      "to": {
        name: 'catalog-video'
      }
    }
  }, [_vm._v("Start a video")])], 1)], 1)], 1), _c('start-section', {
    staticClass: "mt-12",
    attrs: {
      "id": "categories",
      "title": "The perfect greeting card for any occasion"
    }
  }, [_c('start-categories'), _c('div', {
    staticClass: "mx-n2 mb-n2 mt-4 d-flex flex-wrap justify-center"
  }, [_c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "dark": "",
      "color": "pink lighten-3",
      "to": {
        name: 'catalog'
      }
    }
  }, [_vm._v("Start a card")]), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "dark": "",
      "color": "teal lighten-2",
      "to": {
        name: 'catalog-video'
      }
    }
  }, [_vm._v("Start a video")])], 1)], 1), _c('clip-waves', {
    staticClass: "mt-12 white--text",
    staticStyle: {
      "background": "linear-gradient(#7fa8d7, #28547e)"
    },
    attrs: {
      "pad-wave-height": "",
      "offset-top": .5,
      "offset-bottom": .2
    }
  }, [_c('start-section', {
    staticClass: "py-6",
    attrs: {
      "id": "business",
      "title": "Business tools",
      "subtitle": "Additional tools, features, and discounts for businesses / organizations"
    }
  }, [_c('start-business-tools')], 1)], 1), _c('start-section', {
    staticClass: "mt-12",
    attrs: {
      "id": "do-more",
      "title": "Do more with Ellacard",
      "subtitle": "Advanced creative and collaborative tools"
    }
  }, [_c('start-features'), _c('div', {
    staticClass: "mx-n2 mb-n2 mt-4 d-flex flex-wrap justify-center"
  }, [_c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "dark": "",
      "color": "pink lighten-3",
      "to": {
        name: 'catalog'
      }
    }
  }, [_vm._v("Start a card")]), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "dark": "",
      "color": "teal lighten-2",
      "to": {
        name: 'catalog-video'
      }
    }
  }, [_vm._v("Start a video")])], 1)], 1), _c('clip-waves', {
    staticClass: "my-12 white--text",
    staticStyle: {
      "background": "linear-gradient(#7fa8d7, #28547e)"
    },
    attrs: {
      "pad-wave-height": "",
      "offset-top": 0,
      "offset-bottom": .2
    }
  }, [_c('start-section', {
    staticClass: "py-6",
    attrs: {
      "id": "reviews",
      "title": "See how much people love Ellacard"
    }
  }, [_c('start-testimonials')], 1)], 1), _c('start-section', {
    staticClass: "my-12",
    attrs: {
      "id": "pricing",
      "title": "Pricing"
    }
  }, [_c('div', {
    staticClass: "mb-6 text-center"
  }, [_vm._v("See our "), _c('router-link', {
    attrs: {
      "to": {
        name: 'pricing'
      }
    }
  }, [_vm._v("pricing page")]), _vm._v(" for more details")], 1), _c('start-pricing', {
    staticClass: "mx-auto",
    on: {
      "start": function (v) {
        return _vm.$router.push({
          name: 'catalog' + (v == 'video' ? '-video' : '')
        });
      }
    }
  })], 1), _c('clip-waves', {
    staticClass: "mt-6 white--text",
    staticStyle: {
      "background": "linear-gradient(#7fa8d7, #28547e)"
    },
    attrs: {
      "pad-wave-height": "",
      "offset-top": .5
    }
  }, [_c('v-container', [_c('h2', {
    staticClass: "my-12 text-h5 text-center"
  }, [_vm._v("Check our "), _c('v-btn', {
    attrs: {
      "href": "/faq"
    }
  }, [_vm._v("FAQ")]), _vm._v(" for more details")], 1), _c('h2', {
    staticClass: "mb-12 text-h5 text-center"
  }, [_vm._v("Please "), _c('v-btn', {
    attrs: {
      "href": "mailto:contact@ellacard.com"
    }
  }, [_vm._v("contact us")]), _vm._v(" with additional questions")], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
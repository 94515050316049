var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overflow-hidden",
    style: Object.assign({
      'clip-path': _vm.path
    }, _vm.pad)
  }, [_c('div', {
    directives: [{
      name: "el-resize",
      rawName: "v-el-resize",
      value: function (_ref) {
        var width = _ref.width,
          height = _ref.height;
        _vm.w = width;
        _vm.h = height;
      },
      expression: "({ width, height }) => { w = width; h = height; }"
    }]
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav-menu', _vm._g(_vm._b({
    attrs: {
      "name": "Group greeting cards",
      "title": "Send any card as a group greeting card",
      "subtitle": "Invite your group to sign remotely",
      "action": "View greeting card categories",
      "icon": _vm.mdiCards,
      "to": {
        name: 'catalog-categories',
        query: {
          type: 'greeting-cards'
        }
      }
    }
  }, 'nav-menu', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pa-3 d-sm-flex flex-row-reverse"
  }, [_c('div', {
    staticClass: "flex-shrink-0 ml-sm-3",
    style: {
      width: _vm.$vuetify.breakpoint.xsOnly ? '100%' : '320px'
    }
  }, [!_vm.global.xgc ? [_c('div', {
    staticClass: "caption"
  }, [_vm._v("Contribute to group gift cards")]), _c('div', {
    staticClass: "mx-n2 mb-6"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "to": {
        name: 'catalog-gift-cards'
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiWalletGiftcard)
    }
  }), _vm._v("View all gift cards ")], 1)], 1)] : _vm._e(), _c('div', {
    staticClass: "mb-6 caption"
  }, [_vm._v("Perfect for "), _c('router-link', {
    attrs: {
      "to": {
        name: 'business'
      }
    }
  }, [_vm._v("business")]), _vm._v(" (remote or in-person)")], 1), _c('v-responsive', {
    staticClass: "overflow-visible",
    attrs: {
      "aspect-ratio": 320 / 246
    }
  }, [_c('v-lazy', [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('video', {
    staticClass: "d-block",
    attrs: {
      "width": "100%",
      "playsinline": "",
      "autoplay": "autoplay",
      "muted": "muted",
      "loop": "loop",
      "src": "https://cdn.ellacard.com/nav/group_cards_320x246.mp4"
    },
    domProps: {
      "muted": true
    }
  })])], 1)], 1), _c('div', {
    staticClass: "mt-2 mb-3 caption"
  }, [_vm._v("Unlimited contributors, unlimited pages. Scheduled send via "), _c('span', {
    staticClass: "green--text"
  }, [_vm._v("email")]), _vm._v(" or "), _c('span', {
    staticClass: "green--text"
  }, [_vm._v("text message")]), _vm._v(".")])], 2), _c('div', {
    staticClass: "flex-grow-1 mb-6 mb-sm-0"
  }, [_vm._l(_vm.categories, function (c, i) {
    var _vm$categories;
    return [c.seasonality != ((_vm$categories = _vm.categories[i - 1]) === null || _vm$categories === void 0 ? void 0 : _vm$categories.seasonality) ? _c('div', {
      staticClass: "caption",
      class: {
        'mt-4': i
      }
    }, [c.seasonality == 0 ? [_vm._v("Private collections")] : c.seasonality == 1 ? [_vm._v("Featured seasonal occasions")] : [_vm._v(_vm._s(i ? '' : 'Group cards for any occasion'))]], 2) : _vm._e(), _c('v-btn', {
      staticClass: "ml-n2",
      attrs: {
        "text": "",
        "exact": "",
        "color": c.icon_color || '#1976d2',
        "to": {
          name: 'catalog-category',
          params: {
            category: c.name
          }
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s(c.icon)
      }
    }), _c('div', [_vm._v(_vm._s(c.title))])], 1), _c('br')];
  })], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
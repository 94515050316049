import iconPathWorkAnniversary from 'raw-loader!@/assets/icon-path-work-anniversary.txt';
import {
  mdiAccountMultiple,
  mdiClover,
  mdiHeart,
  mdiHumanFemaleBoy,
  mdiBabyCarriage,
  mdiCakeVariant,
  mdiEmoticonSad,
  mdiHumanPregnant,
  mdiPartyPopper,
  mdiTagHeart,
  mdiHospitalBox,
  mdiHumanHandsup,
  mdiBriefcaseCheck,
  mdiHandshake,
  mdiRing,
  mdiAccountGroup,
  mdiAlertDecagram,
  mdiCalendarCheck,
  mdiCalendarHeart,
  mdiSchool,
  mdiImagePlus
} from '@mdi/js';


// These categories will show up on the landing pages and search filters
const CORE_CATEGORIES = [
  [ 'Anniversary', mdiAccountMultiple ],
  [ 'Baby', mdiBabyCarriage ],
  [ 'Birthday', mdiCakeVariant ],
  [ 'Condolences', mdiEmoticonSad ],
  [ 'Congrats', mdiPartyPopper ],
  [ 'Farewell', mdiTagHeart ],
  [ 'Get Well', mdiHospitalBox ],
  [ 'Just Because', mdiHumanHandsup ],
  [ 'Retirement', mdiBriefcaseCheck ],
  [ 'Thank You', mdiHandshake ],
  [ 'Wedding', mdiRing ],
  [ 'Welcome', mdiAccountGroup ]
];


// These categories will only show up in the search filters
const SUPPLEMENTAL_CATEGORIES = [
  [ 'Baby Shower', mdiHumanPregnant ],
  [ 'Birth Announcement', mdiHumanFemaleBoy ],
  [ 'Good Luck', mdiClover ],
  [ 'Graduation', mdiSchool ],
  [ 'Love', mdiHeart ],
  [ 'Save the Date', mdiCalendarHeart ],
  [ 'Work Anniversary', iconPathWorkAnniversary ]
];


// These categories concern specific technical attributes and are not user-assigned
const ATTRIBUTE_CATEGORIES = [
  [ 'New', mdiAlertDecagram ],
  [ 'Template', mdiImagePlus ],
  [ 'Invitation', mdiCalendarCheck ]
];


const DEFAULT_CATEGORIES = [ ...CORE_CATEGORIES, ...SUPPLEMENTAL_CATEGORIES ].sort((a, b) => a[0].localeCompare(b[0]));


function buildSearchCategories(local) {
  // Injects the provided 'local' categories into the appropriate position in the general list
  // Each item in the resulting list looks like this:
  // [ query, icon, iconColor, textClass, isDefault ]
  return [
    ...ATTRIBUTE_CATEGORIES,
    ...((local && local.length) ? [ [], ...local, [] ] : [ [] ]),
    ...DEFAULT_CATEGORIES.map(x => [ ...x, null, null, true ])
  ];
}


/*const categories = [
  '4th of July',
  'Admin Professionals' Day',
  'Anniversary',
  'Baby',
  'Baby Shower',
  'Baptism',
  'Belated Birthday',
  'Birthday',
  'Boss',
  'Chinese New Year',
  'Christmas',
  'Condolences',
  'Confirmation',
  'Congrats',
  'Diwali',
  'Easter',
  'Encouragement',
  'Engagement',
  'Farewell',
  'Father's Day',
  'Friendship',
  'Get Well',
  'Goodbye & Good Luck',
  'Graduation',
  'Halloween',
  'Hanukkah',
  'Housewarming',
  'Just for Fun',
  'Kwanzaa',
  'Labor Day',
  'Love',
  'Memorial Day',
  'Miss You',
  'Mother's Day',
  'New Home',
  'New Pet',
  'New Years',
  'Nurse's Day',
  'Passover',
  'Pet died',
  'Promotion',
  'Ramadan',
  'Relocation',
  'Retirement',
  'Rosh Hashanah',
  'Seasons Greetings',
  'Sick',
  'Sorry',
  'St. Patrick's Day',
  'Thank You',
  'Thanksgiving',
  'Thinking of You',
  'Valentine's Day',
  'Veteran's Day',
  'Wedding',
  'Welcome'
];
*/


function buildCategoryURL(category) {
  return category.toLowerCase().replace(/['"]/g, '').replace(/\s/g, '-');
}


export { CORE_CATEGORIES, SUPPLEMENTAL_CATEGORIES, ATTRIBUTE_CATEGORIES, DEFAULT_CATEGORIES, buildSearchCategories, buildCategoryURL };

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list-item', _vm._g(_vm._b({}, 'v-list-item', _vm.$attrs, false), _vm.$listeners), [_c('v-list-item-icon', {
    staticClass: "ma-2",
    staticStyle: {
      "width": "48px"
    }
  }, [_c('account-order-preview', {
    attrs: {
      "mode": "purchase",
      "width": 48,
      "order": _vm.order,
      "is-card-owner": true
    }
  })], 1), _c('v-list-item-content', [_vm._t("default", function () {
    return [_c('div', {
      staticClass: "text--label-active"
    }, [_vm._v("Last updated")]), _c('div', {
      staticClass: "ec-dark-blue--text text-body-2"
    }, [_vm._v(_vm._s(_vm._f("moment")(_vm.order.updated_at)))])];
  })], 2), _c('v-list-item-action', [_c('v-icon', {
    attrs: {
      "color": "ec-dark-blue"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiChevronRight)
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div style="max-width: 600px;">
    <pricing-base class="mb-12" />

    <a id="subscriptions" />
    <h2 class="text-h5 text-center mb-3">Annual subscription plans</h2>
    <div class="text-center mb-6">
      <v-btn color="primary" :to="{ name: 'pricing', hash: '#subscriptions' }">Select plan…</v-btn>
    </div>

    <div class="d-flex">
      <div>Individual</div>
      <v-spacer />
      <div class="primary--text">
        <price value="40" per="year" />
      </div>
    </div>
    <div class="caption" style="max-width: 400px; line-height: 1rem;">For those looking to create and send multiple greeting cards / invitations for personal use</div>

    <div class="mt-4 d-flex">
      <div>Business basic</div>
      <v-spacer />
      <div class="primary--text">
        <price value="60" per="year" />
      </div>
    </div>
    <div class="caption" style="max-width: 400px; line-height: 1rem;">For a few administrators to create and send greeting cards / invitations on behalf of their organization</div>

    <div class="mt-4 d-flex">
      <div>Business plus</div>
      <v-spacer />
      <div class="primary--text">
        <price value="150+" per="year" />
      </div>
    </div>
    <div class="caption mb-12" style="max-width: 400px; line-height: 1rem;">For businesses that want to share credit among employees so they can all create and send greeting cards / invitations</div>


    <a id="pre-paid-credit" />
    <h2 class="text-h5 text-center mb-3">Pre-paid credit</h2>
    <div class="text-center mb-6">
      <v-btn color="primary" :to="{ name: 'pricing', hash: '#pre-paid-credit' }">Add credit…</v-btn>
    </div>

    <div v-for="[ amt, price, discount, details ] in CREDIT_PACKS" class="mt-3 d-flex">
      <div>
        <div class="mb-n1">${{amt}} of credit</div>
        <div class="caption">{{details}}</div>
      </div>
      <v-spacer />
      <v-chip v-if="discount" class="flex-shrink-0 mr-2 px-2" label outlined small color="red">{{discount}}% off</v-chip>
      <price :value="price" />
    </div>
  </div>
</template>

<script>
import Price from '@/components/Price.vue';
import PricingBase from '@/components/PricingBase.vue';

const CREDIT_PACKS = [
  [ 10, 10, 0, '5 personal cards / 2 group cards / 1 invitation' ],
  [ 20, 18, 10, '10 personal cards / 5 group cards / 2 invitations' ],
  [ 50, 40, 20, '25 personal cards / 12 group cards / 7 invitations' ],
  [ 100, 70, 30, '50 personal cards / 25 group cards / 14 invitations' ]
];

export default {
  name: 'start-pricing',
  components: { Price, PricingBase },
  created() {
    Object.assign(this, { CREDIT_PACKS });
  }
}
</script>

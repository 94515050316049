var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('v-responsive', {
    staticStyle: {
      "position": "absolute",
      "z-index": "3"
    },
    style: _vm.vertical ? 'left: 5.9%; top: 11%; width: 88.5%;' : 'left: 11%; top: 4.8%; width: 78%;',
    attrs: {
      "aspect-ratio": _vm.vertical ? 9 / 16 : 16 / 9
    }
  }, [_vm._t("default")], 2), _c('v-img', {
    staticStyle: {
      "position": "relative",
      "z-index": "2",
      "pointer-events": "none"
    },
    attrs: {
      "width": "100%",
      "aspect-ratio": _vm.vertical ? 355 / 720 : 720 / 355,
      "src": ("https://cdn.ellacard.com/start/mobile-outline" + (_vm.vertical ? '-vertical' : '') + ".png")
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-btn', {
    staticClass: "btn-like-google-sign-in",
    attrs: {
      "elevation": "0",
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "innerHTML": _vm._s(_vm.mdiIncognitoCircle)
    }
  }), _c('span', [_vm._v("Stay anonymous")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
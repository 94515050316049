<template>
  <div>
    <v-card v-if="$vuetify.breakpoint.smAndUp" class="float-right ml-3" style="width: 45%;">
      <div class="d-flex align-center pa-1">
        <v-icon color="grey lighten-1" small v-html="mdiArrowLeft" />
        <v-icon color="grey lighten-1" small v-html="mdiArrowRight" />
        <v-card class="mx-1 flex-grow-1 caption grey--text text--lighten-1 px-1" height="24" outlined>ellacard.com</v-card>
        <v-icon color="grey lighten-1" small v-html="mdiRefresh" />
      </div>
      <v-divider />
      <v-responsive :aspect-ratio="4 / 3">
        <video style="position: absolute;" width="100%" playsinline autoplay muted loop :src="STEPS[selected].src" />
      </v-responsive>
    </v-card>

    <div class="my-n6 my-sm-n12">
      <div
        v-for="(step, i) in STEPS"
        class="my-6 my-sm-12 d-flex align-center"
        style="cursor: pointer;"
        @click="selected = $vuetify.breakpoint.xsOnly ? -1 : i"
      >
        <v-btn
          class="flex-grow-0 mr-2"
          icon x-large
          :dark="dark"
          :color="selected == i ? dark ? 'grey darken-3' : 'primary' : null"
          :aria-label="step.title"
        >
          <v-icon x-large v-html="step.icon" />
        </v-btn>
        <div>
          <h5 class="text-h6 text-sm-h5">
            <a style="color: unset; text-decoration: unset;" v-html="step.title" />
          </h5>
          <p class="mb-0" v-html="step.subtitle" />
        </div>
      </div>
    </div>

    <div style="clear: both;" />
  </div>
</template>

<script>
import iconPathEmailSent from 'raw-loader!@/assets/icon-path-email-sent.txt';
import { mdiCards, mdiImageEdit, mdiAccountGroup, mdiArrowLeft, mdiArrowRight, mdiRefresh } from '@mdi/js';


const STEPS = [
  {
    icon: mdiCards,
    title: 'Choose the perfect greeting card',
    subtitle: `Browse our ever-expanding selection or design your own`,
    src: 'https://cdn.ellacard.com/start/browse-cards_640x480.mp4'
  },
  {
    icon: mdiImageEdit,
    title: 'Personalize your greeting card',
    subtitle: `Add text, images, videos, and more with our easy-to-use editor`,
    src: 'https://cdn.ellacard.com/start/editor_2_640x480.mp4'
  },
  {
    icon: iconPathEmailSent,
    title: 'Enter your recipient(s)',
    subtitle: `Ellacard will automatically send your card via text or email on the date you set`,
    src: 'https://cdn.ellacard.com/start/send-date_2_640x480.mp4'
  },
  {
    icon: mdiAccountGroup,
    title: 'Share the link',
    subtitle: `Your friends, family, or coworkers can sign your card too`,
    src: 'https://cdn.ellacard.com/start/share_2_640x480.mp4'
  }
];


export default {
  name: 'start-how-it-works',

  props: { dark: Boolean },

  data() {
    return {
      selected: 0
    };
  },

  created() {
    Object.assign(this, { STEPS, mdiArrowLeft, mdiArrowRight, mdiRefresh })
  },

  watch: {
    '$vuetify.breakpoint.xsOnly': {
      immediate: true,
      handler(v) {
        this.selected = v ? -1 : 0;
      }
    }
  }
}
</script>

<template>
  <section>
    <v-container>
      <div class="pb-6 relative">
        <a :id="id" />
        <h2 class="text-h4 text-md-h3 text-center">
          <a style="color: unset; text-decoration: unset;" :href="'#' + id" v-html="title" />
        </h2>
        <h4 v-if="subtitle" class="mt-3 text-h6 text-sm-h5 text-center" v-html="subtitle" />
      </div>
      <slot />
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'start-section',
  props: { title: String, subtitle: String, id: String }
}
</script>

<template>
  <!-- Make sure the route is resolved so our call to $routeAssign doesn't throw warnings -->
  <v-app v-if="$route.name" v-bind:class="{ 'hide-app': isIE, 'iframe': IS_IFRAME, dark: global.dark }">

    <!-- For global temporary notifications -->
    <snackbar v-if="global.notify" v-bind="global.notify.props" :value="!!global.notify" @input="global.notify = null">
      <span v-html="global.notify.msg" />
    </snackbar>

    <sign-in-dialog v-if="showSignIn" v-model="showSignIn" />

    <snackbar top center min-width="0" color="warning" :value="!!checkPromoCodeMsg">{{checkPromoCodeMsg}}</snackbar>

    <template v-if="!!promoCode && !checkPromoCodeStatus.pending && !checkPromoCodeMsg">

      <sign-in-dialog v-if="!isSignedIn" :value="true" @input="handlePromoCodeSignIn">
        <template v-slot:title>
          <div>Sign in to apply this promo code to your account</div>
        </template>
        <template v-slot:subtitle>
          <code class="d-inline-block overline">{{promoCode}}</code>
        </template>
      </sign-in-dialog>

      <v-dialog v-no-scroll v-else-if="!applyPromoCodeStatus.rejected" :value="true" persistent width="500" @input="stripPromoCode">
        <v-card>
          <v-card-title>
            <div v-if="applyPromoCodeStatus.pending" class="font-italic ml-2">Checking promo code…</div>
            <div v-else-if="!!applyPromoCodeMsg" class="warning--text">Invalid promo code</div>
            <div v-else>Promo code accepted</div>
          </v-card-title>
          <v-card-text>
            <div v-if="applyPromoCodeStatus.pending"><v-progress-circular indeterminate /></div>
            <div v-else-if="!!applyPromoCodeMsg">Could not apply promo code <code>{{promoCode}}</code> &mdash; {{applyPromoCodeMsg}}</div>
            <div v-else>The promo code <code>{{promoCode}}</code> has been added to <router-link :to="{ name: 'account' }" target="_blank">your account</router-link>. It will be automatically applied at checkout.</div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" :disabled="applyPromoCodeStatus.pending" @click="stripPromoCode">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <error-dialog v-else :status="applyPromoCodeStatus" />

    </template>

    <!-- Note that setting the height is necessary to function properly on iOS -->
    <v-navigation-drawer
      v-if="sections.header"
      v-no-scroll
      style="height: 100%;"
      app right disable-resize-watcher color="grey lighten-5"
      v-model="showNavMenu"
    >
      <v-list class="py-0">
        <v-list-item class="pa-2 ec-light-blue" to="/">
          <img class="d-block" height="56" src="/ellacard-logo.png" alt="Ellacard" title="Ellacard" />
          <!--
          <v-spacer />
          <v-btn class="ml-2" active-class="before-opacity-0" large icon color="pink" :to="{ name: 'catalog-video' }" @click.stop="showNavMenu = false">
            <v-icon v-html="mdiVideoVintage" />
          </v-btn>
          <v-btn class="mr-2" active-class="before-opacity-0" large icon color="pink" :to="{ name: 'catalog' }" @click.stop="showNavMenu = false">
            <v-icon v-html="mdiCards" />
          </v-btn>
          -->
        </v-list-item>
        <v-divider />
        <v-list-item :to="{ name: 'account' }">
          <v-icon color="ec-dark-blue" v-html="isSignedIn ? mdiAccountCheck : mdiAccount" />
          <v-list-item-content class="ml-3">
            <v-list-item-title class="ec-dark-blue--text text-body-2">Account</v-list-item-title>
            <v-list-item-subtitle class="caption" v-html="isSignedIn ? global.profile.email : 'Not signed in'" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="showPending = (showPending == 'side') ? null : 'side'">
          <div class="flex-shrink-0">
            <v-progress-circular v-if="loadPendingOrdersStatus.pending" indeterminate color="ec-dark-blue" size="24" width="2" />
            <v-icon v-else color="ec-dark-blue" v-html="mdiCart" />
          </div>
          <v-list-item-title class="ml-3 text-body-2 ec-dark-blue--text">
            Saved projects
          </v-list-item-title>
          <v-list-item-action>
            <v-icon v-html="(showPending == 'side') ? mdiMenuUp : mdiMenuDown" />
          </v-list-item-action>
        </v-list-item>
        <v-expand-transition>
          <div
            v-show="(showPending == 'side') && !loadPendingOrdersStatus.pending"
            class="overflow-auto"
            style="max-height: 372px;"
          >
            <cart-list-item
              v-for="[ orderId, order ] in pendingOrders" :key="'side-' + orderId"
              :order-id="orderId"
              :order="order"
              :to="{ name: 'project', params: { orderId, routeOnClose: $routeAssign({}) } }"
            />
            <div v-if="!pendingOrders.length" class="pl-13 py-3 text--label-active font-italic">No saved projects</div>
          </div>
        </v-expand-transition>
        <v-divider />
        <component
          v-for="component, i in navMenus" :key="'list-' + i"
          :is="component"
          :disabled="menu != -1 && menu != i"
          @spotlight="v => menu = v ? i : menu == -1 || menu == i ? -1 : menu"
        />
        <v-divider />
        <v-list-item
          v-for="[ name, title, icon ] in [
            [ 'catalog-categories', 'Categories', mdiExpandAll ],
            [ 'catalog', 'Cards', mdiCards ],
            [ 'catalog-video', 'Videos', mdiVideoVintage ],
            [ 'featured', 'Featured cards', mdiTrophy ],
            [ 'batch-order', 'Batch order', mdiCalendarMultiselect ],
            [ 'design', 'Design program', mdiImageSizeSelectLarge ],
            [ 'pricing', 'Pricing', mdiCurrencyUsd ],
            [ 'faq', 'Faq', mdiFrequentlyAskedQuestions ],
            [ 'blog', 'Blog', mdiFormatFloatLeft ],
            [ 'site-map', 'More', mdiDotsHorizontal ]
          ]"
          :key="name"
          class="ec-dark-blue--text"
          :to="{ name }"
        >
          <v-icon color="ec-dark-blue" v-html="icon" />
          <v-list-item-title class="ml-3 text-body-2" v-html="title" />
        </v-list-item>
        <v-divider />
        <v-list-item href="mailto:contact@ellacard.com">
          <v-icon color="ec-dark-blue" v-html="mdiEmail" />
          <v-list-item-content class="ml-3">
            <v-list-item-title class="ec-dark-blue--text text-body-2">Contact us</v-list-item-title>
            <v-list-item-subtitle>contact@ellacard.com</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider />

      </v-list>

      <template v-if="global.profile?.admin">
        <div class="overline pt-2 px-4 mb-n2">Admin</div>
        <v-list dense>
          <v-list-item
            v-for="name in $router.options.routes.find(x => x.name == 'admin').children.map(x => x.name.substr(6))"
            :key="name"
            :to="{ name: 'admin-' + name }"
          >
            <v-list-item-title class="font-italic" v-html="name" />
          </v-list-item>
        </v-list>
      </template>

    </v-navigation-drawer>

    <v-main v-if="isLoaded">
      <div v-if="sections.header" class="section-header relative">

        <div class="nav-btns pa-3 d-flex justify-end">
          <div class="flex-grow-1 ma-n2 ma-sm-n1 ma-md-0 overflow-visible" active-class="transparent" style="max-height: 1px;">
            <router-link :to="{ name: 'start' }">
              <img
                v-bind:style="{ height: `${$vuetify.breakpoint.lgAndUp ? '64' : '56'}px` }"
                src="/ellacard-logo.png" alt="Ellacard" title="Ellacard"
              />
            </router-link>
          </div>

          <!-- TODO: experiment with putting a search field in here -->
          <!--
          <v-text-field
            class="mt-0 white"
            style="pointer-events: auto;"
            dense hide-details outlined rounded placeholder="Search"
            :append-icon="mdiMagnify"
          />
          -->

          
          <div
            v-if="$vuetify.breakpoint.mdAndUp"
            class="flex-grow-1 d-flex justify-end justify-md-center"
            v-bind:class="{ 'absolute-fill pa-3': $vuetify.breakpoint.lgAndUp }"
            style="pointer-events: none;"
          >
            <v-btn
              style="pointer-events: auto; text-transform: none;"
              active-class="before-opacity-0" text color="ec-dark-blue" aria-label="View all"
              :to="{ name: 'catalog' }"
            >
              <v-icon v-html="mdiCards" />
              <span class="ml-2 hidden-sm-and-down">View all</span>
            </v-btn>
            <!--
            <v-btn
              style="pointer-events: auto; text-transform: none;"
              active-class="before-opacity-0" text color="pink" aria-label="View all categories"
              :to="{ name: 'catalog-categories' }"
            >
              <v-icon v-html="mdiExpandAll" />
              <span class="ml-2 hidden-sm-and-down">View all categories</span>
            </v-btn>
            -->
          </div>
          

          <v-menu v-if="isSignedIn" eager bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs" v-on="on"
                text aria-label="Account" color="ec-dark-blue"
                :input-value="$route.matched[0]?.name == 'account'"
              >
                <v-icon v-html="mdiAccountCheck" />
                <div class="mx-1 hidden-xs-only" style="max-width: 30vw; overflow: hidden; text-transform: none;">
                  {{global.profile.email}}
                </div>
                <v-icon class="mx-n1" v-html="mdiMenuDown" />
              </v-btn>
            </template>
            <v-list class="py-0 grey lighten-5">
              <v-list-item exact :to="{ name: 'account' }">
                <v-icon color="ec-dark-blue" v-html="mdiAccount" />
                <v-list-item-content class="ml-3">
                  <v-list-item-title class="ec-dark-blue--text text-body-2">Account</v-list-item-title>
                  <v-list-item-subtitle v-if="$vuetify.breakpoint.xsOnly" v-text="global.profile.email" />
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="[ title, name ] in [
                  [ 'Cards you ordered', 'account-orders' ],
                  [ 'Cards you signed', 'account-signed' ],
                  [ 'Cards you received', 'account-received' ],
                  [ 'Ellacard contacts', 'account-contacts' ]
                ]"
                :key="name"
                :to="{ name }"
                class="pl-13"
              >
                <v-list-item-title class="ec-dark-blue--text text-body-2" v-html="title" />
              </v-list-item>
              <v-divider />
              <v-list-item :to="{ name: 'account-design' }">
                <v-icon color="ec-dark-blue" v-html="mdiImageSizeSelectLarge" />
                <v-list-item-title class="ml-3 ec-dark-blue--text text-body-2">Design portal</v-list-item-title>
              </v-list-item>
              <v-divider />
              <v-list-item :to="{ name: 'account-org' }">
                <v-icon color="ec-dark-blue" v-html="mdiDomain" />
                <v-list-item-title class="ml-3 ec-dark-blue--text text-body-2">Business portal</v-list-item-title>
              </v-list-item>
              <v-divider />
              <v-list-item @click="global.jwt = null">
                <v-icon color="ec-dark-blue" v-html="mdiLogout" />
                <v-list-item-title class="ml-3 ec-dark-blue--text text-body-2">Sign out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn v-else text aria-label="Sign in" color="ec-dark-blue" @click="showSignIn = true">
            <v-icon v-html="mdiAccount" />
            <div class="ml-1 hidden-xs-only" style="text-transform: none;">Sign in</div>
          </v-btn>

          <v-menu
            bottom offset-y close-on-content-click min-width="300"
            :value="showPending == 'top'"
            @input="x => showPending = x ? 'top' : null"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs" v-on="on"
                class="pr-2"
                text aria-label="Cart / projects" color="ec-dark-blue"
                :loading="(showPending == 'top') && loadPendingOrdersStatus.pending"
              >
                <v-icon v-html="mdiCart" />
                <v-icon class="mx-n1" v-html="mdiMenuDown" />
              </v-btn>
            </template>
            <v-list v-show="!loadPendingOrdersStatus.pending" class="py-0 grey lighten-5">
              <div class="text--label-active pa-2">Saved projects</div>
              <cart-list-item
                v-for="[ orderId, order ] in pendingOrders" :key="orderId"
                class="pl-0"
                :order-id="orderId"
                :order="order"
                :to="{ name: 'project', params: { orderId, routeOnClose: $routeAssign({}) } }"
              />
              <div v-if="!pendingOrders.length" class="pa-3 text--label-active font-italic">No saved projects</div>
            </v-list>
          </v-menu>

          <v-btn
            text aria-label="Navigation menu" color="ec-dark-blue"
            :input-value="showNavMenu"
            @click="showNavMenu = !showNavMenu"
          >
            <v-icon v-html="mdiMenu" />
          </v-btn>
        </div>

        <div
          v-if="$vuetify.breakpoint.smAndUp"
          class="px-3 pb-3 flex-grow-1 d-flex flex-wrap align-center justify-center"
        >
          <component
            v-for="component, i in navMenus" :key="'head-' + i"
            :is="component"
            header
            :disabled="!showNavMenu && menu != -1 && menu != i"
            @spotlight="v => menu = v ? i : menu == -1 || menu == i ? -1 : menu"
          />
        </div>
      </div>

      <router-view ref="router-view" @hook:mounted="routerViewMounted = true" />
    </v-main>

    <!-- Note that we wait to attach the footer (if needed) until after the route has mounted to avoid CLS -->
    <v-footer v-if="sections.footer" dark tile>
      <div class="flex-grow-1 overline">
        <div class="my-2 float-right">&copy; 2022</div>
        <div class="my-2 white--text">Ellacard, LLC</div>
        <div class="mx-n2 d-flex flex-wrap">
          <router-link class="ma-2 white--text" to="/start">Start</router-link>
          <router-link class="ma-2 white--text" to="/faq">FAQ</router-link>
          <router-link class="ma-2 white--text" to="/about">About</router-link>
          <router-link class="ma-2 white--text" to="/privacy-policy">Privacy Policy</router-link>
          <router-link class="ma-2 white--text" to="/terms-and-conditions">Terms and Conditions</router-link>
          <router-link class="ma-2 white--text" to="/site-map">Site map</router-link>
          <a class="ma-2 white--text" href="mailto:contact@ellacard.com">contact@ellacard.com</a>
        </div>
        <div class="mx-n2 d-flex flex-wrap">
          <v-btn class="ma-2" icon aria-label="Facebook" href="https://www.facebook.com/ellacard/" target="_blank">
            <v-icon>{{mdiFacebook}}</v-icon>
          </v-btn>
          <v-btn class="ma-2" icon aria-label="Instagram" href="https://instagram.com/ellacard_co" target="_blank">
            <v-icon>{{mdiInstagram}}</v-icon>
          </v-btn>
          <v-btn class="ma-2" icon aria-label="LinkedIn" href="https://www.linkedin.com/company/ellacard" target="_blank">
            <v-icon>{{mdiLinkedin}}</v-icon>
          </v-btn>
          <v-btn class="ma-2" icon aria-label="Pinterest" href="https://www.pinterest.com/ellacard_co/" target="_blank">
            <v-icon>{{mdiPinterest}}</v-icon>
          </v-btn>
          <v-btn class="ma-2" icon aria-label="Twitter" href="https://twitter.com/ellacard_co" target="_blank">
            <v-icon>{{mdiTwitter}}</v-icon>
          </v-btn>
        </div>
      </div>
    </v-footer>

    <cookie-warning v-if="showCookieWarning" />

  </v-app>
</template>


<style scoped>
.nav-btns .v-btn {
  min-width: unset;
}

.before-opacity-0::before {
  opacity: 0;
}

.v-application {
  background-color: transparent !important;
}

.section-header {
  background: linear-gradient(#EDF6FC, #FFFFFF);
}

.v-application.hide-app header,
.v-application.hide-app footer,
.v-application.hide-app nav {
  display: none;
}
.v-application.hide-app main {
  padding: 0 !important;
}

.v-application.iframe {
  background-color: transparent !important;
}

main {
  position: relative;
}
footer a {
  white-space: nowrap;
}
</style>


<script>
// Just putting this here - there is no way around it, you will not be able to load previews in SMS texts for iOS users:
// https://stackoverflow.com/questions/50616536/remove-need-to-press-tap-to-load-preview-for-iphone-opengraph-sms-message

// Here's an article for detecting platform:
// https://stackoverflow.com/questions/38241480/detect-macos-ios-windows-android-and-linux-os-with-js

import CartListItem from '@/components/CartListItem.vue';
import CookieWarning from '@/components/CookieWarning.vue';
import NavMenuBusiness from '@/components/NavMenuBusiness.vue';
import NavMenuGroupGiftCards from '@/components/NavMenuGroupGiftCards.vue';
import NavMenuGroupGreetingCards from '@/components/NavMenuGroupGreetingCards.vue';
import NavMenuGroupVideos from '@/components/NavMenuGroupVideos.vue';
import NavMenuInvitations from '@/components/NavMenuInvitations.vue';
import NavMenuOccasions from '@/components/NavMenuOccasions.vue';
import SignInDialog from '@/components/SignInDialog.vue';
import Snackbar from '@/components/Snackbar.vue';
import jwtDecode from 'jwt-decode';
import pendingOrders from '@/mixins/pending-orders.js';
import { COUNTRY_CODE } from '@/utils/intl.js';
import loadFont from '@/utils/load-font.js';
import store from '@/utils/local-storage.js';
import { mdiClose, mdiImageSizeSelectLarge, mdiCards, mdiCart, mdiAccount, mdiAccountCheck, mdiHome, mdiFacebook, mdiInstagram, mdiLinkedin, mdiPinterest, mdiTwitter, mdiTrophy, mdiFormatFloatLeft, mdiMenu, mdiEmail, mdiFrequentlyAskedQuestions, mdiCloud, mdiVideoVintage, mdiDotsHorizontal, mdiDomain, mdiMenuDown, mdiMenuUp, mdiWrench, mdiOpenInNew, mdiCurrencyUsd, mdiLogout, mdiCalendarMultiselect, mdiExpandAll } from '@mdi/js';


// This import seems wasted, but it will actually force the start component to be part of the main app chunk, which
// will lead to faster load times for the start page (effectively preloading)
import '@/views/Start.vue';


// Routes for which the cookie warning should not show
const HIDDEN_ROUTES = [ null, '_capture', '_print', 'sign-in', 'card-open', 'card-sign', 'video-project', 'cookies', 'unsubscribe', 'confirm-unsubscribe' ];


// Waits for any one of the specified events 'evts' to occur on the media element
async function waitEl(el, evts) {
  return await new Promise(r => {
    const handler = () => {
      evts.map(evt => el.removeEventListener(evt, handler));
      r();
    };
    evts.map(evt => el.addEventListener(evt, handler));
  });
}


export default {
  name: 'app',

  mixins: [ pendingOrders ],

  components: {
    CartListItem,
    CookieWarning,
    SignInDialog,
    Snackbar
  },

  data() {
    return {
      isLoaded: false,
      menu: -1, // The index of the spotlighted menu in navMenus
      showNavMenu: false,
      showSignIn: false,
      showPending: null,
      routerViewMounted: false,
      checkPromoCodeMsg: null,
      checkPromoCodeStatus: this.$track('checkPromoCode'),
      applyPromoCodeMsg: null,
      applyPromoCodeStatus: this.$track('applyPromoCode')
    };
  },

  computed: {
    promoCode() {
      return this.$route.query['promo-code'];
    },

    referralCode() {
      return this.$route.query['referral-code'];
    },

    isIE() {
      return /MSIE|Trident/.test(window.navigator.userAgent);
    },

    showCookieWarning() {
      // Note that we don't bother showing the cookie warning if we don't even have access to localStorage to avoid
      // annoyingly popping it up constantly
      return !this.IS_IFRAME && !this.global.cookies && store.isAvailable() && !HIDDEN_ROUTES.includes(this.$route.name);
    },

    topLevelRouteName() {
      return this.$route?.matched[0]?.name;
    },

    sections() {
      if (!this.routerViewMounted)
        // If there is no mounted route, then we don't show any sections
        return {};

      const output = { header: true, footer: !this.$refs['router-view']?.loadRouteStatus?.pending };
      for (const m of this.$route.matched)
        for (const section of (m.meta && m.meta.hide || []))
          output[section] = false;
      return output;
    },

    navMenus() {
      return [
        NavMenuOccasions,
        NavMenuInvitations,
        NavMenuGroupGreetingCards,
        ...(this.global.xgc ? [] : [ NavMenuGroupGiftCards ]),
        NavMenuGroupVideos,
        NavMenuBusiness
      ];
    }
  },

  created() {
    loadFont('Nunito').finally(() => this.isLoaded = true);

    Object.assign(this, {
      mdiClose,
      mdiImageSizeSelectLarge,
      mdiCards,
      mdiCart,
      mdiDomain,
      mdiCurrencyUsd,
      mdiAccount,
      mdiAccountCheck,
      mdiHome,
      mdiFacebook,
      mdiInstagram,
      mdiLinkedin,
      mdiPinterest,
      mdiTwitter,
      mdiMenu,
      mdiEmail,
      mdiCloud,
      mdiDotsHorizontal,
      mdiMenuDown,
      mdiMenuUp,
      mdiOpenInNew,
      mdiVideoVintage,
      mdiFrequentlyAskedQuestions,
      mdiFormatFloatLeft,
      mdiTrophy,
      mdiLogout,
      mdiCalendarMultiselect,
      mdiExpandAll
    });
  },

  async mounted() {
    // Wait until all imgs and videos have loaded (or our timeout) before you load the deferred scripts
    await this.$nextTick();
    await Promise.race([
      Promise.all([
        ...Array.from(document.images).filter(x => !x.complete).map(x => waitEl(x, [ 'load', 'error' ])),
        ...Array.from(document.getElementsByTagName('video')).filter(x => !x.readyState).map(x => waitEl(x, [ 'loadedmetadata', 'error' ]))
      ]),
      new Promise(r => setTimeout(r, 5000))
    ]);
  },

  methods: {
    async checkPromoCode() {
      // This function essentially pre-checks a promo code without the user account data - just to see if it's still
      // viable. Any failures are presented unobtrusively in the UI - it's likely that people will click on links with
      // expired promo codes, and they should still have a good experience.
      try {
        this.checkPromoCodeMsg = await this.$call('check-promo-code', { code: this.promoCode });
      } catch (e) {
        this.checkPromoCodeMsg = 'Error checking promo code - please try agian later';
      }
      if (this.checkPromoCodeMsg) {
        this.stripPromoCode();
        setTimeout(() => this.checkPromoCodeMsg = null, 5000);
      } else {
        if (this.isSignedIn)
          this.applyPromoCode();
        else
          // The sign-in dialog is automatically shown in this case - make sure our promo card is not overlapping
          this.global.didClosePromoCard = true;
      }
    },

    stripPromoCode() {
      // Removes the promo-code from the route
      this.$router.push(this.$routeAssign({ query: _.omit(this.$route.query, [ 'promo-code' ]) }));
    },

    async applyPromoCode() {
      this.applyPromoCodeMsg = await this.$call('apply-promo-code', { code: this.promoCode });
    },

    async handlePromoCodeSignIn() {
      await this.$nextTick();
      if (this.isSignedIn)
        this.applyPromoCode();
      else
        this.stripPromoCode();
    },

    signOut() {
      this.global.jwt = null;
    },

    trySetLocalStorage(key, value) {
      if (store.isAvailable() && this.global.cookies && this.global.cookies.strict)
        store.set(key, value);
    }
  },

  watch: {
    topLevelRouteName() {
      this.routerViewMounted = false;
    },

    showNavMenu(v) {
      if (!v)
        this.menu = -1;
    },

    globalUserId(v) {
      const p = this.global.profile;
      $event.log('sign-in', v ? (p.anon ? 'anon' : 'real') : null);
      if (v && !p.anon) {
        gtag('set', { user_id: v });
        gtag('set', 'user_data', { email: p.email });
      }
    },

    promoCode(v) {
      if (v)
        this.checkPromoCode();
    },

    referralCode(v) {
      if (v)
        this.global.referralCode = v;
    },

    'global.jwt': {
      immediate: true,
      async handler(v, prev) {
        if (v != prev)
          this.trySetLocalStorage('jwt', v);

        let profile = undefined;
        if (v) {
          try {
            profile = jwtDecode(v);
          } catch (e) {
            console.error('Error decoding JWT', e);
          }
        }
        this.global.profile = profile;

        // this.global.isEligibleForPromo = (COUNTRY_CODE == 'US') && (!profile || !!profile.anon || (await this.$call('is-eligible-for-promo')));
        this.global.isEligibleForPromo = false;
      }
    },

    'global.cookies': {
      immediate: true,
      handler(to, from) {
        if (!store.isAvailable())
          return;

        this.trySetLocalStorage('cookies', to);

        const scope = to || { strict: true };

        // Ignore consent settings for now
        // gtag('consent', 'update', { 'analytics_storage': scope.performance ? 'granted' : 'denied' });
        // gtag('consent', 'update', { 'ad_storage': scope.targeting ? 'granted' : 'denied' });
        // fbq('consent', scope.targeting ? 'grant' : 'revoke');

        if ((!from || !from.strict) && to && to.strict)
          // We've just been given permission to write to localStorage
          for (const key of store.PERSIST)
            store.set(key, this.global[key]);

        else if ((!to || !to.strict) && from && from.strict)
          // We've just lost permission to write to localStorage (delete everything)
          store.clear();
      }
    },

    'global.videoDeviceId'(v) {
      this.trySetLocalStorage('videoDeviceId', v);
    },

    'global.audioDeviceId'(v) {
      this.trySetLocalStorage('audioDeviceId', v);
    },

    'global.showThemePresets'(v) {
      this.trySetLocalStorage('showThemePresets', v);
    },

    'global.showHelp'(v) {
      this.trySetLocalStorage('showHelp', v);
    },

    '$vuetify.breakpoint.xsOnly': {
      immediate: true,
      handler(v) {
        if (v)
          this.global.showHelp = false;
      }
    },

    'global.referralCode': {
      immediate: true,
      handler(v) {
        this.trySetLocalStorage('referralCode', v);
      }
    },

    showPending(v) {
      if (v)
        this.loadPendingOrders();
    },

    $route() {
      this.showPending = false;
      if (this.$vuetify.breakpoint.mdAndDown)
        this.showNavMenu = false;
    },

    async routerViewMounted(v) {
      await this.$nextTick();
      if (v && !this.didLoadDeferredScripts) {
        setTimeout(loadDeferredScripts);
        this.didLoadDeferredScripts = true;
      }
      if (location.hash) {
        const el = document.getElementById(location.hash.substr(1));
        if (el)
          el.scrollIntoView();
      }
    }
  }
};
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('video-dialog', {
    attrs: {
      "hash": "#business-admin-tools",
      "poster": "https://cdn.ellacard.com/start/business/org_users.png",
      "src": "https://cdn.ellacard.com/start/org_admin_tools_720.mp4"
    }
  }), _c('v-row', {
    staticClass: "my-6 flex-row-reverse"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-card', [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 16 / 9
    }
  }, [_c('video', {
    staticClass: "d-block",
    attrs: {
      "width": "100%",
      "playsinline": "",
      "autoplay": "autoplay",
      "muted": "muted",
      "loop": "loop",
      "src": "https://cdn.ellacard.com/start/business/branding_640x360.mp4"
    },
    domProps: {
      "muted": true
    }
  })])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('h3', {
    staticClass: "mb-6 text-h5 text-md-h4"
  }, [_vm._v("Ellacard is the premier platform for sharing appreciation and building community")]), _c('div', {
    staticClass: "mb-6 text-md-h6"
  }, [_vm._v(" Send greeting cards and videos between employees, or use Ellacard for outreach to better engage customers / donors. Perfect for kudos, thanks, birthdays, and more. ")]), _c('div', {
    staticClass: "ma-n2 d-flex flex-wrap"
  }, [_c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "dark": "",
      "color": "pink",
      "to": {
        name: 'business'
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    domProps: {
      "innerHTML": _vm._s(_vm.mdiDomain)
    }
  }), _vm._v("Business portal ")], 1)], 1)])], 1), _c('div', {
    staticClass: "my-6 text-md-h6 text-sm-center"
  }, [_c('div', {
    staticClass: "mx-sm-12 px-sm-12"
  }, [_vm._v("Please contact us to get started – we're happy to answer any questions or give you a demo")]), _c('v-btn', {
    staticClass: "mt-4",
    attrs: {
      "href": "mailto:contact@ellacard.com"
    }
  }, [_vm._v("Contact us")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
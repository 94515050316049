<template>
  <nav-menu
    ref="menu"
    name="Business"
    title="Tools and discounts for businesses"
    subtitle="Employee appreciation and outreach to customers / donors"
    action="Business features"
    :icon="mdiDomainPlus"
    :to="{ name: 'business' }"
    v-bind="$attrs" v-on="$listeners"
  >

    <org-sign-up-dialog
      v-if="showSignUp"
      :value="true"
      @cancel="showSignUp = false"
      @complete="$router.push({ name: 'account-org' })"
    />

    <v-row class="ma-0">
      <v-col cols="6">
        <div class="caption">Tools</div>
        <div
          v-for="[ title, name, icon ] in [
            [ 'Batch order', 'batch-order', mdiCalendarMultiselect ],
            [ 'Create private design', 'img-to-design', mdiIncognitoCircle ],
            [ 'Business portal', 'account-org', mdiDomain ]
          ]"
          :key="name"
          class="mx-n2"
        >
          <v-btn text color="primary" :to="{ name }">
            <v-icon small class="mr-1" v-html="icon" />{{title}}
          </v-btn>
        </div>
        <div class="caption mt-5">More information</div>
        <div
          v-for="[ title, to, icon ] in [
            [ 'Pricing', { name: 'pricing' }, mdiCurrencyUsd ],
            [ 'FAQ', { name: 'faq', hash: '#what-is-an-org-account' }, mdiFrequentlyAskedQuestions ],
            [ 'API docs', { name: 'api-docs' }, mdiApi ]
          ]"
          :key="title"
          class="mx-n2"
        >
          <v-btn text color="primary" :to="to">
            <v-icon small class="mr-1" v-html="icon" />{{title}}
          </v-btn>
        </div>
        <div class="caption mt-5">Our business tools are <span class="green--text">free</span></div>
        <v-btn class="mx-n2" text color="primary" @click="showSignUp = true">Get started…</v-btn>
      </v-col>
      <v-col cols="6">
        <div class="caption mb-1">Add custom branding</div>
        <v-card outlined max-width="320">
          <v-responsive :aspect-ratio="16 / 9">
            <v-lazy>
              <video
                class="d-block"
                width="100%" playsinline="" autoplay="autoplay" muted="muted" loop="loop"
                src="https://cdn.ellacard.com/start/business/branding_640x360.mp4"
              />
            </v-lazy>
          </v-responsive>
        </v-card>
        <div class="caption mt-5 mb-1">Share credits with managed users</div>
        <v-card outlined max-width="320">
          <v-img :aspect-ratio="16 / 9" src="https://cdn.ellacard.com/start/business/org_users.png" />
        </v-card>
      </v-col>
      <!--
      <v-col cols="12">
        <div class="caption">Quickly and easily make custom, professional quality videos</div>
      </v-col>
      -->
    </v-row>
  </nav-menu>
</template>

<script>
import NavMenu from '@/components/NavMenu.vue';
import OrgSignUpDialog from '@/components/OrgSignUpDialog.vue';
import { mdiApi, mdiCurrencyUsd, mdiDomain, mdiDomainPlus, mdiFrequentlyAskedQuestions, mdiCalendarMultiselect, mdiIncognitoCircle, mdiCog } from '@mdi/js';

export default {
  name: 'nav-menu-business',

  components: {
    NavMenu,
    OrgSignUpDialog
  },

  data() {
    return {
      showSignUp: false
    };
  },

  created() {
    Object.assign(this, { mdiApi, mdiCurrencyUsd, mdiDomain, mdiDomainPlus, mdiFrequentlyAskedQuestions, mdiCalendarMultiselect, mdiIncognitoCircle, mdiCog });
  },

  watch: {
    showSignUp(v) {
      // Make sure the menu stays open behind the sign-up dialog (to avoid removing the dialog itself)
      this.$refs.menu.persistent = v;
    }
  }
}
</script>

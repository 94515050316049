import ConfirmDialog from '@/components/ConfirmDialog.vue';
import vuetify from '@/plugins/vuetify';
import Vue from 'vue';

export default {
  methods: {
    // props: {
    //   title: str
    //   msg: str
    //   buttons: [ 'No', 'Yes' ] // Optional, defaults to [ 'No', 'Yes' ]
    //   primary: 'Yes' // Optional, should match one of the buttons in the 'buttons' array, defaults to 'Yes',
    //   persistent: bool // Optional, defaults to true
    //   width: int // Optional, defaults to 300
    //   loading: int // The amount of time in MS to prevent the buttons from being clicked, optional, defaults to 0
    // }
    async $confirm(props) {
      // https://css-tricks.com/creating-vue-js-component-instances-programmatically/
      let instance;
      const result = await new Promise(resolve => {
        const ComponentClass = Vue.extend(Object.assign({ vuetify }, ConfirmDialog));
        instance = new ComponentClass({ propsData: { ...props, resolve } });
        instance.$mount();
        this.$root.$el.appendChild(instance.$el);
      });
      instance.$destroy();
      return result;
    }
  }
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticStyle: {
      "white-space": "nowrap"
    }
  }, [_c('span', [_vm._v("Ella")]), _vm._v(" "), _c('span', {
    style: {
      'margin-left': "-.28em"
    }
  }, [_vm._v("card")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
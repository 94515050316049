var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('v-responsive', {
    staticStyle: {
      "position": "absolute",
      "z-index": "2",
      "left": "15.53%",
      "top": "6.3%",
      "width": "70.14%"
    },
    attrs: {
      "aspect-ratio": 16 / 9
    }
  }, [_vm._t("default")], 2), _c('v-img', {
    staticStyle: {
      "position": "relative",
      "z-index": "1",
      "pointer-events": "none"
    },
    attrs: {
      "width": "100%",
      "aspect-ratio": 1380 / 634,
      "src": "https://cdn.ellacard.com/start/laptop-outline.png"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
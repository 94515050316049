<template>
  <v-responsive-bounds :aspect-ratio="5 / 7" :width="width" v-slot="{ bounds }">
    <div
      class="card-preview-page"
      v-bind:class="{ editable }"
      v-bind:style="{
        transform: `scale(${bounds.w / W})`,
        width: W + 'px',
        height: H + 'px',
        'background-image': card.background.img ? `url('${card.background.img}')` : null,
        'background-color': card.background.color
      }"
    >
      <!-- Note that we set 'is-page-active' to show the template outlines if we are the card owner (only applies to some modes) -->
      <blob
        v-for="blob in blobs" :key="blob.id" :ref="blob.id"
        :class="'blob--' + blob.id"
        :mode="mode"
        :is-page-active="editable"
        :scale="bounds.w / W"
        :blob="blob"
        :skin="card.skin || {}"
        :is-primary="blob.user_id && (blob.user_id == globalUserId)"
        :is-card-owner="isCardOwner"
        :is-verified-recipient="isVerifiedRecipient"
        :static="static"
        @loaded="loaded(blob.id)"
        @input="x => $emit('update-blob', x)"
      />
    </div>
  </v-responsive-bounds>
</template>

<style>
.card-preview-page {
  transform-origin: top left;
  overflow: hidden;
  background-repeat: repeat;
}
.card-preview-page:not(.editable) * {
  pointer-events: none !important;
}
</style>

<script>
import VResponsiveBounds from '@/components/VResponsiveBounds.vue';
import Blob from '@/components/Blob.vue';
import { isBlobVisible } from '@/utils/edit-order.js';
import constraints from '@/card-geometry-constraints.js';

export default {
  name: 'card-preview',

  components: {
    VResponsiveBounds,
    Blob
  },

  props: {
    card: Object,
    width: Number,
    mode: {
      type: String,
      default: 'view'
    },
    isCardOwner: Boolean,
    isVerifiedRecipient: Boolean,
    static: Boolean,

    // You can use this property to get very basic editing capabilities on the blobs. This is really only useful when
    // used as the back of the envelope. Note that 'mode' should also be set to 'edit' if this property is true.
    editable: Boolean
  },

  computed: {
    page() {
      return this.card.pages[0] || { blobs: [] };
    },

    blobs() {
      return (this.page ? this.page.blobs : []).filter(x => isBlobVisible(x, this.mode, this.card.design_id));
    }
  },

  created() {
    Object.assign(this, constraints.page);
  },

  methods: {
    loaded(key) {
      this.keysToLoad.delete(key);
      if (!this.keysToLoad.size)
        this.$emit('loaded');
    }
  },

  watch: {
    blobs: {
      immediate: true,
      handler(v) {
        this.keysToLoad = new Set(v.map(x => x.id));
        if (!v.length)
          this.$emit('loaded');
      }
    }
  }
}
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-alert', {
    staticClass: "cookie-warning primary non-printing",
    class: {
      'mb-0': _vm.$vuetify.breakpoint.xsOnly,
      'ma-2': _vm.$vuetify.breakpoint.smAndUp
    },
    attrs: {
      "dark": "",
      "elevation": "20",
      "tile": _vm.$vuetify.breakpoint.xsOnly
    }
  }, [_c('v-btn', {
    staticClass: "float-right ml-4 mb-4 mb-sm-0",
    attrs: {
      "light": ""
    },
    on: {
      "click": function ($event) {
        _vm.global.cookies = {
          strict: true,
          targeting: true,
          performance: true
        };
      }
    }
  }, [_vm._v("Accept")]), _c('div', {
    staticClass: "caption"
  }, [_vm._v("By clicking \"Accept\", you agree Ellacard can use cookies to remember your sign-in details, integrate with third-party services, analyze site usage, and enable targeted ads. You can configure which cookies are set "), _c('a', {
    staticClass: "white--text",
    attrs: {
      "href": "/cookies"
    }
  }, [_vm._v("here")]), _vm._v(".")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
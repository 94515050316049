var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('video-overlay', {
    attrs: {
      "disabled": _vm.loading || !_vm.didPlay,
      "paused": _vm.paused,
      "t": _vm.t,
      "duration": _vm.duration,
      "allow-fullscreen": _vm.allowFullscreen && !_vm.error,
      "fullscreen-el": _vm.$refs.video
    },
    on: {
      "update:paused": function ($event) {
        _vm.paused = $event;
      },
      "update:t": function ($event) {
        _vm.t = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm._t("default")];
      },
      proxy: true
    }], null, true)
  }, [_c('v-responsive', {
    staticClass: "black relative",
    attrs: {
      "aspect-ratio": 16 / 9
    }
  }, [_vm.error ? _c('div', {
    staticClass: "absolute-fill d-flex justify-center align-center text-center",
    staticStyle: {
      "z-index": "0"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.error)
    }
  }) : [_vm.loading || !_vm.didPlay ? _c('div', {
    staticClass: "absolute-fill d-flex justify-center align-center"
  }, [_vm.loading ? _c('v-progress-circular', {
    attrs: {
      "indeterminate": ""
    }
  }) : _c('v-btn', {
    staticStyle: {
      "z-index": "1"
    },
    attrs: {
      "fab": "",
      "x-large": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.didPlay = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": ""
    }
  }, [_vm._v(_vm._s(_vm.mdiPlay))])], 1)], 1) : _vm._e(), _c('video', {
    ref: "video",
    staticClass: "d-block",
    attrs: {
      "width": "100%",
      "height": "100%",
      "playsinline": "",
      "src": _vm.src,
      "poster": _vm.poster
    },
    on: {
      "durationchange": function (e) {
        return _vm.duration = isFinite(e.target.duration) ? e.target.duration : 0;
      },
      "ended": function ($event) {
        _vm.paused = true;
      },
      "timeupdate": function (e) {
        return _vm.t = e.target.currentTime;
      },
      "play": function () {
        _vm.paused = false;
        _vm.$emit('play-video');
      },
      "pause": function ($event) {
        _vm.paused = true;
      }
    }
  })]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
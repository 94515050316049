var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', [_c('v-col', {
    staticClass: "d-flex flex-wrap justify-center align-end",
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-img', {
    staticClass: "mx-2 mb-n2",
    attrs: {
      "aspect-ratio": 480 / 158,
      "min-width": "64",
      "max-width": "194",
      "src": "https://cdn.ellacard.com/start/google_logo_white.png"
    }
  }), _c('div', [_c('div', {
    staticClass: "px-1 mb-n1 d-flex align-baseline"
  }, [_c('v-spacer'), _c('div', {
    staticClass: "caption mr-2"
  }, [_vm._v("50 reviews")]), _c('div', {
    staticClass: "title font-weight-bold yellow--text"
  }, [_vm._v("4.8")])], 1), _c('v-rating', {
    attrs: {
      "dense": "",
      "readonly": "",
      "half-increments": "",
      "x-large": "",
      "color": "yellow",
      "value": 4.8
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "d-flex flex-wrap justify-center align-end",
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-img', {
    staticClass: "mx-2 mb-2",
    attrs: {
      "aspect-ratio": 480 / 96,
      "min-width": "64",
      "max-width": "230",
      "src": "https://cdn.ellacard.com/start/facebook_logo_white.png"
    }
  }), _c('div', [_c('div', {
    staticClass: "px-1 mb-n1 d-flex align-baseline"
  }, [_c('v-spacer'), _c('div', {
    staticClass: "caption mr-2"
  }, [_vm._v("15 reviews")]), _c('div', {
    staticClass: "title font-weight-bold yellow--text"
  }, [_vm._v("5.0")])], 1), _c('v-rating', {
    attrs: {
      "dense": "",
      "readonly": "",
      "half-increments": "",
      "x-large": "",
      "color": "yellow",
      "value": 5
    }
  })], 1)], 1)], 1), _c('v-row', _vm._l(_vm.REVIEWS, function (r, i) {
    return _c('v-col', {
      key: i,
      attrs: {
        "cols": "12",
        "sm": "4"
      }
    }, [_c('quote-card', _vm._b({
      staticClass: "pa-3 text-body-1"
    }, 'quote-card', r, false))], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }